import { useMemo, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { inject } from 'mobx-react'
import { Button, Avatar, Dropdown, Menu } from 'antd'
import moment from 'moment'
import ProLayout from '@ant-design/pro-layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill, faUserGroup, faCalendarDays, faGauge, faP, faTag } from '@fortawesome/free-solid-svg-icons'
import AdminRole from '@models/enum/AdminRole'
import { adminAppointmentListingPath, adminCfpListingPath, adminCreatePlanTypePath, adminCustomerListingPath, adminCustomerPlanListingPath, adminDashboardPath, adminEventListingPath, adminPlanTypeListingPath, adminPromoCodeListingPath, adminPurchaseListingPath } from '@constants/routes'

const marketingRoutes = [
    {
        path: adminDashboardPath,
        name: 'Dashboard',
        icon: <FontAwesomeIcon icon={faGauge} />
    },
    {
        name: adminEventListingPath,
        icon: <FontAwesomeIcon icon={faCalendarDays} />,
        routes: [
            {
                path: '/admin/events',
                name: 'List'
            }
        ]
    }
]

const adminRoutes = [
    {
        path: adminDashboardPath,
        name: 'Dashboard',
        icon: <FontAwesomeIcon icon={faGauge} />
    },
    {
        name: 'Plan Type',
        icon: <FontAwesomeIcon icon={faP} />,
        routes: [
            {
                path: adminPlanTypeListingPath,
                name: 'List'
            },
            {
                path: adminCreatePlanTypePath,
                name: 'Create'
            }
        ]
    },
    {
        name: 'Customer',
        icon: <FontAwesomeIcon icon={faUserGroup} />,
        routes: [
            {
                path: adminCustomerListingPath,
                name: 'List'
            }
        ]
    },
    {
        name: 'CFP',
        icon: <FontAwesomeIcon icon={faUserGroup} />,
        routes: [
            {
                path: adminCfpListingPath,
                name: 'List'
            }
        ]
    },
    {
        name: 'Plan',
        icon: <FontAwesomeIcon icon={faP} />,
        routes: [
            {
                path: adminCustomerPlanListingPath,
                name: 'List'
            }
        ]
    },
    {
        name: 'Appointment',
        icon: <FontAwesomeIcon icon={faCalendarDays} />,
        routes: [
            {
                path: adminAppointmentListingPath,
                name: 'List'
            }
        ]
    },
    {
        name: 'Purchase',
        icon: <FontAwesomeIcon icon={faMoneyBill} />,
        routes: [
            {
                path: adminPurchaseListingPath,
                name: 'List'
            }
        ]
    },
    {
        name: 'Event',
        icon: <FontAwesomeIcon icon={faCalendarDays} />,
        routes: [
            {
                path: adminEventListingPath,
                name: 'List'
            }
        ]
    },
    {
        name: 'Promo',
        icon: <FontAwesomeIcon icon={faTag} />,
        routes: [
            {
                path: adminPromoCodeListingPath,
                name: 'List'
            }
        ]
    }
]

const AdminLayout = ({ adminStore, config, children }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const currentTime = moment()
    const [pathname, setPathname] = useState(location?.pathname)

    const routes = useMemo(() => {
        const isMarketing = adminStore.role=== AdminRole.ADMIN_MARKETING.enumKey
        const routes = isMarketing ? marketingRoutes : adminRoutes
        return routes
    }, [adminStore.role])

    const logout = () => {
        adminStore.clear()
        navigate('/admin/login')
    }

    const userDropdownMenu = useMemo(() => {
        return (
            <Menu
                items={[
                    // {
                    //     key: 'profile',
                    //     label: 'Profile'
                    // },
                    {
                        key: 'logout',
                        label: (
                            <div onClick={logout}>
                                Logout
                            </div>
                        )
                    }
                ]}
            />
        )
    }, [])

    return (
        <ProLayout
            title={`${config.app.appName} - Admin`}
            logo={null}
            layout='mix'
            route={{
                path: '/',
                routes
            }}
            location={{
                pathname
            }}
            menu={{
                collapsedShowGroupTitle: true
            }}
            menuFooterRender={(props) => {
                if (props?.collapsed) return undefined
                return (
                    <div
                        style={{
                            textAlign: 'center',
                            paddingBlockStart: 12
                        }}
                    >
                        <div>© {currentTime.format('YYYY')} {config.app.appName}</div>
                    </div>
                )
            }}
            menuItemRender={(item, dom) => (
                <Link to={item.path}>
                    {dom}
                </Link>
            )}
            actionsRender={(props) => {
                return [
                    <Dropdown key='user-dropdown' overlay={userDropdownMenu} arrow size='large'>
                        <Avatar style={{ backgroundColor: '#1890ff', verticalAlign: 'middle' }} size='default'>
                            {adminStore?.fullName?.charAt(0)}
                        </Avatar>
                    </Dropdown>
                ]
            }}
        >
            {children}
        </ProLayout>
    )
}

export default inject('adminStore', 'config')(AdminLayout)
