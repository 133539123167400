import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { inject } from 'mobx-react'
import moment from 'moment'
import { Form, Radio, Select, Input, DatePicker, InputNumber, Checkbox, Modal } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomerType from '@models/enum/CustomerType'
import PlanType from '@models/enum/PlanType'
import Gender from '@models/enum/Gender'
import Race from '@models/enum/Race'
import schema from './schema'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import SubmitButton from '@components/common/button/submit/SubmitButton'

const CfpUpdateCustomerForm = (props) => {
    const logger = props.logger
    const customerService = props.services.customerService
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const { handleSubmit, setError, control, reset, watch, setValue, trigger, formState: { errors } } = useForm({
        defaultValues: props.defaultValues,
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            const params = {
                ...data,
                birthDate: (data.birthDate) ? moment(data.birthDate).format('YYYY-MM-DD') : null
            }
            const res = await customerService.updateFromCfp(params)

            if (res.ok && res.data) {
                props.onSuccessSubmit(res.data)
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (error) {
            logger.error('[Fail Submit Add Customer Form', error)
        } finally {
            setSubmitting(false)
        }
    }

    const handleIcNumberInputPressEnter = (e) => {
        const value = e.target.value
        if (value.length === 12) {
            let dob = value.slice(0, 6)
            const year = Number(dob.slice(0, 2))
            dob = year < 30 ? '20' + dob : '19' + dob
            const birthDate = moment(dob, 'YYYYMMDD')

            const last = value[11]
            const gender = last % 2 === 0 ? Gender.FEMALE.enumKey : Gender.MALE.enumKey

            setValue('birthDate', birthDate)
            setValue('gender', gender)
        }
    }

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            <Form.Item
                label='Customer Type'
                className='mb-4'
                validateStatus={(errors?.type?.message) ? 'error' : '-'}
                help={errors?.type?.message}
            >
                <Controller
                    name='type'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Radio.Group
                            options={[
                                { label: CustomerType.B2C.label, value: CustomerType.B2C.enumKey },
                                { label: CustomerType.B2B.label, value: CustomerType.B2B.enumKey}
                            ]}
                            onChange={(e) => {
                                onChange(e.target.value)
                            }}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Name'
                validateStatus={(errors?.fullName?.message) ? 'error' : '-'}
                help={errors?.fullName?.message}
            >
                <Controller
                    name='fullName'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            placeholder='e.g. John Smith'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='IC No.'
                validateStatus={(errors?.icNumber?.message) ? 'error' : '-'}
                help={errors?.icNumber?.message}
            >
                <Controller
                    name='icNumber'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            placeholder='e.g. 880108018008'
                            onChange={onChange}
                            value={value}
                            onPressEnter={handleIcNumberInputPressEnter}
                        />
                    )}
                />
            </Form.Item>

            <div className='mb-4 grid grid-cols-12 gap-4'>
                <Form.Item
                    className='mb-0 col-span-6'
                    label='D.O.B'
                    validateStatus={(errors?.birthDate?.message) ? 'error' : '-'}
                    help={errors?.birthDate?.message}
                >
                    <Controller
                        name='birthDate'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <DatePicker
                                ref={ref}
                                className='w-full'
                                placeholder='Date of birth'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='mb-0 col-span-6'
                    label="Gender"
                    validateStatus={(errors?.gender?.message) ? 'error' : '-'}
                    help={errors?.gender?.message}
                >
                    <Controller
                        name='gender'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Select
                                ref={ref}
                                options={[
                                    { label: Gender.MALE.label, value: Gender.MALE.enumKey },
                                    { label: Gender.FEMALE.label, value: Gender.FEMALE.enumKey }
                                ]}
                                placeholder='Gender'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>
            </div>

            <div className='mb-4 grid grid-cols-12 gap-4'>
                <Form.Item
                    className='mb-0 col-span-6'
                    label='Mobile Prefix'
                    validateStatus={(errors?.mobilePrefix?.message) ? 'error' : '-'}
                    help={errors?.mobilePrefix?.message}
                >
                    <Controller
                        name='mobilePrefix'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Input
                                ref={ref}
                                placeholder='e.g. 60'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='mb-0 col-span-6'
                    label='Mobile Number'
                    validateStatus={(errors?.mobileNumber?.message) ? 'error' : '-'}
                    help={errors?.mobileNumber?.message}
                >
                    <Controller
                        name='mobileNumber'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Input
                                ref={ref}
                                placeholder='e.g. 123456789'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>
            </div>

            <Form.Item
                className='mb-4'
                label='Email'
                validateStatus={(errors?.email?.message) ? 'error' : '-'}
                help={errors?.email?.message}
            >
                <Controller
                    name='email'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            disabled
                            placeholder='e.g. john.smith@email.com'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Race'
                validateStatus={(errors?.race?.message) ? 'error' : '-'}
                help={errors?.race?.message}
            >
                <Controller
                    name='race'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Select
                            ref={ref}
                            placeholder='Select race'
                            options={[
                                {
                                    label: Race.MALAY.label,
                                    value: Race.MALAY.enumKey
                                },
                                {
                                    label: Race.CHINESE.label,
                                    value: Race.CHINESE.enumKey
                                },
                                {
                                    label: Race.INDIAN.label,
                                    value: Race.INDIAN.enumKey
                                },
                                {
                                    label: Race.OTHERS.label,
                                    value: Race.OTHERS.enumKey
                                },
                                {
                                    label: Race.FOREIGNER.label,
                                    value: Race.FOREIGNER.enumKey
                                }
                            ]}
                            onChange={(e) => {
                                onChange(e)
                            }}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Monthly Income'
                validateStatus={(errors?.monthlyIncome?.message) ? 'error' : '-'}
                help={errors?.monthlyIncome?.message}
            >
                <Controller
                    name='monthlyIncome'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <InputNumber
                            ref={ref}
                            className='w-full'
                            formatter={value => `RM ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            /* eslint-disable no-useless-escape */
                            parser={value => value.replace(/\RM\s?|(,*)/g, '')}
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Remarks'
                validateStatus={(errors?.otherRemarks?.message) ? 'error' : '-'}
                help={errors?.otherRemarks?.message}
            >
                <Controller
                    name='otherRemarks'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            placeholder='Any reference or remark'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <SubmitButton className='mt-1' loading={submitting}>
                Submit
            </SubmitButton>
        </Form>
    )
}

export default inject('services')(CfpUpdateCustomerForm)
