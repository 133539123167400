import moment from 'moment'
import { string, object, number } from 'yup'
import DiscountType from '@models/enum/DiscountType'

const schema = object().shape({
    code: string()
        .nullable()
        .required(),
    description: string()
        .nullable()
        .required(),
    discountType: string()
        .nullable()
        .required()
        .oneOf([DiscountType.PERCENT.enumKey, DiscountType.PRICE.enumKey]),
    discountValue: number()
        .nullable()
        .required()
        .when('discountType', {
            is: DiscountType.PERCENT.enumKey,
            then: number()
                .max(100),
            otherwise: number()
                .max(9999)
        }),
    expiredAt: string()
        .nullable()
        .test(
            'is-valid',
            'Invalid datetime format',
            (value, context) => {
                if (!value) return true
                return moment(value).isValid()
            }
        )
})

export default schema