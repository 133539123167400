import { useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import { inject } from 'mobx-react'
import moment from 'moment'
import { Form, Select, Input, TimePicker, DatePicker, Modal } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import SubmitButton from '@components/common/button/submit/SubmitButton'

const CfpUpdateAppointmentForm = (props) => {
    const { logger, services, defaultValues } = props
    const appointmentService = services.appointmentService
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const { handleSubmit, setError, control, reset, watch, setValue, trigger, formState: { errors } } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    const disabledStartDate = (current) => {
        return current && current.isBefore(moment(), 'day')
    }

    const disabledStartTime = (now) => {
        return {
            disabledHours: () => {
                const hours = []
                if (moment(now).isSame(moment(), 'day')) {
                    for (let i = 0; i < moment().hour(); i += 1) hours.push(i)
                }
                return hours
            },
            disabledMinutes: (selectedHour) => {
                const minutes = []
                if (moment(now).isSame(moment(), 'day') && selectedHour === moment().hour()) {
                    for (let i = moment().minute(); i >= 0; i -= 1) minutes.push(i)
                }
                return minutes
            }
        }
    }

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            const params = {
                ...data,
                startTime: moment(data.startTime).format('YYYY-MM-DDTHH:mm:ss')
            }

            logger.info('[Submit Update Appointment Form]', params)

            const res = await appointmentService.updateFromCfp(params)

            if (res.ok && res.data) {
                props.onSuccessSubmit(res.data)
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (error) {
            logger.error('[Fail Submit Add Appointment Form]', error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            <Form.Item
                className='mb-4'
                label='Appointment Start Time'
                validateStatus={(errors?.startTime?.message) ? 'error' : '-'}
                help={errors?.startTime?.message}
            >
                <Controller
                    name='startTime'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <DatePicker
                            className='w-full'
                            placeholder='Select Start Time'
                            format='YYYY-MM-DD hh:mm A'
                            showTime
                            disabledDate={disabledStartDate}
                            disabledTime={disabledStartTime}
                            onChange={(e) => {
                                onChange(e)
                                trigger('startTime')
                            }}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <SubmitButton className='mt-1' loading={submitting}>
                Submit
            </SubmitButton>
        </Form>
    )
}

export default inject('services', 'logger')(CfpUpdateAppointmentForm)
