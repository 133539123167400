import { inject } from 'mobx-react'
import { useState, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import qs from 'qs'
import { Modal, Spin } from 'antd'
import showApiErrorMessage from '@helpers/showApiErrorMessage'

const CfpEmailVerificationPage = (props) => {
    const logger = props.logger
    const cfpStore = props.cfpStore
    const location = useLocation()
    const [checking, setChecking] = useState(true)
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    useEffect(() => {
        if (query?.code) {
            verify(query.code)
        }
    }, [])

    const verify = async (code) => {
        try {
            setChecking(true)
            const res = await cfpStore.verifyEmail(code)

            if (res.ok) {
                window.location.href = '/#/cfp/set-password'
            } else {
                const errors = res.data?.errors
                if (errors) {
                    const onOk = () => { window.location.href = '/#/cfp/login' }
                    showApiErrorMessage(errors, onOk)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        ),
                        onOk: () => {
                            window.location.href = '/#/cfp/login'
                        }
                    })
                }
            }
        } catch (error) {
            logger.error('[Failed Verify Email]', error)
        } finally {
            setChecking(false)
        }
    }

    if (!query?.code) {
        return (
            <Navigate
                to='/cfp/login'
                replace
            />
        )
    }

    return (
        <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
            {checking &&
            <>
                <Spin size='large' />
                <p className='text-lg mt-2 text-center text-blue-400'>Verifying ...</p>
            </>}
        </div>
    )
}

export default inject('logger', 'cfpStore')(CfpEmailVerificationPage)
