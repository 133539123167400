const listAppointmentFromAdminEndpointPath = '/admin/appointment/list'
const addAppointmentFromAdminEndpointPath = '/admin/appointment/create'
const addPendingAppointmentFromAdminEndpointPath = '/admin/appointment/create-pending'
const cancelAppointmentFromAdminEndpointPath = '/admin/appointment/cancel'
const updateAppointmentFromAdminEndpointPath = '/admin/appointment/update'
const completeAppointmentFromAdminEndpointPath = '/admin/appointment/complete'
const listAppointmentFromCfpEndpointPath = '/cfp/appointment/list'
const listAppointmentFromCfpByIdEndpointPath = '/cfp/appointment'
const addAppointmentFromCfpEndpointPath = '/cfp/appointment/create'
const addPendingAppointmentFromCfpEndpointPath = '/cfp/appointment/create-pending'
const cancelAppointmentFromCfpEndpointPath = '/cfp/appointment/cancel'
const updateAppointmentFromCfpEndpointPath = '/cfp/appointment/update'
const completeAppointmentFromCfpEndpointPath = '/cfp/appointment/complete'

const uploadFileFromAdminEndpointPath = '/admin/appointment/attach-file'
const viewFileFromAdminEndpointPath = '/admin/appointment/view-attachment'
const deleteFileFromAdminEndpointPath = '/admin/appointment/remove-file'

class AppointmentService {
    constructor (adminWebClient, cfpWebClient) {
        this.adminWebClient = adminWebClient
        this.cfpWebClient = cfpWebClient
    }

    async listFromAdmin (params) {
        const res = await this.adminWebClient.get(listAppointmentFromAdminEndpointPath, params)
        return res
    }

    async addFromAdmin (params) {
        const res = await this.adminWebClient.post(addAppointmentFromAdminEndpointPath, params)
        return res
    }

    async addPendingFromAdmin (params) {
        const res = await this.adminWebClient.post(addPendingAppointmentFromAdminEndpointPath, params)
        return res
    }

    async cancelFromAdmin (params) {
        const res = await this.adminWebClient.post(cancelAppointmentFromAdminEndpointPath, params)
        return res
    }

    async updateFromAdmin (params) {
        const res = await this.adminWebClient.post(updateAppointmentFromAdminEndpointPath, params)
        return res
    }

    async completeFromAdmin (params) {
        const res = await this.adminWebClient.post(completeAppointmentFromAdminEndpointPath, params)
        return res
    }

    async listFromCfp (params) {
        const res = await this.cfpWebClient.get(listAppointmentFromCfpEndpointPath, params)
        return res
    }

    async listFromCfpById (id) {
        const endpointWithPath = `${listAppointmentFromCfpByIdEndpointPath}/${id}`
        const res = await this.cfpWebClient.get(endpointWithPath)
        return res
    }

    async addFromCfp (params) {
        const res = await this.cfpWebClient.post(addAppointmentFromCfpEndpointPath, params)
        return res
    }

    async addPendingFromCfp (params) {
        const res = await this.cfpWebClient.post(addPendingAppointmentFromCfpEndpointPath, params)
        return res
    }

    async cancelFromCfp (params) {
        const res = await this.cfpWebClient.post(cancelAppointmentFromCfpEndpointPath, params)
        return res
    }

    async updateFromCfp (params) {
        const res = await this.cfpWebClient.post(updateAppointmentFromCfpEndpointPath, params)
        return res
    }

    async completeFromCfp (params) {
        const res = await this.cfpWebClient.post(completeAppointmentFromCfpEndpointPath, params)
        return res
    }

    async uploadFileFromCfp(formData) {
        const res = await this.cfpWebClient.post(uploadFileFromAdminEndpointPath, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return res
    }

    async viewFileFromCfp(formData) {
        const res = await this.cfpWebClient.post(viewFileFromAdminEndpointPath, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return res
    }

    async deleteFileFromCfp(formData) {
        const res = await this.cfpWebClient.post(deleteFileFromAdminEndpointPath, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return res
    }
}

export default AppointmentService
