import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Drawer, Progress, Tag, Typography } from 'antd'
import ProDescriptions from '@ant-design/pro-descriptions'
import AppointmentStatus from '@models/enum/AppointmentStatus'
import { Link } from 'react-router-dom'
import { adminCustomerDetailPath, adminCustomerPlanDetailPath } from '@constants/routes'

const AppointmentDetail = (props) => {
    const { renderColumns, loading, className, extra } = props
    const [appointment, setAppointment] = useState(props.appointment)

    const defaultColumns = {
        id: {
            title: 'ID',
            dataIndex: 'id',
            render: (text, record) => (
                text
            )
        },
        planId: {
            title: 'Plan ID',
            render: (text, record) => (
                <Link to={adminCustomerPlanDetailPath(record?.planId)}>
                    {record?.planId}
                </Link>
            )
        },
        customerFullName: {
            title: 'Customer Name',
            dataIndex: 'fullName',
            render: (text, record) => (
                <Link to={adminCustomerDetailPath(record?.customerId)}>
                    {record?.customer?.fullName}
                </Link>
            )
        },
        customerEmail: {
            title: 'Customer Email',
            dataIndex: 'email',
            render: (text, record) => (
                record?.customer?.email
            )
        },
        cfp: {
            title: 'CFP Name',
            dataIndex: 'cfpName',
            render: (text, record) => {
                const cfpName = record.cfp?.name
                return (
                    cfpName
                )
            }
        },
        planType: {
            title: 'Plan Type',
            dataIndex: 'planType',
            render: (text, record) => {
                if (!record?.plan?.planTypeName) return '-'
                if (!record?.plan?.planTypeVariantType) {
                    return (
                        record?.plan?.planTypeName
                    )
                }
                return (
                    record?.plan?.planTypeName + ' ' + record?.plan?.planTypeVariantType
                )
            }
        },
        session: {
            title: 'Session',
            dataIndex: 'sessionNumber',
            valueEnum: {
                1: { text: 'Session 1' },
                2: { text: 'Session 2' },
                3: { text: 'Session 3' }
            }
        },
        status: {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                return (
                    AppointmentStatus.of(text)?.label
                )
            }
        },
        startTime: {
            title: 'Start Time',
            dataIndex: 'startTime',
            renderText: (text) => {
                if (!text) return '-'
                return moment.utc(text).local().format('D MMM YYYY, hh:mm A')
            }
        },
        endTime: {
            title: 'End Time',
            dataIndex: 'endTime',
            renderText: (text) => {
                if (!text) return '-'
                return moment.utc(text).local().format('D MMM YYYY, hh:mm A')
            }
        },
        location: {
            title: 'Location',
            dataIndex: 'location',
            render: (text) => {
                if (!text) return '-'
                return <Typography.Link href={text} target='_blank'>{text}</Typography.Link>
            }
        },
        completedTime: {
            title: 'Completed Time',
            dataIndex: 'completedAt',
            renderText: (text) => {
                if (!text) return '-'
                return moment.utc(text).local().format('D MMM YYYY, hh:mm A')
            }
        },
        completedBy: {
            title: 'Completed by',
            dataIndex: 'completedBy',
            renderText: (text) => {
                if (!text) return '-'
                return text
            }
        },
        cancelledTime: {
            title: 'Cancelled Time',
            dataIndex: 'cancelledAt',
            renderText: (text) => {
                if (!text) return '-'
                return moment.utc(text).local().format('D MMM YYYY, hh:mm A')
            }
        },
        cancelledBy: {
            title: 'Cancelled by',
            dataIndex: 'cancelledBy',
            renderText: (text) => {
                if (!text) return '-'
                return text
            }
        },
        createdAt: {
            title: 'Created At',
            dataIndex: 'createdAt',
            renderText: (text) => {
                if (!text) return '-'
                return moment.utc(text).local().format('D MMM YYYY, hh:mm A')
            }
        },
        updatedAt: {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            renderText: (text) => {
                if (!text) return '-'
                return moment.utc(text).local().format('D MMM YYYY, hh:mm A')
            }
        }
    }

    let columns = Object.values(defaultColumns)
    if (renderColumns) {
        columns = renderColumns(defaultColumns)
    }

    useEffect(() => {
        if (props.appointment) {
            setAppointment(props.appointment)
        }
    }, [props.appointment])

    return (
        <ProDescriptions
            className={className}
            title='Appointment Detail'
            bordered
            layout='horizontal'
            size='small'
            column={1}
            loading={loading}
            columns={columns}
            dataSource={appointment}
            extra={extra}
        />
    )
}

export default AppointmentDetail
