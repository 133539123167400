import { useEffect, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import cn from 'classnames'
import Modal from 'antd/lib/modal/Modal'
import AdminAddCustomerForm from '../form/AdminAddCustomerForm'

const AdminAddCustomerModal = (props) => {
    return (
        <Modal
            className={cn(props.className)}
            title='Add Customer'
            open={props.visible}
            onCancel={props.onCancel}
            footer={null}
            destroyOnClose
        >
            <AdminAddCustomerForm
                {...props.form}
            />
        </Modal>
    )
}

export default inject('services')(AdminAddCustomerModal)
