import cn from 'classnames'
import { Modal } from 'antd'
import AdminAddFileInSessionsForm from '../form/AdminAddFileInSessionsForm'

const AdminAddAttachmentSessionsModal = (props) => {

    const handleSucccesSubmit = () => {
        Modal.info({
            title: 'File uploaded successfully!',
            content: (
                <p>
                    Your file has been uploaded.
                </p>
            )
        })
    }

    return (
        <Modal
            className={cn(props.className)}
            title='Add Session File'
            open={props.visible}
            onCancel={props.onCancel}
            footer={null}
            destroyOnClose
        >
            <AdminAddFileInSessionsForm
                {...props.form}
                onCancel={props.onCancel}
                onSuccessSubmit={handleSucccesSubmit}
                updateSessionsFile={props.updateSessionsFile}
            />
        </Modal>
    )
}

export default AdminAddAttachmentSessionsModal
