import React, { useEffect, useState, useRef } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import AppointmentDetail from '@components/shared/appointment/detail/AppointmentDetail'
import { cfpCustomerDetailPath, cfpCustomerPlanDetailPath } from '@constants/routes'
import { Button, Modal } from 'antd'
import moment from 'moment-timezone'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import CfpUpdateAppointmentModal from '../update/modal/CfpUpdateAppointmentModal'
import AppointmentStatus from '@models/enum/AppointmentStatus'

const CfpAppointmentDetail = ({ logger, services, className, style, onSuccessUpdate, onSuccessCancel, onSuccessComplete, appointment, loading }) => {

    const id = appointment?.id
    const { appointmentService } = services
    const [updateAppointmentFormProps, setUpdateAppointmentFormProps] = useState(null)

    const cancelAppointment = async (id) => {
        try {
            const apiParams = { id }

            const res = await appointmentService.cancelFromCfp(apiParams)

            if (res.ok && res.data) {
                onSuccessCancel()
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (e) {
            logger.error('[Failed Cancel Appointment]', e)
        }
    }

    const completeAppointment = async (id) => {
        try {
            const apiParams = { id }
            const res = await appointmentService.completeFromCfp(apiParams)

            if (res.ok && res.data) {
                onSuccessComplete()
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (e) {
            logger.error('[Failed Complete Appointment]', e)
        }
    }

    return (
        <>
            {appointment &&
                <AppointmentDetail
                    className={className}
                    style={style}
                    loading={loading}
                    appointment={appointment}
                    renderColumns={(defaultColumns) => {
                        defaultColumns.customerFullName = {
                            ...defaultColumns.customerFullName,
                            render: (text, record) => {
                                return (
                                    <Link to={cfpCustomerDetailPath(record.customerId)}>
                                        {record.customer.fullName}
                                    </Link>
                                )
                            }
                        }

                        defaultColumns.planId = {
                            ...defaultColumns.planId,
                            render: (text, record) => {
                                return (
                                    <Link to={cfpCustomerPlanDetailPath(record.planId)}>
                                        {record.planId}
                                    </Link>
                                )
                            }
                        }

                        return Object.values(defaultColumns)
                    }}
                    extra={
                        <div className='flex gap-3'>
                            {(appointment.status === AppointmentStatus.PENDING.enumKey || appointment.status === AppointmentStatus.UPCOMING.enumKey) && (
                                <>
                                    {/* <Button
                                        type='primary'
                                        className='font-semibold'
                                        onClick={() => {
                                            setUpdateAppointmentFormProps({
                                                defaultValues: {
                                                    id: appointment?.id,
                                                    startTime: (appointment?.startTime) ? moment(appointment?.startTime) : null,
                                                    timezone: moment.tz.guess()
                                                },
                                                onSuccessSubmit: () => {
                                                    tableActionRef.current?.reload()
                                                    if (onSuccessUpdate) onSuccessUpdate()
                                                    setUpdateAppointmentFormProps(null)
                                                }
                                            })
                                        }}
                                    >
                                        Update
                                    </Button> */}
                                    <Button
                                        type='primary'
                                        className='font-semibold'
                                        onClick={() => {
                                            Modal.confirm({
                                                title: 'Are you sure to cancel this appointment?',
                                                icon: <ExclamationCircleOutlined />,
                                                okText: 'Confirm',
                                                cancelText: 'Cancel',
                                                onOk: async () => {
                                                    await cancelAppointment(id)
                                                }
                                            })
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </>
                            )}
                            {appointment.status === AppointmentStatus.UPCOMING.enumKey && (
                                <Button
                                    type='primary'
                                    className='font-semibold'
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Are you sure to complete this appointment?',
                                            icon: <ExclamationCircleOutlined />,
                                            okText: 'Confirm',
                                            cancelText: 'Cancel',
                                            onOk: async () => {
                                                await completeAppointment(id)
                                            }
                                        })
                                    }}
                                >
                                    Complete
                                </Button>
                            )}
                        </div>
                    }
                />}

            <CfpUpdateAppointmentModal
                form={updateAppointmentFormProps}
                visible={!!updateAppointmentFormProps}
                onCancel={() => setUpdateAppointmentFormProps(null)}
            />
        </>
    )
}

export default inject('logger', 'services')(CfpAppointmentDetail)
