import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import moment from 'moment'
import numeral from 'numeral'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable from '@ant-design/pro-table'
import { Button, Switch, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Layout from '@components/admin/layout/AdminLayout'
import prepareApiParams from '@helpers/prepareApiParams'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import AdminAddPromoModal from '@components/admin/promo-code/add/modal/AdminAddPromoModal'
import PromoCodeStatus from '@models/enum/PromoCodeStatus'
import PromoCodeDetailDrawer from '@components/admin/promo-code/detail/drawer/PromoCodeDetailDrawer'
import UpdatePromoCodeModal from '@components/admin/promo-code/update/modal/UpdatePromoCodeModal'
import { adminPromoCodeListingPath } from '@constants/routes'

const AdminPromoCodeListingPage = ({ config, logger, services: { promoCodeService } }) => {
    const tableActionRef = useRef()
    const [activeTabKey, setActiveTabKey] = useState(PromoCodeStatus.ACTIVE.enumKey)
    const [selectedPromo, setSelectedPromo] = useState(null)
    const [toShowAddPromo, setToShowAddPromo] = useState(false)
    const [updatePromoCodeFormDefaultValues, setUpdatePromoCodeFormDefaultValues] = useState(null)
    const [toShowPromoCodeDetailDrawer, setToShowPromoCodeDetailDrawer] = useState(false)
    const [toShowUpdatePromoCodeModal, setToShowUpdatePromoCodeModal] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const tabList = useMemo(() => {
        const enumValues = Object.values(PromoCodeStatus.enumValues)
        const tabList = []
        enumValues.map((enumValue) =>
            tabList.push({
                label: enumValue.label, key: enumValue.enumKey
            })
        )
        return tabList
    }, [])

    const columns = useMemo(() => {
        return [
            {
                title: 'Code',
                dataIndex: 'code',
                width: 80,
                fixed: 'left',
                search: true,
                render: (text, record) => {
                    return record.code
                }
            },
            {
                title: 'Type',
                width: 80,
                fixed: 'left',
                search: false,
                render: (text, record) => {
                    return record.type
                }
            },
            {
                title: 'Discount Type',
                width: 120,
                fixed: 'left',
                search: false,
                render: (text, record) => {
                    return record.discountType
                }
            },
            {
                title: 'Discount Value',
                width: 120,
                fixed: 'left',
                search: false,
                render: (text, record) => {
                    if (record.discountType === 'PRICE') {
                        return 'MYR ' + numeral(record.discountValue).format('0,0.00') 
                    } else {
                        return numeral(record.discountValue).format('0,0.00') + '%'
                    }
                }
            },
            {
                title: 'Status',
                width: 120,
                search: false,
                render: (text, record) => {
                    return (
                        <Switch
                            defaultChecked={record.status === PromoCodeStatus.ACTIVE.enumKey}
                            onChange={(checked) => {
                                handleStatusChange(checked, record)
                            }}
                        />
                    )
                }
            },
            {
                title: 'Expired At',
                dataIndex: 'expiredAt',
                search: false,
                renderText: (text) => {
                    if (text == null) return '-'
                    const dateStr = moment.utc(text).local().format('D MMM YYYY hh:mm A')
                    return dateStr
                }
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                search: false,
                sorter: true,
                renderText: (text) => {
                    if (text == null) return '-'
                    const dateStr = moment.utc(text).local().format('D MMM YYYY hh:mm A')
                    return dateStr
                }
            },
            {
                title: 'Actions',
                valueType: 'option',
                key: 'option',
                width: 80,
                search: false,
                fixed: 'right',
                render: (text, record, _, action) => {
                    const actionComponents = [
                        <a
                            key='view'
                            onClick={() => {
                                setSelectedPromo(record)
                                setToShowPromoCodeDetailDrawer(true)
                            }}
                        >
                            View
                        </a>
                    ]
                    const updateBtn = [
                        <a
                            key='update'
                            onClick={() => {
                                setUpdatePromoCodeFormDefaultValues({
                                    ...record,
                                    expiredAt: (record.expiredAt) ? moment(record.expiredAt) : null
                                })
                                setToShowUpdatePromoCodeModal(true)
                            }}
                        >
                            Update
                        </a>
                    ]
                    actionComponents.push(updateBtn)
                    return actionComponents
                }
            }
        ]
    },[])

    useEffect(() => {
    }, [])

    useEffect(() => {
        tableActionRef.current?.reset()
        tableActionRef.current?.reload()
    }, [activeTabKey])

    const handleRequest = async (params, sort) => {
        try {

            const apiParams = { status: activeTabKey }
            prepareApiParams(params, sort, apiParams)
            const res = await promoCodeService.listFromAdmin(apiParams)

            if (res.ok) {
                const data = res.data
                return {
                    data: data.data,
                    success: res.ok,
                    total: data.lastPage * data.perPage
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                return {
                    data: null,
                    success: res.ok,
                    total: 0
                }
            }
        } catch (e) {
            logger.error('[Failed List Promo Code]', e)
        }
    }

    const handleTabChange = (key) => {
        setActiveTabKey(key)
    }

    const handleAddPromoSuccess = (data) => {
        setToShowAddPromo(false)
        tableActionRef.current?.reload()
    }

    const handleSuccesUpdatePromoCode = () => {
        setToShowUpdatePromoCodeModal(false)
        tableActionRef.current?.reload()
    }

    const handleStatusChange = async (checked, record) => {
        try {
            setSubmitting(true)
            const status = checked ? PromoCodeStatus.ACTIVE.enumKey : PromoCodeStatus.INACTIVE.enumKey
            const params = {
                id: record.id,
                status
            }
    
            const res = await promoCodeService.updateStatusFromAdmin(params)
    
            const errors = res.data?.errors
            if (errors) {
                showApiErrorMessage(errors)
            } else {
                if (res.ok) {
                    tableActionRef.current?.reload()
                } else {
                    Modal.error({
                        title: 'Error',
                        content: <p>Something went wrong</p>
                    })
                }
            }
        } catch (error) {
            logger.error('[Fail Update Promo Code Status', error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: adminPromoCodeListingPath,
                            breadcrumbName: 'Promo-Codes'
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title='Promo Codes'
                tabList={tabList}
                onTabChange={handleTabChange}
                tabActiveKey={activeTabKey}
            >
                <ProTable
                    actionRef={tableActionRef}
                    headerTitle=''
                    columns={columns}
                    request={handleRequest}
                    search={{
                        defaultCollapsed: false
                    }}
                    rowKey='id'
                    toolBarRender={() => [
                        <Button
                            type='primary'
                            key='primary'
                            onClick={() => {
                                setToShowAddPromo(true)
                            }}
                        >
                            <PlusOutlined /> Add
                        </Button>
                    ]}
                />

                <AdminAddPromoModal
                    visible={toShowAddPromo}
                    onCancel={() => setToShowAddPromo(false)}
                    onSuccessSubmit={handleAddPromoSuccess}
                />


                <PromoCodeDetailDrawer
                    promo={selectedPromo}
                    visible={toShowPromoCodeDetailDrawer}
                    onClose={() => setToShowPromoCodeDetailDrawer(false)}
                    handleVisible={setToShowPromoCodeDetailDrawer}
                />

                {updatePromoCodeFormDefaultValues &&
                <UpdatePromoCodeModal
                    key={updatePromoCodeFormDefaultValues.id}
                    formDefaultValues={updatePromoCodeFormDefaultValues}
                    visible={toShowUpdatePromoCodeModal}
                    onCancel={() => setToShowUpdatePromoCodeModal(false)}
                    onSuccessSubmit={handleSuccesUpdatePromoCode}
                />}
                
            </PageContainer>
        </Layout>
    )
}

export default inject('services')(AdminPromoCodeListingPage)