import { useState, useEffect, useMemo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Input, Modal } from 'antd'
import { inject } from 'mobx-react'
import schema from './schema'
import SubmitButton from '@components/common/button/submit/SubmitButton'
import showApiErrorMessage from '@helpers/showApiErrorMessage'

const CfpLoginForm = props => {
    const logger = props.logger
    const cfpStore = props.cfpStore
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const { handleSubmit, setError, control, reset, watch, setFocus, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    useEffect(() => {
    }, [])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)

            const res = await cfpStore.login(data.email, data.password)

            if (res.ok && res.data) {
                props.onSuccessSubmit(data)
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (error) {
            logger.error('[Failed Submit CFP Login Form]', error)
            Modal.error({
                title: 'Error',
                content: (
                    <p>Something went wrong</p>
                )
            })
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            <Form.Item
                className='mb-4'
                label='Email'
                validateStatus={(errors?.email?.message) ? 'error' : '-'}
                help={errors?.email?.message}
            >
                <Controller
                    name='email'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            placeholder='Email'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Password'
                validateStatus={(errors?.password?.message) ? 'error' : '-'}
                help={errors?.password?.message}
            >
                <Controller
                    name='password'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            type='password'
                            placeholder='Password'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <SubmitButton className='mt-1' loading={submitting}>
                Submit
            </SubmitButton>
        </Form>
    )
}

CfpLoginForm.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onSuccessSubmit: PropTypes.func
}

CfpLoginForm.defaultProps = {
    onSuccessSubmit: () => {}
}

export default inject('cfpStore', 'logger')(CfpLoginForm)
