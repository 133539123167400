import { Enumify } from 'enumify'

export default class AppointmentStatus extends Enumify {
    value = null

    constructor (value = null, label = null) {
        super()
        this.value = value
        this.label = label
    }

    static of (type) {
        return AppointmentStatus.enumValueOf(type)
    }

    static for (type) {
        switch (type) {
            case 0: return AppointmentStatus.PENDING
            case 1: return AppointmentStatus.UPCOMING
            case 2: return AppointmentStatus.COMPLETED
            case 3: return AppointmentStatus.CANCELLED
        }
        return null
    }
}

AppointmentStatus.PENDING = new AppointmentStatus(0, 'Pending')
AppointmentStatus.UPCOMING = new AppointmentStatus(1, 'Upcoming')
AppointmentStatus.COMPLETED = new AppointmentStatus(2, 'Completed')
AppointmentStatus.CANCELLED = new AppointmentStatus(3, 'Cancelled')
AppointmentStatus._ = AppointmentStatus.closeEnum()
