import { useState } from 'react'
import { inject } from 'mobx-react'
import cn from 'classnames'
import { Modal, Button, Table } from 'antd'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import AdminDeleteAttachmentConfirmationModal from './AdminDeleteAttachmentConfirmationModal'

const AdminViewAttachmentSessionsModal = (props) => {
    const logger = props.logger
    const planTypeService = props.services.planTypeService

    const files = props?.record?.attachments
    const [submitting, setSubmitting] = useState(false)
    const [deleteModalProps, setDeleteModalProps] = useState(null)

    const handleViewFile = (attachmentUrl) => {
        window.open(attachmentUrl, '_blank')
    }

    const handleSucccesDelete = () => {
        Modal.info({
            title: 'File deleted successfully!',
            content: (
                <p>
                    Your file has been deleted.
                </p>
            )
        })
    }

    const handleConfirmDelete = (attachment) => {
        setDeleteModalProps(attachment)
    }

    const handleDeleteFile = async (attachmentId) => {
        const sessionIndex = props?.record?.sessionIndex
        const req = {
            sessionIndex,
            id: props?.record?.id,
            attachmentId
        }

        try {
            setSubmitting(true)

            const formData = new FormData()
            Object.keys(req).forEach((key) => {
                formData.append(key, req[key])
            })

            const res = await planTypeService.deleteFilePlanType(formData)

            if (res.ok && res.data) {
                props.onCancel()
                handleSucccesDelete()
                props.updateSessionsFile(res.data, sessionIndex)
            } else {
                const errors = res?.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: <p>Something went wrong</p>
                    })
                }
            }

        } catch (error) {
            logger.error('Fail to delete file in sessions form', error)
        } finally {
            setSubmitting(false)
        }
    }

    const columns = [
        {
            title: 'File Name',
            dataIndex: 'title'
        },
        {
            title: 'View File',
            dataIndex: 'attachmentUrl',
            render: (attachmentUrl) => (
                <Button onClick={() => handleViewFile(attachmentUrl)}>View File</Button>
            )
        },
        {
            title: 'Delete File',
            dataIndex: 'attachment',
            render: (attachment) => (
                <Button onClick={() => handleConfirmDelete(attachment)}>Delete File</Button>
            )
        }
    ]

    const dataSource = files ? Object.entries(files).map(([attachmentId, attachment]) => ({
        key: attachmentId,
        title: attachment?.title,
        attachmentUrl: attachment?.attachmentUrl,
        attachment
    })) : []

    return (
        <>
            <Modal
                className={cn(props.className)}
                title='View Session Files'
                open={props.visible}
                onCancel={props.onCancel}
                footer={null}
                destroyOnClose
            >
                <Table columns={columns} dataSource={dataSource} />
            </Modal>
            <AdminDeleteAttachmentConfirmationModal
                record={deleteModalProps}
                visible={!!deleteModalProps}
                onCancel={() => setDeleteModalProps(null)}
                handleDeleteFile={handleDeleteFile}
            />
        </>
    )
}
export default inject('services', 'logger')(AdminViewAttachmentSessionsModal)
