import DiscountType from '@models/enum/DiscountType'
import { string, object, number, array, boolean } from 'yup'

const schema = object().shape({
    name: string()
        .required(),
    subTitle: string()
        .nullable(),
    description: string()
        .nullable(),
    mobileDescription: string()
        .nullable(),
    price: number()
        .nullable()
        .transform((value, originalValue) => {
            return originalValue === '' ? null : value
        })
        // Require when variants is null
        .when('variants', {
            is: (variants) => !variants,
            then: number()
                .min(0)
                .required()
        }),
    expireDuration: number()
        .nullable()
        .when('expireDurationUnit', {
            is: (expireDurationUnit) => !!expireDurationUnit,
            then: number()
                .nullable()
                .required()
        }),
    expireDurationUnit: string()
        .nullable()
        .when('expireDuration', {
            is: (expireDuration) => !!expireDuration,
            then: string()
                .oneOf(['MONTH', 'YEAR'])
                .nullable()
                .required()
        }),
    nextPurchaseDiscountType: string()
        .nullable()
        .when('nextPurchaseDiscountValue', {
            is: (nextPurchaseDiscountValue) => !!nextPurchaseDiscountValue,
            then: string()
                .nullable()
                .oneOf([DiscountType.PERCENT.enumKey, DiscountType.PRICE.enumKey])
                .required()
        }),
    nextPurchaseDiscountValue: number()
        .nullable()
        .transform((value, originalValue) => {
            return originalValue === '' ? null : value
        })
        .when('nextPurchaseDiscountType', {
            is: (nextPurchaseDiscountType) => !!nextPurchaseDiscountType,
            then: number()
                .nullable()
                .required()
        }),
    purchaseLimit: number()
        .nullable(),
    colorCode: string()
        .nullable(),
    sortWeight: number()
        .nullable(),
    purchaseable: boolean(),
    isPublic: boolean(),
    isRecommended: boolean(),
    ableToContactCfp: boolean(),
    isInstallmentAvailable: boolean(),
    sessions: array()
        .of(object().shape({
            title: string()
                .nullable()
                .required(),
            duration: number()
                .nullable()
                .required(),
            durationUnit: string()
                .oneOf(['MINUTE', 'HOUR'])
                .required(),
            mobileName: string()
                .required(),
            mobileDescription: string()
                .required()
        }))
        .min(1)
        .max(4)
        .required(),
    variants: array()
        .of(object().shape({
            type: string()
                .nullable()
                .oneOf(['SOLO', 'COUPLE'])
                .required(),
            price: number()
                .min(0)
                .nullable()
                .transform((value, originalValue) => {
                    return originalValue === '' ? null : value
                })
                .required(),
            purchaseable: boolean(),
            isPublic: boolean()
        }))
        .nullable(),
    features: array()
        .of(object().shape({
            name: string()
                .nullable()
                .required(),
            description: string()
                .required()
        }))
        .min(0)
        .max(20)
        .nullable()
}, [['expireDuration', 'expireDurationUnit'], ['nextPurchaseDiscountType', 'nextPurchaseDiscountValue']])

export default schema
