import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import CfpPrivateRoute from './CfpPrivateRoute'
import CfpCustomerListingPage from '@pages/cfp/customers'
import CfpCustomerDetailPage from '@pages/cfp/customers/[id]'

const CfpCustomerRoutes = (props) => {
    const logger = props.logger
    const { pathname } = useLocation()
    const navigate = useNavigate()

    return (
        <Routes>
            <Route
                path='/'
                exact
                element={
                    <CfpPrivateRoute>
                        <CfpCustomerListingPage />
                    </CfpPrivateRoute>
                }
            />
            <Route
                path='/:id'
                element={
                    <CfpPrivateRoute>
                        <CfpCustomerDetailPage />
                    </CfpPrivateRoute>
                }
            />
        </Routes>
    )
}

export default inject('logger')(CfpCustomerRoutes)
