import { useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { PageContainer } from '@ant-design/pro-layout'
import { inject } from 'mobx-react'
import Layout from '@components/admin/layout/AdminLayout'
import { adminCustomerListingPath } from '@constants/routes'
import AdminCustomerList from '@components/admin/customer/list/AdminCustomerList'

const AdminCustomerListingPage = ({ logger, services: { customerService } }) => {
    useEffect(() => {
    }, [])

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: adminCustomerListingPath,
                            breadcrumbName: 'Customers'
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title='Customers'
            >
                <AdminCustomerList />
            </PageContainer>
        </Layout>
    )
}

export default inject('services', 'logger')(AdminCustomerListingPage)
