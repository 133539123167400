import showApiErrorMessage from '@helpers/showApiErrorMessage'

const useCfpOptionsLoader = (props) => {
    const { logger, cfpService } = props

    const loadOptions = async (inputValue, loadedOptions) => {
        try {
            const nextPage = (loadedOptions.length / 20) + 1

            const res = await cfpService.listFromAdmin({
                name: inputValue,
                page: nextPage
            })

            if (res.ok && res.data) {
                const data = res.data
                const cfps = data.data
                const options = []

                cfps.forEach((cfp, index) => {
                    options.push({
                        label: `${cfp.name} - ${cfp.email}`,
                        value: cfp.id,
                        data: cfp
                    })
                })

                const hasMore = (data.lastPage > data.page)

                return {
                    options,
                    hasMore
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                throw new Error(errors)
            }
        } catch (error) {
            logger.error('[Failed Load CFP Options]', error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    return { loadOptions }
}

export default (useCfpOptionsLoader)