import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import moment from 'moment'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable from '@ant-design/pro-table'
import Layout from '@components/admin/layout/AdminLayout'
import PurchaseStatus from '@models/enum/PurchaseStatus'
import prepareApiParams from '@helpers/prepareApiParams'
import PurchaseDetailDrawer from '@components/cfp/purchase/detail/drawer/PurchaseDetailDrawer'
import numeral from 'numeral'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import { adminPurchaseListingPath } from '@constants/routes'

const AdminPurchaseListingPage = ({ config, logger, services: { purchaseService, planTypeService } }) => {
    const tableActionRef = useRef(null)
    const [activeTabKey, setActiveTabKey] = useState(PurchaseStatus.PENDING.enumKey)
    const [toShowPurchaseDetailDrawer, setToShowPurchaseDetailDrawer] = useState(false)
    const [selectedPurchase, setSelectedPurchase] = useState(null)

    const tabList = useMemo(() => {
        const enumValues = Object.values(PurchaseStatus.enumValues)
        const tabList = []
        enumValues.map((enumValue) =>
            tabList.push({
                label: enumValue.label, key: enumValue.enumKey
            })
        )
        return tabList
    }, [])

    const columns = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 300,
                fixed: 'left',
                search: true,
                render: (text, record) => {
                    return text
                }
            },
            {
                title: 'Plan Type',
                dataIndex: 'planTypeId',
                width: 300,
                search: false,
                render: (text, record) => {
                    if (!record.purchasePlans || record.purchasePlans.length === 0) return '-'
                    const purchasePlanType = record.purchasePlans[0]
                    return purchasePlanType.name
                }
            },
            {
                title: 'Full Name',
                dataIndex: 'customerFullName',
                width: 300,
                search: true,
                render: (text, record) => {
                    return text
                }
            },
            {
                title: 'IC Number',
                dataIndex: 'customerIcNumber',
                width: 150,
                search: false,
                render: (text, record) => {
                    return text
                }
            },
            {
                title: 'Email',
                dataIndex: 'customerEmail',
                width: 300,
                search: true,
                render: (text, record) => {
                    return text
                }
            },
            {
                title: 'Contact',
                dataIndex: 'contact',
                width: 170,
                search: false,
                renderText: (text, record) => {
                    if (!record.customerMobilePrefix || !record.customerMobileNumber) return '-'
                    return `+${record.customerMobilePrefix} ${record.customerMobileNumber}`
                }
            },
            {
                title: 'Monthly Income',
                dataIndex: 'customerMonthlyIncome',
                width: 200,
                search: false,
                renderText: (text, record) => {
                    return `MYR ${numeral(record.customerMonthlyIncome).format('0,0.00')}`
                }
            },
            {
                title: 'Original Amount',
                dataIndex: 'originalAmount',
                width: 250,
                search: false,
                renderText: (text, record) => {
                    return `${record.currency} ${numeral(record.originalAmount).format('0,0.00')}`
                }
            },
            {
                title: 'Discount Amount',
                dataIndex: 'discountAmount',
                search: false,
                width: 250,
                renderText: (text, record) => {
                    return `${record.currency} ${numeral(record.discountAmount).format('0,0.00')}`
                }
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                search: false,
                width: 250,
                renderText: (text, record) => {
                    return <span className='text-green-600'>{`${record.currency} ${numeral(record.amount).format('0,0.00')}`}</span>
                }
            },
            {
                title: 'Referral Code',
                search: false,
                dataIndex: 'referralCode',
                width: 250,
                render: (text, record) => {
                    if (!text) return '-'
                    return text
                }
            },
            {
                title: 'Promo Code',
                search: false,
                dataIndex: 'promoCode',
                width: 250,
                render: (text, record) => {
                    if (!text) return '-'
                    return text
                }
            },
            {
                title: 'Status',
                search: false,
                dataIndex: 'status',
                width: 250,
                render: (text, record) => {
                    return (
                        PurchaseStatus.of(text)?.label
                    )
                }
            },
            {
                title: 'Purchased At',
                dataIndex: 'createdAt',
                search: false,
                sorter: true,
                width: 250,
                valueType: 'datetime',
                renderText: (text) => {
                    return moment.utc(text).local().format('D MMM YYYY hh:mm A')
                }
            },
            {
                title: 'Purchased At',
                dataIndex: 'createdAt',
                search: true,
                valueType: 'dateTimeRange',
                hideInTable: true
            },
            {
                title: 'Actions',
                valueType: 'option',
                key: 'option',
                width: 80,
                search: false,
                fixed: 'right',
                render: (text, record, _, action) => {
                    const actionComponents = [
                        <a
                            key='view'
                            onClick={() => {
                                setSelectedPurchase(record)
                                setToShowPurchaseDetailDrawer(true)
                            }}
                        >
                            View
                        </a>
                    ]
                    return actionComponents
                }
            }
        ]
    }, [])
    
    useEffect(() => {
    }, [])

    useEffect(() => {
        tableActionRef.current?.reset()
        tableActionRef.current?.reload()
    }, [activeTabKey])

    const handleRequest = async (params, sort) => {
        try {
            if (params.createdAt) {
                if (params.createdAt[0]) {
                    params.createdStartAt = moment(params.createdAt[0]).format('YYYY-MM-DDThh:mm:ss')
                }
                if (params.createdAt[1]) {
                    params.createdEndAt = moment(params.createdAt[1]).format('YYYY-MM-DDThh:mm:ss')
                }
                delete params.createdAt
            }

            const apiParams = { status: activeTabKey }
            prepareApiParams(params, sort, apiParams)
            const res = await purchaseService.listFromAdmin(apiParams)

            if (res.ok) {
                const data = res.data
                return {
                    data: data.data,
                    success: res.ok,
                    total: data.lastPage * data.perPage
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                return {
                    data: null,
                    success: res.ok,
                    total: 0
                }
            }
        } catch (e) {
            logger.error('[Failed List Purchase]', e)
        }
    }

    const handleTabChange = (key) => {
        setActiveTabKey(key)
    }

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: adminPurchaseListingPath,
                            breadcrumbName: 'Purchases'
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title='Purchases'
                tabList={tabList}
                onTabChange={handleTabChange}
                tabActiveKey={activeTabKey}
            >
                <ProTable
                    actionRef={tableActionRef}
                    search={{
                        defaultCollapsed: false,
                        span: 12,
                        labelWidth: 'auto'
                    }}
                    request={handleRequest}
                    columns={columns}
                    rowKey='id'
                    scroll={{ x: '120%' }}
                    pagination={{
                        showSizeChanger: false
                    }}
                />

                <PurchaseDetailDrawer
                    purchase={selectedPurchase}
                    visible={toShowPurchaseDetailDrawer}
                    onClose={() => setToShowPurchaseDetailDrawer(false)}
                    handleVisible={setToShowPurchaseDetailDrawer}
                />
            </PageContainer>
        </Layout>
    )
}

export default inject('config', 'services', 'logger')(AdminPurchaseListingPage)
