import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import moment from 'moment'
import { Button, Progress, Tag } from 'antd'
import ProTable from '@ant-design/pro-table'
import cn from 'classnames'
import Gender from '@models/enum/Gender'
import CustomerType from '@models/enum/CustomerType'
import Race from '@models/enum/Race'
import numeral from 'numeral'
import { Link } from 'react-router-dom'
import { adminCustomerDetailPath } from '@constants/routes'

const CustomerList = ({ className, style, actionRef, handleRequest, renderColumns, renderToolbar, search, title, scroll }) => {
    const defaultColumns = {
        fullName: {
            title: 'Name',
            dataIndex: 'fullName',
            fixed: 'left',
            width: 120,
            render: (text, record) => (
                <Link to={adminCustomerDetailPath(record.id)}>
                    {text}
                </Link>
            )
        },
        email: {
            title: 'Email',
            dataIndex: 'email',
            width: 250,
            render: (text, record) => (
                <a href={`mailto:${text}`}>
                    {text}
                </a>
            )
        },
        contact: {
            title: 'Contact',
            dataIndex: 'contact',
            search: false,
            width: 150,
            renderText: (text, record) => {
                if (!record.mobilePrefix || !record.mobileNumber) {
                    return '-'
                }
                return `+${record.mobilePrefix} ${record.mobileNumber}`
            }
        },
        icNumber: {
            title: 'Identity Number',
            dataIndex: 'icNumber',
            search: false,
            width: 150,
            renderText: (text, record) => {
                if (!text) return '-'
                return text
            }
        },
        birthDate: {
            title: 'Birth Date',
            dataIndex: 'birthDate',
            search: false,
            width: 100,
            renderText: (text, record) => {
                if (!record.birthDate) return '-'
                const dateStr = moment(record.birthDate).format('D MMM YYYY')
                return dateStr
            }
        },
        gender: {
            title: 'Gender',
            dataIndex: 'gender',
            search: false,
            width: 100,
            renderText: (text, record) => {
                if (record.gender === undefined || record.gender === null) return '-'
                return Gender.of(record.gender).label
            }
        },
        type: {
            title: 'Type',
            dataIndex: 'type',
            search: false,
            width: 100,
            renderText: (text, record) => {
                if (!record.type) {
                    return '-'
                }
                return CustomerType.of(record.type).label
            }
        },
        race: {
            title: 'Race',
            dataIndex: 'race',
            search: false,
            width: 100,
            renderText: (text, record) => {
                if (!record.race) {
                    return '-'
                }
                return Race.of(record.race).label
            }
        },
        monthlyIncome: {
            title: 'Monthly Income',
            dataIndex: 'monthlyIncome',
            search: false,
            width: 200,
            renderText: (text, record) => {
                if (!record.monthlyIncome) {
                    return '-'
                }
                return numeral(record.monthlyIncome).format('0,0.00')
            }
        },
        latestPlanType: {
            title: 'Latest Plan',
            dataIndex: 'latestPlanType',
            search: false,
            filters: true,
            onFilter: true,
            width: 150,
            render: (text, record) => {
                if (!record.latestPlan) return <span>-</span>
                return <span>{record.latestPlan.planTypeName}</span>
            }
        },
        progress: {
            title: 'Progress',
            dataIndex: 'latestPlanProgressScore',
            width: 200,
            search: false,
            render: (text, record) => (
                <Progress percent={text} size='small' />
            ),
            sorter: (a, b) => {
                return a.progress - b.progress
            }
        },
        verified: {
            title: 'Verified',
            dataIndex: 'verified',
            search: false,
            width: 100,
            render: (text, record) => {
                if (record.verified) {
                    return (
                        <Tag color='success'>
                            True
                        </Tag>
                    )
                } else {
                    return (
                        <Tag color='error'>
                            False
                        </Tag>
                    )
                }
            }
        },
        basicAssessmentCreatedAt: {
            title: 'Basic',
            dataIndex: 'basicAssessmentCreatedAt',
            search: false,
            width: 160,
            renderText: (text) => {
                if (text === null || text === undefined) return '-'

                const date = new Date(text)
                const dateStr = moment.utc(date).local().format('D MMM YYYY hh:mm A')
                return dateStr
            }
        },
        doneCashFlowAt: {
            title: 'Cash Flow',
            dataIndex: 'doneCashFlowAt',
            search: false,
            width: 160,
            renderText: (text, record) => {
                if (!record.doneCashFlowAt) {
                    return '-'
                }
                const date = new Date(text)
                const dateStr = moment.utc(date).local().format('D MMM YYYY hh:mm A')
                return dateStr
            }
        },
        doneInsuranceAt: {
            title: 'Insurance',
            dataIndex: 'doneInsuranceAt',
            search: false,
            width: 160,
            renderText: (text, record) => {
                if (!record.doneInsuranceAt) {
                    return '-'
                }
                const date = new Date(text)
                const dateStr = moment.utc(date).local().format('D MMM YYYY hh:mm A')
                return dateStr
            }
        },            
        doneTaxPlanningAt: {
            title: 'Tax Planning',
            dataIndex: 'doneTaxPlanningAt',
            search: false,
            width: 160,
            renderText: (text, record) => {
                if (!record.doneTaxPlanningAt) {
                    return '-'
                }
                const date = new Date(text)
                const dateStr = moment.utc(date).local().format('D MMM YYYY hh:mm A')
                return dateStr
            }
        },            
        doneRetirementAt: {
            title: 'Retirement',
            dataIndex: 'doneRetirementAt',
            search: false,
            width: 160,
            renderText: (text, record) => {
                if (!record.doneRetirementAt) {
                    return '-'
                }
                const date = new Date(text)
                const dateStr = moment.utc(date).local().format('D MMM YYYY hh:mm A')
                return dateStr
            }
        },            
        doneInvestmentAt: {
            title: 'Investment',
            dataIndex: 'doneInvestmentAt',
            search: false,
            width: 160,
            renderText: (text, record) => {
                if (!record.doneInvestmentAt) {
                    return '-'
                }
                const date = new Date(text)
                const dateStr = moment.utc(date).local().format('D MMM YYYY hh:mm A')
                return dateStr
            }
        },
        doneEstatePlanningAt: {
            title: 'Estate Planning',
            dataIndex: 'doneEstatePlanningAt',
            search: false,
            width: 160,
            renderText: (text, record) => {
                if (!record.doneEstatePlanningAt) {
                    return '-'
                }
                const date = new Date(text)
                const dateStr = moment.utc(date).local().format('D MMM YYYY hh:mm A')
                return dateStr
            }
        },
        action: {
            title: 'Actions',
            valueType: 'option',
            key: 'option',
            width: 80,
            search: false,
            fixed: 'right',
            render: (text, record, _, action) => {
                const actionComponents = [
                    <a
                        key='update'
                    >
                        Update
                    </a>
                ]
                return actionComponents
            }
        }
    }

    let columns = Object.values(defaultColumns)
    if (renderColumns) {
        columns = renderColumns(defaultColumns)
    }

    useEffect(() => {
    }, [])

    return (
        <ProTable
            className={cn(className)}
            style={style}
            title={title}
            search={search}
            actionRef={actionRef}
            columns={columns}
            rowKey='id'
            request={handleRequest}
            pagination={{
                showSizeChanger: false
            }}
            toolBarRender={() => {
                const defaultToolbars = []

                if (renderToolbar) {
                    return renderToolbar(defaultToolbars)
                }

                return defaultToolbars
            }}
            scroll={scroll}
        />
    )
}

CustomerList.defaultProps = {
    title: () => 'Customers',
    scroll: {
        x: '120%'
    },
    search: {
        defaultCollapsed: false
    }
}

export default inject('logger', 'services')(CustomerList)
