import { useEffect } from 'react'
import { inject } from 'mobx-react'
import { Link, useNavigate } from 'react-router-dom'
import AdminLoginFormWrapper from '@components/admin/login/form/wrapper/AdminLoginFormWrapper'

const AdminLoginPage = ({ adminStore, config }) => {
    const navigate = useNavigate()

    const handleSuccessSubmit = () => {
        navigate('/admin/dashboard')
    }

    return (
        <AdminLoginFormWrapper
            onSuccessSubmit={handleSuccessSubmit}
        />
    )
}

export default inject('services', 'adminStore', 'config')(AdminLoginPage)
