import { useMemo, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { inject } from 'mobx-react'
import { Button, Avatar, Dropdown, Menu, Modal } from 'antd'
import moment from 'moment'
import ProLayout from '@ant-design/pro-layout'
import {
    GoogleCircleFilled
} from '@ant-design/icons'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill, faUserGroup, faCalendarDays, faGauge, faUser, faP } from '@fortawesome/free-solid-svg-icons'
import { cfpAppointmentListingPath, cfpCustomerListingPath, cfpCustomerPlanListingPath, cfpDashboardPath, cfpProfilePath } from '@constants/routes'

const cfpRoutes = [
    {
        path: cfpDashboardPath,
        name: 'Dashboard',
        icon: <FontAwesomeIcon icon={faGauge} />
    },
    {
        name: 'Customer',
        icon: <FontAwesomeIcon icon={faUserGroup} />,
        routes: [
            {
                path: cfpCustomerListingPath,
                name: 'Customer List'
            }
        ]
    },
    {
        name: 'Plan',
        icon: <FontAwesomeIcon icon={faP} />,
        routes: [
            {
                path: cfpCustomerPlanListingPath,
                name: 'Plan List'
            }
        ]
    },
    {
        name: 'Appointment',
        icon: <FontAwesomeIcon icon={faCalendarDays} />,
        routes: [
            {
                path: cfpAppointmentListingPath,
                name: 'Appointment List'
            }
        ]
    },
    {
        path: cfpProfilePath,
        name: 'Profile',
        icon: <FontAwesomeIcon icon={faUser} />
    }
]

const CfpLayout = ({ cfpStore, logger, config, children }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const currentTime = moment()
    const [pathname, setPathname] = useState(location?.pathname)

    const routes = useMemo(() => {
        const routes = cfpRoutes
        return routes
    }, [cfpStore.role])

    const getGoogleCalendarUrl = async () => {
        try {
            const res = await cfpStore.getGoogleCalendarUrl()
            if (res.ok) {
                window.location.replace(res.data.url)
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res.data.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (e) {
            logger.error('[Failed Get Google Auth Url]', e)
        }
    }

    const logout = () => {
        cfpStore.clear()
        navigate('/cfp/login')
    }

    const userDropdownMenu = useMemo(() => {
        return (
            <Menu
                items={[
                    {
                        key: 'profile',
                        label: (
                            <Link to='/cfp/profile'>
                                Profile
                            </Link>
                        )
                    },
                    {
                        key: 'logout',
                        label: (
                            <div onClick={logout}>
                                Logout
                            </div>
                        )
                    }
                ]}
            />
        )
    }, [])

    return (
        <ProLayout
            title={`${config.app.appName} - CFP`}
            logo={null}
            layout='mix'
            route={{
                path: '/',
                routes
            }}
            location={{
                pathname
            }}
            menu={{
                collapsedShowGroupTitle: true
            }}
            menuFooterRender={(props) => {
                if (props?.collapsed) return undefined
                return (
                    <div
                        style={{
                            textAlign: 'center',
                            paddingBlockStart: 12
                        }}
                    >
                        <div>© {currentTime.format('YYYY')} {config.app.appName}</div>
                    </div>
                )
            }}
            menuItemRender={(item, dom) => (
                <Link to={item.path}>
                    {dom}
                </Link>
            )}
            actionsRender={(props) => {
                return [
                    <Button key='btn-sync-google-calendar' className='mr-2' type='default' onClick={getGoogleCalendarUrl}>
                        <GoogleCircleFilled /> Sync Google Calendar
                    </Button>,
                    <Dropdown key='user-dropdown' overlay={userDropdownMenu} arrow size='large'>
                        <Avatar style={{ backgroundColor: '#1890ff', verticalAlign: 'middle' }} size='default'>
                            {cfpStore.fullName.charAt(0)}
                        </Avatar>
                    </Dropdown>
                ]
            }}
        >
            {children}
        </ProLayout>
    )
}

export default inject('cfpStore', 'logger', 'config')(CfpLayout)
