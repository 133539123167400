import { Enumify } from 'enumify'

export default class PurchaseStatus extends Enumify {
    value = null

    constructor (value = null, label = null) {
        super()
        this.value = value
        this.label = label
    }

    static of (type) {
        return PurchaseStatus.enumValueOf(type)
    }

    static for (type) {
        switch (type) {
            case 0: return PurchaseStatus.PENDING
            case 1: return PurchaseStatus.PAID
            case 2: return PurchaseStatus.FAILED
        }
        return null
    }
}

PurchaseStatus.PENDING = new PurchaseStatus(0, 'Pending')
PurchaseStatus.PAID = new PurchaseStatus(1, 'Paid')
PurchaseStatus.FAILED = new PurchaseStatus(2, 'Failed')
PurchaseStatus._ = PurchaseStatus.closeEnum()
