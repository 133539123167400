import React, { useEffect, useState } from 'react'
import { startCase, camelCase } from 'lodash'
import { Spin } from 'antd'
import { inject } from 'mobx-react'
import showApiErrorMessage from '@helpers/showApiErrorMessage'

const CfpCustomerReport = ({ logger, id, services }) => {
    const [loading, setLoading] = useState(false)
    const { customerService } = services
    const [data, setData] = useState(null)

    const getCustomerReport = async (id) => {
        try {
            setLoading(true)
            const apiParams = { id }
            const res = await customerService.getReportFromCfp(apiParams)

            if (res.ok) {
                const data = res.data
                setData(data)
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
            }
        } catch (e) {
            logger.error('[Failed Get Customer[', e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id) {
            getCustomerReport(id)
        }
    }, [id])

    return (
        <div>
            <h1>Customer Report</h1>

            <div>
                {loading && <Spin className='mt-3' />}

                {!loading && data && Object.keys(data).map((categoryName, j) => {
                    const category = data[categoryName]
                    const formattedCategoryName = startCase(camelCase(categoryName))

                    return (
                        <div key={j} className='mb-4'>
                            {!category.report &&
                                <p>No {formattedCategoryName.toLowerCase()} report found</p>}

                            {category.report?.map((item, i) => (
                                <div key={i} className='mb-4'>
                                    <div className='font-bold text-blue-500 text-[16px] mb-2 underline underline-offset-4'>{formattedCategoryName} ({item.name})</div>
                                    {item.list?.map((list, k) => (
                                        <div key={k}>
                                            <div className='font-bold text-blue-500 mb-2 mt-3'>{list.header}</div>
                                            <div dangerouslySetInnerHTML={{ __html: list.content }}></div>
                                        </div>

                                    ))}
                                </div>
                            ))}
                        </div>
                    )
                })}

                {!data && !loading &&
                <p>No report found</p>}
            </div>
        </div>
    )
}

export default inject('logger', 'services')(CfpCustomerReport)