import { Enumify } from 'enumify'

export default class DiscountType extends Enumify {
    value = null

    constructor (value = null, label = null) {
        super()
        this.value = value
        this.label = label
    }

    static of (type) {
        return DiscountType.enumValueOf(type)
    }

    static for (type) {
        switch (type) {
            case 0: return DiscountType.PERCENT
            case 1: return DiscountType.PRICE
        }
        return null
    }
}

DiscountType.PERCENT = new DiscountType(0, 'Percent')
DiscountType.PRICE = new DiscountType(1, 'Price')
DiscountType._ = DiscountType.closeEnum()
