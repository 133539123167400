import { inject } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'
import CfpPrivateRoute from './CfpPrivateRoute'
import CfpAppointmentListingPage from '@pages/cfp/appointments'
import CfpAppointmentDetailPage from '@pages/cfp/appointments/[id]'

const CfpAppointmentRoutes = (props) => {
    return (
        <Routes>
            <Route
                path='/'
                exact
                element={
                    <CfpPrivateRoute>
                        <CfpAppointmentListingPage />
                    </CfpPrivateRoute>
                }
            />
            <Route
                path='/:id'
                element={
                    <CfpPrivateRoute>
                        <CfpAppointmentDetailPage />
                    </CfpPrivateRoute>
                }
            />
        </Routes>
    )
}

export default inject('logger')(CfpAppointmentRoutes)
