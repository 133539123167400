import moment from 'moment'
import { string, object, number } from 'yup'

const schema = object().shape({
    name: string()
        .required(),
    description: string()
        .required(),
    venue: string()
        .required(),
    startAt: string()
        .required()
        .test(
            'is-before-end-time',
            'Start time must be before end time',
            (value, context) => {
                return moment(value).isBefore(context.parent.endAt)
            }
        ),
    endAt: string()
        .required()
        .test(
            'is-after-start-time',
            'End time must be after start time',
            (value, context) => {
                return moment(value).isAfter(context.parent.startAt)
            }
        ),
    price: number()
        .required()
})

export default schema
