import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import AdminPrivateRoute from './AdminPrivateRoute'
import AdminRole from '@models/enum/AdminRole'
import AdminPlanTypeListingPage from '@pages/admin/plan-types'
import AdminPlanTypeDetailPage from '@pages/admin/plan-types/[slug]'

const AdminPlanTypeRoutes = (props) => {
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <AdminPrivateRoute roles={[AdminRole.SUPER_ADMIN, AdminRole.ADMIN]}>
                        <AdminPlanTypeListingPage />
                    </AdminPrivateRoute>
                }
            />

            <Route
                path='/:slug'
                element={
                    <AdminPrivateRoute roles={[AdminRole.SUPER_ADMIN, AdminRole.ADMIN]}>
                        <AdminPlanTypeDetailPage />
                    </AdminPrivateRoute>
                }
            />
        </Routes>
    )
}

export default AdminPlanTypeRoutes
