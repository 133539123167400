import { UploadOutlined } from '@ant-design/icons'
import { Button, message, Upload, Image } from 'antd'
import React, { useState } from 'react'
import { inject } from 'mobx-react'

const CfpProfileBannerUpload = props => {
    const logger = props.logger
    const cfpStore = props.cfpStore
    const [fileList, setFileList] = useState([])
    const [uploading, setUploading] = useState(false)
    const id = props.defaultValues.id
    const profileBannerUrl = props.defaultValues.profileBannerUrl
    const [currentProfileBannerUrl, setCurrentProfileBannerUrl] = useState(profileBannerUrl)
    const [toPreviewBanner, setToPreviewBanner] = useState(false)

    const handleUpload = async data => {
        const formData = new FormData()
        fileList.forEach((file) => {
            formData.append('id', id)
            formData.append('profilePicture', file)
        })

        try {
            setUploading(true)
            const res = await cfpStore.uploadProfileBanner(formData)
            if (res.ok) {
                setCurrentProfileBannerUrl(res.data.profileBannerUrl)
                setFileList([])
                message.success('Upload successful.')
            } else {
                message.error('Upload failed.')
            }
        } catch (error) {
            message.error('Upload failed.')
        } finally {
            setUploading(false)
        }
    }

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            setFileList(newFileList)
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file])
            return false
        },
        fileList
    }
    return (
        <div className='flex flex-col items-start'>
            {currentProfileBannerUrl &&
                <>
                    <Button className='mb-3' type='primary' onClick={() => setToPreviewBanner(true)}>
                        View
                    </Button>

                    <Image
                        className='mb-3 hidden'
                        src={currentProfileBannerUrl}
                        preview={{
                            visible: toPreviewBanner,
                            onVisibleChange: value => {
                                setToPreviewBanner(value)
                            }
                        }}
                    />
                </>}

            <Upload {...uploadProps} className='mb-3'>
                <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>

            <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0}
                loading={uploading}
            >
                {uploading ? 'Uploading' : 'Start Upload'}
            </Button>
        </div>
    )
}
export default inject('cfpStore', 'logger')(CfpProfileBannerUpload)