import { Drawer } from 'antd'
import AppointmentDetail from '@components/shared/appointment/detail/AppointmentDetail'

const AdminAppointmentDetailDrawer = ({ className, style, loading, visible, onClose, appointment, ...props }) => {
    return (
        <Drawer
            width={700}
            open={visible}
            onClose={onClose}
            closable={false}
            destroyOnClose
        >
            {appointment &&
            <AppointmentDetail
                className={className}
                style={style}
                loading={loading}
                appointment={appointment}
            />}
        </Drawer>
    )
}

export default AdminAppointmentDetailDrawer
