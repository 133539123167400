import { useEffect, useState } from 'react'
import { inject } from 'mobx-react'
import { Modal, Button, Table } from 'antd'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import CfpAddAppointmentAttachmentModal from '../../add/modal/CfpAddAppointmentAttachmentModal'
import CfpDeleteAppointmentAttachmentModal from '../../add/modal/CfpDeleteAppointmentAttachmentModal'

const CfpAppointmentAttachmentList = (props) => {
    const logger = props.logger
    const appointmentService = props.services.appointmentService
    
    const id = props?.appointment?.id

    const [submitting, setSubmitting] = useState(false)
    const [addAppointmentAttachmentProps, setAddAppointmentAttachmentProps] = useState(null)
    const [deleteModalProps, setDeleteModalProps] = useState(null)
    const [files, setFiles] = useState(null)

    useEffect(() => {
        setFiles(props?.appointment?.attachment)
    }, [props])

    const handleAddFileInSessions = (id) => {
        setAddAppointmentAttachmentProps({
            defaultValues: {
                id
            }
        })
    }

    const handleViewFile = async (attachmentId) => {
        try {
            setSubmitting(true)

            const formData = new FormData()
            formData.append('attachmentId', attachmentId)
            formData.append('id', id)

            const res = await appointmentService.viewFileFromCfp(formData)

            if (res.ok && res.data) {
                window.open(res?.data?.attachmentUrl, '_blank')

            } else {
                const errors = res?.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: <p>Something went wrong when trying to view file</p>
                    })
                }
            }

        } catch (error) {
            logger.error('Fail to get view file url', error)
        } finally {
            setSubmitting(false)
        }
    }

    const handleSucccesDelete = () => {
        Modal.info({
            title: 'File deleted successfully!',
            content: (
                <p>
                    Your file has been deleted.
                </p>
            )
        })
    }

    const handleConfirmDelete = (attachment) => {
        setDeleteModalProps(attachment)
    }

    const handleDeleteFile = async (attachmentId) => {
        try {
            setSubmitting(true)

            const formData = new FormData()
            formData.append('attachmentId', attachmentId)
            formData.append('id', id)

            const res = await appointmentService.deleteFileFromCfp(formData)

            if (res.ok && res.data) {
                updateAppointmentAttachments(res.data)
                handleSucccesDelete()
            } else {
                const errors = res?.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: <p>Something went wrong when trying to delete attachment</p>
                    })
                }
            }

        } catch (error) {
            logger.error('Fail to delete attachment', error)
        } finally {
            setSubmitting(false)
        }
    }

    const columns = [
        {
            title: 'File Name',
            dataIndex: 'title'
        },
        // {
        //     title: 'View File',
        //     dataIndex: 'attachmentId',
        //     render: (attachmentId) => (
        //         <Button onClick={() => handleViewFile(attachmentId)}>View File</Button>
        //     )
        // },
        // {
        //     title: 'Delete File',
        //     dataIndex: 'attachment',
        //     render: (attachment) => (
        //         <Button onClick={() => handleConfirmDelete(attachment)}>Delete File</Button>
        //     )
        // },
        {
            title: 'Action',
            dataIndex: 'attachment',
            render: (attachment) => (
                <div>
                    <Button onClick={() => handleConfirmDelete(attachment)}>Delete File</Button>
                    <Button className='ml-3' onClick={() => handleViewFile(attachment?.attachmentId)}>View File</Button>
                </div>
            )
        }
    ]



    const dataSource = files ? Object.entries(files).map(([attachmentId, attachment]) => ({
        key: attachmentId,
        title: attachment?.title,
        attachmentId: attachment?.attachmentId,
        attachment
    })) : []

    const updateAppointmentAttachments = (updatedAttachments) => {
        setFiles(updatedAttachments)
    }

    return (
        <>
            <div className='mb-3'>
                <Button onClick={() => { handleAddFileInSessions(id) }}>Add Attachment</Button>
            </div>
            <Table columns={columns} dataSource={dataSource} />
            <CfpDeleteAppointmentAttachmentModal
                record={deleteModalProps}
                visible={!!deleteModalProps}
                onCancel={() => setDeleteModalProps(null)}
                handleDeleteFile={handleDeleteFile}
            />
            <CfpAddAppointmentAttachmentModal
                form={addAppointmentAttachmentProps}
                visible={!!addAppointmentAttachmentProps}
                onCancel={() => setAddAppointmentAttachmentProps(null)}
                updateAppointmentAttachments={updateAppointmentAttachments}
            />
        </>
    )
}
export default inject('services', 'logger')(CfpAppointmentAttachmentList)
