export const adminDashboardPath = '/admin'
export const adminPlanTypeListingPath = '/admin/plan-types'
export const adminCreatePlanTypePath = '/admin/plan-type/create'
export const adminPlanTypeDetailPath = (planTypeId) => `/admin/plan-types/${planTypeId}`
export const adminCustomerListingPath = '/admin/customers'
export const adminCustomerDetailPath = (customerId) => `/admin/customers/${customerId}`
export const adminCfpListingPath = '/admin/certified-financial-planners'
export const adminCustomerPlanListingPath = '/admin/plans'
export const adminCustomerPlanDetailPath = (planId) => `/admin/plans/${planId}`
export const adminAppointmentListingPath = '/admin/appointments'
export const adminAppointmentDetailPath = (appointmentId) => `/admin/appointments/${appointmentId}`
export const adminPurchaseListingPath = '/admin/purchases'
export const adminEventListingPath = '/admin/events'
export const adminPromoCodeListingPath = '/admin/promo-codes'


export const cfpDashboardPath = '/cfp'
export const cfpCustomerListingPath = '/cfp/customers'
export const cfpCustomerDetailPath = (customerId) => `/cfp/customers/${customerId}`
export const cfpCustomerPlanListingPath = '/cfp/plans'
export const cfpCustomerPlanDetailPath = (planId) => `/cfp/plans/${planId}`
export const cfpAppointmentListingPath = '/cfp/appointments'
export const cfpAppointmentDetailPath = (appointmentId) => `/cfp/appointments/${appointmentId}`
export const cfpProfilePath = '/cfp/profile'