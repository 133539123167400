import React, { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Upload, message } from 'antd'

const UploadFile = (props) => {
    const [fileList, setFileList] = useState([])

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            setFileList(newFileList)
        },
        beforeUpload: (file) => {
            setFileList([file])
            return false
        },
        fileList
    }

    const onDropFiles = (e) => {
        const droppedFiles = Array.from(e.dataTransfer.files)
        const invalidFiles = droppedFiles.filter((file) => {
            const allowedFormats = ['.pdf', '.doc', '.docx', '.xlsx', '.xls', '.jpg', '.jpeg', '.png', '.gif', '.zip']

            const fileExtension = file.name.split('.').pop().toLowerCase()
            return !allowedFormats.includes(`.${fileExtension}`)
        })

        if (invalidFiles.length > 0) {
            message.error(
                'Invalid file format. Please drop files of type .pdf, .doc, .docx, .xlsx, .xls, .jpg, .jpeg, .png, .gif or .zip'
            )
            e.preventDefault()
        }
    }

    return (
        <>
            <Upload.Dragger
                className=''
                {...uploadProps}
                {...props}
                maxCount={1}
                accept='.pdf,.doc,.docx,.xlsx,.xls,.jpg,.jpeg,.png,.gif,.zip'
                onDrop={onDropFiles}
            >
                <Button
                    icon={<PlusOutlined style={{ color: '#A9AEBF' }} />}
                ></Button>
            </Upload.Dragger>
        </>
    )
}
export default UploadFile
