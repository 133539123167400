import { Container } from 'inversify'
import 'reflect-metadata'
import config from '@config'
import WebClient from '@utils/WebClient'
import MockWebClient from '@utils/MockWebClient'
import { createCfpStore } from '@models/cfp'
import CfpService from '@services/CfpService'
import CustomerService from '@services/CustomerService'
import AppointmentService from '@services/AppointmentService'
import PurchaseService from '@services/PurchaseService'
import EventService from '@services/EventService'
import PromoCodeService from '@services/PromoCodeService'
import PlanTypeService from '@services/PlanTypeService'
import logger from '@utils/logger'
import AdminService from '@services/AdminService'
import { createAdminStore } from '@models/admin'
import CustomerPlanService from '@services/CustomerPlanService'
import JSEncrypt from 'jsencrypt'

const generateAppContainer = async () => {
    const container = new Container()
    container.bind('config').toConstantValue(config)

    // Logger
    logger.setLevel(logger[config.app.logLevel])
    container.bind('logger').toConstantValue(logger)

    // Web Clients
    const toMockAPIWithoutServer = (config.app.mockApi && !config.app.mockApiWithServer)
    if (toMockAPIWithoutServer) {
        const options = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            timeout: 30000
        }
    
        const mockEndpointModule = await import('api-mock/src/endpoints')
        const endpoints = mockEndpointModule.default
    
        const webClient = new MockWebClient(options, endpoints)
        container.bind('webClient').toConstantValue(webClient)
    } else {
        const adminWebClientOptions = {
            baseURL: config.app.apiUrl,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            timeout: 30000
        }
    
        const adminWebClient = new WebClient(adminWebClientOptions, logger)
        container.bind('adminWebClient').toConstantValue(adminWebClient)

        const cfpWebClientOptions = {
            baseURL: config.app.apiUrl,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            timeout: 30000
        }
    
        const cfpWebClient = new WebClient(cfpWebClientOptions, logger)
        container.bind('cfpWebClient').toConstantValue(cfpWebClient)
    }

    // Encryption
    const credentialEncrypter = new JSEncrypt()
    credentialEncrypter.setPublicKey(config.app.credentialEncryptionPublicKey)

    // Services
    const adminService = new AdminService(container.get('adminWebClient'), credentialEncrypter)
    container.bind('adminService').toConstantValue(adminService)

    const cfpService = new CfpService(container.get('adminWebClient'), container.get('cfpWebClient'), credentialEncrypter)
    container.bind('cfpService').toConstantValue(cfpService)

    const customerService = new CustomerService(container.get('adminWebClient'), container.get('cfpWebClient'))
    container.bind('customerService').toConstantValue(customerService)

    const appointmentService = new AppointmentService(container.get('adminWebClient'), container.get('cfpWebClient'))
    container.bind('appointmentService').toConstantValue(appointmentService)

    const purchaseService = new PurchaseService(container.get('adminWebClient'))
    container.bind('purchaseService').toConstantValue(purchaseService)

    const eventService = new EventService(container.get('adminWebClient'))
    container.bind('eventService').toConstantValue(eventService)
    
    const planTypeService = new PlanTypeService(container.get('adminWebClient'))
    container.bind('planTypeService').toConstantValue(planTypeService)

    const customerPlanService = new CustomerPlanService(container.get('adminWebClient'), container.get('cfpWebClient'))
    container.bind('customerPlanService').toConstantValue(customerPlanService)

    const promoCodeService = new PromoCodeService(container.get('adminWebClient'))
    container.bind('promoCodeService').toConstantValue(promoCodeService)

    const services = {
        adminService, cfpService, customerService, appointmentService, purchaseService, eventService,
        planTypeService, customerPlanService, promoCodeService
    }
    container.bind('services').toConstantValue(services)

    // Models
    const adminStore = createAdminStore(
        {
            id: null,
            fullName: null,
            email: null,
            accessToken: null,
            refreshToken: null
        },
        {
            adminService,
            webClient: container.get('adminWebClient'),
            logger
        }
    )
    container.bind('adminStore').toConstantValue(adminStore)

    const cfpStore = createCfpStore(
        {
            id: null,
            fullName: null,
            email: null,
            accessToken: null,
            refreshToken: null,
            type: null
        },
        {
            cfpService,
            webClient: container.get('cfpWebClient'),
            logger
        }
    )
    container.bind('cfpStore').toConstantValue(cfpStore)

    return container
}

export default (async () => {
    const appContainer = await generateAppContainer()
    return appContainer
})()
