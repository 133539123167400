import React, { useState, useEffect, useMemo } from 'react'
import { Button, Divider, notification, Space } from 'antd'
import OnlineStatusContext from './OnlineStatusContext'
import { destroy } from 'mobx-state-tree'

const { Provider } = OnlineStatusContext

const OnlineStatusProvider = props => {
    const [onlineStatus, setOnlineStatus] = useState(true)
    const [toShowNotification, setToShowNotification] = useState(false)
    const [api, contextHolder] = notification.useNotification()

    const showOfflineNotification = () => {
        api.error({
            key: 'offline-notification',
            message: 'You are currently offline',
            description: 'Make sure your device has an active Internet connection',
            placement: 'bottomLeft',
            duration: 0
        })
    }

    const showOnlineNotification = () => {
        api.success({
            key: 'online-notification',
            message: 'Internet connection restored',
            description: 'Your Internet connection was restored',
            placement: 'bottomLeft',
            duration: 0
        })
    }

    const destroyNotification = () => {
        notification.close('offline-notification')
        notification.close('online-notification')
    }

    useEffect(() => {
        const handleOfflineConnection = () => {
            setOnlineStatus(false)
            setToShowNotification(true)
        }

        const handleOnlineConnection = () => {
            setOnlineStatus(true)
            setToShowNotification(true)
        }

        window.addEventListener('offline', handleOfflineConnection)
        window.addEventListener('online', handleOnlineConnection)
    
        return () => {
            window.removeEventListener('offline', handleOfflineConnection)
            window.removeEventListener('online', handleOnlineConnection)
        }
    }, [])

    useEffect(() => {
        if (!toShowNotification) return
        destroyNotification()

        if (onlineStatus) {
            showOnlineNotification()
        } else {
            showOfflineNotification()
        }
    }, [onlineStatus, toShowNotification])

    return (
        <Provider value={{ onlineStatus }}>
            {contextHolder}
            {props.children}
        </Provider>
    )
}

export default OnlineStatusProvider
