import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import numeral from 'numeral'
import { Link } from 'react-router-dom'
import { Drawer, Typography } from 'antd'
import ProDescriptions from '@ant-design/pro-descriptions'
import PromoStatus from '@models/enum/PromoCodeStatus'

const PromoCodeDetailDrawer = (props) => {
    const [promo, setPromo] = useState(props.promo)

    const columns = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: 'Code',
                dataIndex: 'code'
            },
            {
                title: 'Discount Type',
                dataIndex: 'discountType',
                render: (text, record) => {
                    if (!text) return '-'
                    return text
                }
            },
            {
                title: 'Discount Value',
                dataIndex: 'discountValue',
                render: (text, record) => {
                    if (!record) return '-'
                    if (record.discountType === 'PRICE') {
                        return 'MYR ' + numeral(record.discountValue).format('0,0.00') 
                    } else {
                        return numeral(record.discountValue).format('0,0.00') + '%'
                    }
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (text, record) => {
                    return (
                        PromoStatus.of(text)?.label
                    )
                }
            },
            {
                title: 'Expired At',
                dataIndex: 'expiredAt',
                width: 250,
                valueType: 'datetime',
                renderText: (text) => {
                    if (!text) return '-'
                    return moment.utc(text).local().format('D MMM YYYY hh:mm A')
                }
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                width: 250,
                valueType: 'datetime',
                renderText: (text) => {
                    return moment.utc(text).local().format('D MMM YYYY hh:mm A')
                }
            },
            {
                title: 'Description',
                dataIndex: 'description',
                render: (text, record) => {
                    return text
                }
            }

        ]
    }, [])

    useEffect(() => {
        if (props.promo) {
            setPromo(props.promo)
        }
    }, [props.promo])

    return (
        <Drawer
            width={700}
            open={props.visible}
            onClose={props.onClose}
            closable={false}
        >
            {promo &&
            <ProDescriptions
                title={'Promo Code Detail'}
                bordered
                layout='horizontal'
                size='small'
                column={1}
                columns={columns}
                dataSource={promo}
            />}
        </Drawer>
    )
}

export default PromoCodeDetailDrawer