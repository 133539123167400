import { Enumify } from 'enumify'

export default class GoalMapperLinkType extends Enumify {
    value = null

    constructor(value = null) {
        super()
        this.value = value
    }

    static of(type) {
        return GoalMapperLinkType.enumValueOf(type)
    }

    static for(type) {
        switch (type) {
            case 0:
                return GoalMapperLinkType.INDIVIDUAL
            case 1:
                return GoalMapperLinkType.COUPLE
        }
        return null
    }
}

GoalMapperLinkType.INDIVIDUAL = new GoalMapperLinkType(0)
GoalMapperLinkType.COUPLE = new GoalMapperLinkType(1)
GoalMapperLinkType._ = GoalMapperLinkType.closeEnum()
