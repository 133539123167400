import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

const AdminPublicRoute = ({ children, adminStore, restricted, ...restProps }) => {
    const [isAuthed, setIsAuthed] = useState(adminStore.accessToken && adminStore.isLogined)

    useEffect(() => {
        setIsAuthed(adminStore.accessToken && adminStore.isLogined)
    }, [adminStore.accessToken, adminStore.isLogined])

    if (isAuthed && restricted) {
        return <Navigate to={restProps.redirectTo} replace />
    }

    return children
}

AdminPublicRoute.propTypes = {
    restricted: PropTypes.bool,
    redirectTo: PropTypes.object
}

AdminPublicRoute.defaultProps = {
    restricted: false,
    redirectTo: {
        pathname: '/admin/dashboard'
    }
}

export default inject('adminStore')(observer(AdminPublicRoute))
