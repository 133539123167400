import { useState, useEffect, useMemo } from 'react'
import { Route, Navigate, useLocation, matchRoutes, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spin, Modal } from 'antd'
import { inject, observer } from 'mobx-react'

const CfpPrivateRoute = ({ logger, children, cfpStore, roles, ...restProps }) => {
    const [isAuthed, setIsAuthed] = useState(false)
    const [checking, setChecking] = useState(true)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (cfpStore?.initiated) {
            if (cfpStore?.accessToken) {
                getProfile()
            } else {
                setChecking(false)
                setIsAuthed(false)
            }
        }
    }, [cfpStore?.initiated, cfpStore.isLogined])

    useEffect(() => {
        if (cfpStore?.initiated) {
            if (!cfpStore.hasPassword && location.pathname !== '/cfp/set-password') {
                navigate('/cfp/set-password')
            }
        }
    }, [cfpStore?.initiated, cfpStore.isLogined, location])

    const getProfile = async () => {
        try {
            setChecking(true)
            const res = await cfpStore.getProfile()

            if (res.ok && res.data) {
                setIsAuthed(res.ok)
                setChecking(false)
            } else if (res.status === 401) {
                cfpStore.clear()
                setIsAuthed(false)
                setChecking(false)
            }
        } catch (error) {
            setIsAuthed(false)
            console.error('[Failed Get Profile]', error)
        }
    }

    if (checking) {
        return (
            <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
                <Spin size='large' />
            </div>
        )
    }
    
    if (!isAuthed) {
        return <Navigate to={restProps.redirectTo} replace />
    }
    
    return children
}

CfpPrivateRoute.propTypes = {
    redirectTo: PropTypes.object,
    roles: PropTypes.array
}

CfpPrivateRoute.defaultProps = {
    redirectTo: {
        pathname: '/cfp/login'
    },
    roles: []
}

export default inject('cfpStore', 'logger')(observer(CfpPrivateRoute))
