import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import CustomerDetail from '@components/shared/customer/detail/CustomerDetail'
import { cfpCustomerDetailPath } from '@constants/routes'
import { Link } from 'react-router-dom'

const CfpCustomerDetail = ({ logger, services, className, style, id, onGetCustomerSuccess, renderColumns, search }) => {
    const { customerService } = services
    const [loading, setLoading] = useState(true)
    const [customer, setCustomer] = useState(null)

    useEffect(() => {
        getCustomer(id)
    }, [id])

    useEffect(() => {
        if (customer) {
            onGetCustomerSuccess(customer)
        }
    }, [customer])

    const getCustomer = async (id) => {
        try {
            setLoading(true)
            const apiParams = { id }
            const res = await customerService.getFromCfp(apiParams)

            if (res.ok) {
                const data = res.data
                setCustomer(data)
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
            }
        } catch (e) {
            logger.error('[Failed Get Customer]', e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <CustomerDetail
                className={className}
                style={style}
                loading={loading}
                customer={customer}
                renderColumns={(defaultColumns) => {
                    defaultColumns.customer = {
                        ...defaultColumns.customer,
                        render: (text, record) => {
                            if (!record?.customer?.fullName) return '-'
                            return (
                                <Link to={cfpCustomerDetailPath(record.customerId)}>
                                    {record.customer.fullName}
                                </Link>
                            )
                        }
                    }

                    if (renderColumns) {
                        return renderColumns(defaultColumns)
                    }

                    return Object.values(defaultColumns)
                }}
            />
        </>
    )
}

export default inject('logger', 'services')(CfpCustomerDetail)
