import moment from 'moment'
import { string, object } from 'yup'

const schema = object().shape({
    startTime: string()
        .nullable()
        .required()
        .test(
            'is-valid',
            'Start time must be a valid date',
            (value, context) => {
                return moment(value).isValid()
            }
        ),
    cfpId: string()
        .nullable()
})

export default schema
