import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import prepareApiParams from '@helpers/prepareApiParams'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import AppointmentList from '@components/shared/appointment/list/AppointmentList'
import { Button, Modal } from 'antd'
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import CfpAppointmentDetailDrawer from '../detail/drawer/CfpAppointmentDetailDrawer'
import AppointmentStatus from '@models/enum/AppointmentStatus'
import CfpUpdateAppointmentModal from '../update/modal/CfpUpdateAppointmentModal.jsx'
import { cfpAppointmentDetailPath } from '@constants/routes'
import moment from 'moment-timezone'

const CfpAppointmentList = ({ logger, services, className, style, defaultFilters, renderColumns, search, onSuccessCancel, onSuccessComplete, onSuccessUpdate }) => {
    const tableActionRef = useRef(null)
    const { appointmentService } = services
    const [filters, setFilters] = useState(defaultFilters)
    const [selectedAppointment, setSelectedAppointment] = useState(null)
    const [updateAppointmentFormProps, setUpdateAppointmentFormProps] = useState(null)

    useEffect(() => {
    }, [])

    useEffect(() => {
        setFilters(defaultFilters)
        tableActionRef.current?.reset()
        tableActionRef.current?.reload()
    }, [defaultFilters])

    const handleRequest = async (params, sort) => {
        try {
            const apiParams = {
                ...filters
            }
            prepareApiParams(params, sort, apiParams)
            const res = await appointmentService.listFromCfp(apiParams)

            if (res.ok) {
                const data = res.data
                return {
                    data: data.data,
                    success: res.ok,
                    total: data.lastPage * data.perPage
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                return {
                    data: null,
                    success: res.ok,
                    total: 0
                }
            }
        } catch (e) {
            logger.error('[Failed List Appointment]', e)
        }
    }

    return (
        <>
            <AppointmentList
                actionRef={tableActionRef}
                className={className}
                style={style}
                title={null}
                search={search}
                handleRequest={handleRequest}
                renderColumns={(defaultColumns) => {
                    defaultColumns.action = {
                        ...defaultColumns.action,
                        render: (text, record, _, action) => {
                            const actionComponents = [
                                <Link to={cfpAppointmentDetailPath(record.id)} key='view'>
                                    View
                                </Link>
                            ]
                            return actionComponents
                        }
                    }

                    if (renderColumns) {
                        return renderColumns(defaultColumns)
                    }

                    return Object.values(defaultColumns)
                }}
                isCfp={true}
            />

            <CfpUpdateAppointmentModal
                form={updateAppointmentFormProps}
                visible={!!updateAppointmentFormProps}
                onCancel={() => setUpdateAppointmentFormProps(null)}
            />

            <CfpAppointmentDetailDrawer
                appointment={selectedAppointment}
                visible={!!selectedAppointment}
                onClose={() => setSelectedAppointment(null)}
            />
        </>
    )
}

export default inject('logger', 'services')(CfpAppointmentList)
