import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { inject } from 'mobx-react'
import moment from 'moment'
import { Form, Select, Input, TimePicker, InputNumber, Modal, Divider, Card } from 'antd'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import SubmitButton from '@components/common/button/submit/SubmitButton'

const AddCfpForm = (props) => {
    const logger = props.logger
    const cfpService = props.services.cfpService
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const { handleSubmit, setError, control, reset, watch, setValue, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            const params = data

            if (params.settings.appointment.defaultStartTime) {
                params.settings.appointment.defaultStartTime = moment(params.settings.appointment.defaultStartTime).format('HH:mm')
            }

            if (params.settings.appointment.defaultEndTime) {
                params.settings.appointment.defaultEndTime = moment(params.settings.appointment.defaultEndTime).format('HH:mm')
            }

            params.settings = JSON.stringify(params.settings)
            const res = await cfpService.addFromAdmin(params)

            if (res.ok && res.data) {
                props.onSuccessSubmit(res.data)
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (error) {
            logger.error('[Fail Submit Add Cfp Form', error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            <div className='grid grid-cols-12 gap-x-3'>
                <Form.Item
                    className='col-span-12 mb-4'
                    label='Type'
                    validateStatus={(errors?.type?.message) ? 'error' : '-'}
                    help={errors?.type?.message}
                >
                    <Controller
                        name='type'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Select
                                placeholder='Select type'
                                options={[
                                    { label: 'Conventional', value: 'CONVENTIONAL' },
                                    { label: 'Islamic', value: 'ISLAMIC' }
                                ]}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='col-span-6 mb-4'
                    label='Name'
                    validateStatus={(errors?.name?.message) ? 'error' : '-'}
                    help={errors?.name?.message}
                >
                    <Controller
                        name='name'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Input
                                ref={ref}
                                placeholder='e.g. John Smith'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='col-span-6 mb-4'
                    label='Email'
                    validateStatus={(errors?.email?.message) ? 'error' : '-'}
                    help={errors?.email?.message}
                >
                    <Controller
                        name='email'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Input
                                ref={ref}
                                placeholder='e.g. john.smith@email.com'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='mb-4 col-span-6'
                    label='Mobile Prefix'
                    validateStatus={(errors?.mobilePrefix?.message) ? 'error' : '-'}
                    help={errors?.mobilePrefix?.message}
                >
                    <Controller
                        name='mobilePrefix'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Input
                                ref={ref}
                                placeholder='e.g. 60'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='mb-4 col-span-6'
                    label='Mobile Number'
                    validateStatus={(errors?.mobileNumber?.message) ? 'error' : '-'}
                    help={errors?.mobileNumber?.message}
                >
                    <Controller
                        name='mobileNumber'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Input
                                ref={ref}
                                placeholder='e.g. 123456789'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>
            </div>

            <Divider orientation='center' orientationMargin='0'><span className='text-gray-300'>Settings</span></Divider>

            <Card title='Appointment' className='mb-4' size='small'>
                <div className='mb-4 grid grid-cols-12 gap-4'>
                    <Form.Item
                        className='mb-0 col-span-6'
                        label='Default Start Time'
                        validateStatus={(errors?.settings?.appointment?.defaultStartTime?.message) ? 'error' : '-'}
                        help={errors?.settings?.appointment?.defaultStartTime?.message}
                    >
                        <Controller
                            name='settings.appointment.defaultStartTime'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <TimePicker
                                    className='w-full'
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        className='mb-0 col-span-6'
                        label='Default End Time'
                        validateStatus={(errors?.settings?.appointment?.defaultEndTime?.message) ? 'error' : '-'}
                        help={errors?.settings?.appointment?.defaultEndTime?.message}
                    >
                        <Controller
                            name='settings.appointment.defaultEndTime'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <TimePicker
                                    className='w-full'
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        className='mb-0 col-span-6'
                        label='Timezone'
                        validateStatus={(errors?.settings?.appointment?.timezone?.message) ? 'error' : '-'}
                        help={errors?.settings?.appointment?.timezone?.message}
                    >
                        <Controller
                            name='settings.appointment.timezone'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Select
                                    ref={ref}
                                    placeholder='Select timezone'
                                    options={[
                                        { label: 'Asia - Kuala Lumpur', value: 'Asia/Kuala_Lumpur' }
                                    ]}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>
                </div>
            </Card>

            <SubmitButton className='mt-1' loading={submitting}>
                Submit
            </SubmitButton>
        </Form>
    )
}

export default inject('services', 'logger')(AddCfpForm)
