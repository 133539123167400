import showApiErrorMessage from '@helpers/showApiErrorMessage'

const usePlanTypeOptionsLoader = (props) => {
    const { logger, planTypeService, by } = props

    const methodNames = {
        'CFP': 'listFromCfp',
        'ADMIN': 'listFromAdmin'
    }

    const loadOptions = async (inputValue, loadedOptions) => {
        try {
            const nextPage = (loadedOptions) ? (loadedOptions.length / 20) + 1 : 1

            const methodName = methodNames[by]
            const res = await planTypeService[methodName]({
                name: inputValue,
                page: nextPage
            })

            if (res.ok && res.data) {
                const data = res.data
                const planTypes = data.data
                const options = []
                
                planTypes.forEach((planType, index) => {
                    const option = {
                        label: `${planType.name}`,
                        value: `${planType.id}`,
                        planType
                    }
                    options.push(option)
                })

                const hasMore = (data.lastPage > data.page)

                return {
                    options,
                    hasMore
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                throw new Error(errors)
            }
        } catch (error) {
            logger.error('[Failed Load Plan Type Options]', error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    return { loadOptions }
}

export default (usePlanTypeOptionsLoader)