import React, { useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import Select, { components } from 'react-select'
import { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate'
import styles from './AsyncSelectPaginateInput.module.scss'

const AsyncSelectPaginateInput = (props, ref) => {
    return (
        <AsyncPaginate
            selectRef={ref}
            className={cn(props.className, styles.wrapper, {[styles.hasError]: props.hasError})}
            classNamePrefix='select'
            isLoading={props.isLoading}
            defaultValue={props.defaultValue}
            value={props.value}
            options={props.options}
            placeholder={props.placeholder}
            customStyles={props.customStyles}
            instanceId={props.id}
            components={{
                ...props.components
            }}
            loadOptions={props.loadOptions}
            onChange={props.onChange}
            onInputChange={props.onInputChange}
            isDisabled={props.isDisabled}
            isSearchable={props.isSearchable}
            debounceTimeout={props.debounceTimeout}
        />
    )
}

export default React.forwardRef(AsyncSelectPaginateInput)
