import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import moment from 'moment'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable from '@ant-design/pro-table'
import Layout from '@components/admin/layout/AdminLayout'
import prepareApiParams from '@helpers/prepareApiParams'
import numeral from 'numeral'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import { Tag } from 'antd'
import { Link } from 'react-router-dom'
import { adminPlanTypeDetailPath, adminPlanTypeListingPath } from '@constants/routes'

const AdminPlanTypeListingPage = ({ config, logger, services: { planTypeService } }) => {
    const tableActionRef = useRef(null)

    const columns = useMemo(() => {
        return [
            {
                title: 'Slug',
                dataIndex: 'slug',
                width: 300,
                search: false,
                render: (text, record) => {
                    return text
                }
            },
            {
                title: 'Name',
                dataIndex: 'name',
                width: 300,
                search: true,
                render: (text, record) => {
                    return text
                }
            },
            {
                title: 'Price',
                dataIndex: 'price',
                width: 200,
                search: false,
                render: (text, record) => {
                    if (record.price !== null && record.price !== undefined) { 
                        return `${record.currency} ${numeral(record.price).format('0,0.00')}`
                    } else {
                        return '-'
                    }
                }
            },
            {
                title: 'Has Variant',
                width: 200,
                search: false,
                render: (text, record) => {
                    if (!record.hasVariant) {
                        return (
                            <Tag color='error'>
                                No
                            </Tag>
                        )
                    } else {
                        return (
                            <Tag color='success'>
                                Yes
                            </Tag>
                        )
                    }
                }
            },
            {
                title: 'Purchaseable',
                dataIndex: 'purchaseable',
                width: 250,
                search: false,
                render: (text, record) => {
                    if (record.purchaseable) {
                        return (
                            <Tag color='success'>
                                True
                            </Tag>
                        )
                    } else {
                        return (
                            <Tag color='error'>
                                False
                            </Tag>
                        )
                    }
                }
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                search: false,
                sorter: true,
                width: 250,
                valueType: 'datetime',
                renderText: (text) => {
                    return moment.utc(text).local().format('D MMM YYYY hh:mm A')
                }
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedAt',
                search: false,
                sorter: true,
                width: 250,
                valueType: 'datetime',
                renderText: (text) => {
                    return moment.utc(text).local().format('D MMM YYYY hh:mm A')
                }
            },
            {
                title: 'Actions',
                valueType: 'option',
                key: 'option',
                width: 80,
                search: false,
                fixed: 'right',
                render: (text, record, _, action) => {
                    const actionComponents = [
                        <Link
                            key='Update'
                            to={adminPlanTypeDetailPath(record.slug)}
                        >
                            Update
                        </Link>
                    ]
                    return actionComponents
                }
            }
        ]
    }, [])
    
    useEffect(() => {
    }, [])

    const handleRequest = async (params, sort) => {
        try {
            const apiParams = {}
            prepareApiParams(params, sort, apiParams)
            const res = await planTypeService.listFromAdmin(apiParams)

            if (res.ok) {
                const data = res.data
                return {
                    data: data.data,
                    success: res.ok,
                    total: data.lastPage * data.perPage
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                return {
                    data: null,
                    success: res.ok,
                    total: 0
                }
            }
        } catch (e) {
            logger.error('[Failed List Plan Type]', e)
        }
    }

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: adminPlanTypeListingPath,
                            breadcrumbName: 'Plan Types'
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title='Plan Types'
            >
                <ProTable
                    actionRef={tableActionRef}
                    search={{
                        defaultCollapsed: false,
                        span: 12,
                        labelWidth: 'auto'
                    }}
                    request={handleRequest}
                    columns={columns}
                    rowKey='id'
                    scroll={{ x: '120%' }}
                    pagination={{
                        showSizeChanger: false
                    }}
                />
            </PageContainer>
        </Layout>
    )
}

export default inject('config', 'services', 'logger')(AdminPlanTypeListingPage)
