import JSEncrypt from 'jsencrypt'

const loginEndpointPath = '/admin/login'
const checkTokenEndpointPath = '/admin/check-token'
const refreshTokenEndpointPath = '/admin/refresh-token'
const dashboardDataEndpointPath = '/admin/dashboard'

class AdminService {
    constructor (adminWebClient, credentialEncrypter) {
        this.adminWebClient = adminWebClient
        this.credentialEncrypter = credentialEncrypter
    }

    async login (username, password, isEncrypted = false) {
        if (isEncrypted) {
            const encrypytedPassword = this.credentialEncrypter.encrypt(password)
            password = encrypytedPassword
        }

        const res = await this.adminWebClient.post(loginEndpointPath, {
            email: username,
            password,
            isEncrypted
        })
        return res
    }

    async checkToken () {
        const res = await this.adminWebClient.get(checkTokenEndpointPath)
        return res
    }

    async refreshToken (refreshToken) {
        const res = await this.adminWebClient.post(refreshTokenEndpointPath, { refreshToken })
        return res
    }

    async getDashboard (params) {
        const res = await this.adminWebClient.get(dashboardDataEndpointPath, params)
        return res
    }
}

export default AdminService
