import { useEffect, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import cn from 'classnames'
import Modal from 'antd/lib/modal/Modal'
import AdminUpdateAppointmentForm from '../form/AdminUpdateAppointmentForm'

const AdminUpdateAppointmentModal = (props) => {
    return (
        <Modal
            className={cn(props.className)}
            title='Update Appointment'
            open={props.visible}
            onCancel={props.onCancel}
            footer={null}
            destroyOnClose
        >
            <AdminUpdateAppointmentForm
                {...props.form}
            />
        </Modal>
    )
}

export default inject('services')(AdminUpdateAppointmentModal)
