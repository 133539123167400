import { useState, useEffect } from 'react'
import { inject } from 'mobx-react'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import AdminPrivateRoute from './AdminPrivateRoute'
import AdminPublicRoute from './AdminPublicRoute'
import AdminLoginPage from '@pages/admin/login'
import AdminDashboardPage from '@pages/admin/dashboard'
import AdminCfpListingPage from '@pages/admin/certified-financial-planners'
import AdminAppointmentListingPage from '@pages/admin/appointments'
import AdminPurchaseListingPage from '@pages/admin/purchases'
import AdminEventListingPage from '@pages/admin/events'
import AdminRole from '@models/enum/AdminRole'
import AdminCustomerRoutes from './AdminCustomerRoutes'
import AdminPromoCodeListingPage from '@pages/admin/promo-code'
import AdminPlanTypeRoutes from './AdminPlanTypeRoutes'
import AdminCreatePlanTypePage from '@pages/admin/plan-types/create'
import AdminPlanRoutes from './AdminPlanRoutes'

const AdminRoutes = (props) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    return (
        <Routes>
            <Route
                path='/'
                element={
                    <Navigate to='login' replace />
                }
            />

            <Route
                exact
                path='login'
                element={
                    <AdminPublicRoute restricted>
                        <AdminLoginPage />
                    </AdminPublicRoute>
                }
            />

            <Route
                path='dashboard'
                element={
                    <AdminPrivateRoute>
                        <AdminDashboardPage />
                    </AdminPrivateRoute>
                }
            />

            <Route
                path='plan-types/*'
                element={
                    <AdminPlanTypeRoutes />
                }
            />

            <Route
                path='plan-type/create'
                element={
                    <AdminPrivateRoute roles={[AdminRole.SUPER_ADMIN, AdminRole.ADMIN]}>
                        <AdminCreatePlanTypePage />
                    </AdminPrivateRoute>
                }
            />

            <Route
                path='customers/*'
                element={
                    <AdminCustomerRoutes />
                }
            />

            <Route
                path='plans/*'
                element={
                    <AdminPlanRoutes />
                }
            />

            <Route
                path='certified-financial-planners'
                element={
                    <AdminPrivateRoute roles={[AdminRole.SUPER_ADMIN, AdminRole.ADMIN]}>
                        <AdminCfpListingPage />
                    </AdminPrivateRoute>
                }
            />

            <Route
                path='appointments'
                element={
                    <AdminPrivateRoute roles={[AdminRole.SUPER_ADMIN, AdminRole.ADMIN]}>
                        <AdminAppointmentListingPage />
                    </AdminPrivateRoute>
                }
            />

            <Route
                path='purchases'
                element={
                    <AdminPrivateRoute roles={[AdminRole.SUPER_ADMIN, AdminRole.ADMIN]}>
                        <AdminPurchaseListingPage />
                    </AdminPrivateRoute>
                }
            />

            <Route
                path='events'
                element={
                    <AdminPrivateRoute roles={[AdminRole.SUPER_ADMIN, AdminRole.ADMIN, AdminRole.ADMIN_MARKETING]}>
                        <AdminEventListingPage />
                    </AdminPrivateRoute>
                }
            />

            <Route
                path='promo-codes'
                element={
                    <AdminPrivateRoute roles={[AdminRole.SUPER_ADMIN, AdminRole.ADMIN, AdminRole.ADMIN_MARKETING]}>
                        <AdminPromoCodeListingPage />
                    </AdminPrivateRoute>
                }
            />
        </Routes>

            
    )
}

export default (AdminRoutes)
