import React, { useMemo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import CfpLoginForm from '../CfpLoginForm'
import styles from './CfpLoginFormWrapper.module.scss'

const CfpLoginFormWrapper = props => {
    const currentDate = new Date()
    const config = props.config

    return (
        <div className={cn(styles.wrapper, props.className)} style={props.style}>
            <h1>Welcome to {config.app.appName}</h1>
            <p>It&apos;s good to see you. Log in to start using CFP panel.</p>

            <CfpLoginForm
                className='my-5 w-full'
                successMessage={props.successMessage}
                onSuccessSubmit={props.onSuccessSubmit}
            />

            <p className='mb-4'>
                <Link to='/admin/login'>
                    Login as admin
                </Link>
            </p>

            <p className='text-gray-400'>
                © {`${currentDate.getFullYear()}`} {config.app.appName}. All Rights Reserved
            </p>
        </div>
    )
}

CfpLoginFormWrapper.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onSuccessSubmit: PropTypes.func
}

CfpLoginFormWrapper.defaultProps = {
    onSuccessSubmit: () => {}
}

export default inject('config')(CfpLoginFormWrapper)
