export const listPromoCodeEndpointFromAdminPath = '/admin/promo-code/list'
export const addPromoCodeFromAdminEndpointPath = '/admin/promo-code/create'
export const updatePromoCodeFromAdminEndpointPath = '/admin/promo-code/update'
export const updatePromoCodeStatusFromAdminEndpointPath = '/admin/promo-code/update-status'

class PromoCodeService {
    constructor (adminWebClient) {
        this.adminWebClient = adminWebClient
    }

    async listFromAdmin (params) {
        const res = await this.adminWebClient.get(listPromoCodeEndpointFromAdminPath, params)
        return res
    }

    async addFromAdmin (params) {
        const res = await this.adminWebClient.post(addPromoCodeFromAdminEndpointPath, params)
        return res
    }

    async updateFromAdmin (params) {
        const res = await this.adminWebClient.post(updatePromoCodeFromAdminEndpointPath, params)
        return res
    }

    async updateStatusFromAdmin (params) {
        const res = await this.adminWebClient.post(updatePromoCodeStatusFromAdminEndpointPath, params)
        return res
    }
}

export default PromoCodeService