import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { inject } from 'mobx-react'
import moment from 'moment'
import { DownOutlined, SmileOutlined } from '@ant-design/icons'
import { Form, Select, Input, TimePicker, InputNumber, Modal, Checkbox, Table, Button, Popconfirm, Menu, Card, Divider, Popover, Dropdown, Space } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import SubmitButton from '@components/common/button/submit/SubmitButton'
import TextArea from 'antd/lib/input/TextArea'
import ReactQuill from 'react-quill'
import DiscountType from '@models/enum/DiscountType'
import AdminAddAttachmentSessionsModal from '../sessions/modal/AdminAddAttachmentSessionsModal'
import AdminViewAttachmentSessionsModal from '../sessions/modal/AdminViewAttachmentSessionsModal'

const AdminUpdatePlanTypeForm = (props) => {
    const logger = props.logger
    const planTypeService = props.services.planTypeService
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const [addAttachmentSessionsProps, setAddAttachmentSessionsProps] = useState(null)
    const [viewAttachmentSessionsProps, setViewAttachmentSessionsProps] = useState(null)
    const { handleSubmit, setError, control, reset, watch, setValue, trigger, formState: { errors } } = useForm({
        defaultValues: props.defaultValues,
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    const planTypesId = props.defaultValues.id
    const toolbarOptions = [
        [{ 'header': 1 }, { 'header': 2 }],
        ['bold'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }]
    ]

    const onSubmit = async (data) => {
        try {
            logger.debug('[Update Plan Type Form Data]', data)
            setSubmitting(true)
            const res = await planTypeService.updateFromAdmin(data)

            if (res.ok && res.data) {
                props.onSuccessSubmit(res.data)
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (error) {
            logger.error('[Fail Submit Update Plan Type Form', error)
        } finally {
            setSubmitting(false)
        }
    }

    const handleAddNewSessionRow = () => {
        const newData = {
            duration: 0,
            durationUnit: 'MINUTE'
        }
        const currentData = watch('sessions')
        setValue('sessions', [...currentData ?? [], newData])
        trigger('sessions')
    }

    const handleRemoveSessionRow = (index) => {
        const sessions = watch('sessions')
        sessions.splice(index, 1)
        setValue('sessions', sessions)
    }

    const handleAddNewVariantRow = () => {
        const newData = {
            type: null,
            currency: 'MYR',
            price: 0,
            purchaseable: true,
            isPublic: true
        }
        const currentData = watch('variants')
        setValue('variants', [...currentData ?? [], newData])
    }

    const handleRemoveVariantRow = (index) => {
        const variants = watch('variants')
        variants.splice(index, 1)
        setValue('variants', variants)
    }

    const handleAddNewFeatureRow = () => {
        const newData = {
            key: Math.random().toString(),
            name: null,
            description: null
        }
        const currentData = watch('features')
        setValue('features', [...currentData ?? [], newData])
    }

    const handleRemoveFeatureRow = (index) => {
        const features = watch('features')
        features.splice(index, 1)
        setValue('features', features)
    }

    const handleAddFileInSessions = (index) => {
        setAddAttachmentSessionsProps({
            defaultValues: {
                id: planTypesId,
                sessionIndex: index
            }
        })
    }

    const handleViewFileInSessions = (index, record) => {
        setViewAttachmentSessionsProps({
            sessionIndex: index,
            id: planTypesId,
            ...record
        })
    }

    const sessionsActionDropDownItem = (index, record) => {
        const items = [
            {
                key: '1',
                label: (
                    <Popconfirm title='Sure to remove?' onConfirm={() => handleRemoveSessionRow(index)}>
                        <a>Delete</a>
                    </Popconfirm>
                )
            },
            {
                key: '2',
                label: (
                    <a onClick={() => { handleAddFileInSessions(index) }} className=''>Add file</a>
                )
            },
            {
                key: '3',
                label:
                    <a onClick={() => { handleViewFileInSessions(index, record) }} className=''>View file</a>
            }
        ]
        return items
    }

    const updateSessionsFile = (updatedAttachments, sessionIndex) => {
        const updatedSessions = watch('sessions')
        updatedSessions[sessionIndex].attachments = updatedAttachments

        setValue('sessions', updatedSessions)
    }


    return (
        <>
            <Form
                className={cn(props.className)}
                style={props.style}
                layout='vertical'
                onFinish={handleSubmit(onSubmit)}
                autoComplete='off'
                form={form}
            >
                <div className='grid grid-cols-12'>
                    <div className='col-span-12 grid grid-cols-12 gap-4 w-full'>
                        <Card className='col-span-12'>
                            <div className='grid grid-cols-12 gap-4'>
                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Name'
                                    validateStatus={(errors?.name?.message) ? 'error' : '-'}
                                    help={errors?.name?.message}
                                >
                                    <Controller
                                        name='name'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Input
                                                ref={ref}
                                                placeholder='e.g. Basic'
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Price'
                                    validateStatus={(errors?.price?.message) ? 'error' : '-'}
                                    help={errors?.price?.message}
                                >
                                    <Controller
                                        name='price'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <InputNumber
                                                ref={ref}
                                                className='w-full'
                                                placeholder='Price'
                                                formatter={value => `MYR ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                /* eslint-disable no-useless-escape */
                                                parser={value => value.replace(/\MYR\s?|(,*)/g, '')}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-12'
                                    label='Sub Title'
                                    validateStatus={(errors?.subTitle?.message) ? 'error' : '-'}
                                    help={errors?.subTitle?.message}
                                >
                                    <Controller
                                        name='subTitle'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <TextArea
                                                ref={ref}
                                                placeholder=''
                                                maxLength={255}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 h-64 col-span-12'
                                    label='Description'
                                    validateStatus={(errors?.description?.message) ? 'error' : '-'}
                                    help={errors?.description?.message}
                                >
                                    <Controller
                                        name='description'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <ReactQuill
                                                className='h-44'
                                                theme='snow'
                                                modules={{
                                                    toolbar: toolbarOptions
                                                }}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 h-64 col-span-12'
                                    label='Mobile Description'
                                    validateStatus={(errors?.mobileDescription?.message) ? 'error' : '-'}
                                    help={errors?.mobileDescription?.message}
                                >
                                    <Controller
                                        name='mobileDescription'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <ReactQuill
                                                className='h-44'
                                                theme='snow'
                                                modules={{
                                                    toolbar: toolbarOptions
                                                }}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </Form.Item>
                            </div>
                        </Card>

                        <Card className='col-span-12'>
                            <Form.Item
                                className='mb-0 col-span-12'
                                label='Sessions'
                                validateStatus={(errors?.sessions?.message) ? 'error' : '-'}
                                help={errors?.sessions?.message}
                            >
                                <Table
                                    rowKey={() => {
                                        return Math.random().toString()
                                    }}
                                    columns={[
                                        {
                                            title: 'Session',
                                            key: 'sessionNumber',
                                            dataIndex: 'sessionNumber',
                                            render: (text, record, index) => {
                                                return index + 1
                                            }
                                        },
                                        {
                                            title: 'Title',
                                            dataIndex: 'title',
                                            render: (text, record, index) => {
                                                return (
                                                    <Form.Item
                                                        className='mb-0'
                                                        validateStatus={(errors?.sessions?.[index]?.title?.message) ? 'error' : '-'}
                                                        help={errors?.sessions?.[index]?.title?.message}
                                                    >
                                                        <Controller
                                                            name={`sessions[${index}].title`}
                                                            control={control}
                                                            render={({ field: { value, onChange, ref } }) => (
                                                                <Input
                                                                    ref={ref}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        },
                                        {
                                            title: 'Duration',
                                            dataIndex: 'duration',
                                            render: (text, record, index) => {
                                                return (
                                                    <Form.Item
                                                        className='mb-0'
                                                        validateStatus={(errors?.sessions?.[index]?.duration?.message) ? 'error' : '-'}
                                                        help={errors?.sessions?.[index]?.duration?.message}
                                                    >
                                                        <Controller
                                                            name={`sessions[${index}].duration`}
                                                            control={control}
                                                            render={({ field: { value, onChange, ref } }) => (
                                                                <InputNumber
                                                                    ref={ref}
                                                                    className='w-full'
                                                                    placeholder='e.g. 60'
                                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\RM\s?|(,*)/g, '')}
                                                                    onChange={onChange}
                                                                    value={record.duration}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        },
                                        {
                                            title: 'Unit',
                                            dataIndex: 'durationUnit',
                                            render: (text, record, index) => {
                                                return (
                                                    <Form.Item
                                                        className='mb-0'
                                                        validateStatus={(errors?.sessions?.[index]?.durationUnit?.message) ? 'error' : '-'}
                                                        help={errors?.sessions?.[index]?.durationUnit?.message}
                                                    >
                                                        <Controller
                                                            name={`sessions[${index}].durationUnit`}
                                                            control={control}
                                                            render={({ field: { value, onChange, ref } }) => (
                                                                <Select
                                                                    placeholder='Select'
                                                                    options={[
                                                                        { label: 'Minutes', value: 'MINUTE' },
                                                                        { label: 'Hours', value: 'HOUR' }
                                                                    ]}
                                                                    onChange={onChange}
                                                                    value={record.durationUnit}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        },
                                        {
                                            title: 'Action',
                                            render: (text, record, index) => {
                                                const items = sessionsActionDropDownItem(index, record)
                                                return (
                                                    <>
                                                        <Dropdown
                                                            overlay={
                                                                <Menu>
                                                                    {items.map(item => (
                                                                        <Menu.Item key={item.key}>{item.label}</Menu.Item>
                                                                    ))}
                                                                </Menu>
                                                            }
                                                        >
                                                            <a onClick={(e) => e.preventDefault()}>
                                                                <Space>
                                                                    Action
                                                                    <DownOutlined />
                                                                </Space>
                                                            </a>
                                                        </Dropdown>
                                                    </>
                                                )
                                            }
                                        }
                                    ]}
                                    dataSource={watch('sessions')}
                                    pagination={false}
                                />
                                <Button className='mt-4' onClick={handleAddNewSessionRow}>
                                    Add new session
                                </Button>
                            </Form.Item>
                        </Card>

                        <Card className='col-span-12'>
                            <Form.Item
                                className='mb-0 col-span-12'
                                label='Variants'
                                validateStatus={(errors?.variants?.message) ? 'error' : '-'}
                                help={errors?.variants?.message}
                            >
                                <Table
                                    rowKey={() => {
                                        return Math.random().toString()
                                    }}
                                    columns={[
                                        {
                                            title: 'Type',
                                            dataIndex: 'type',
                                            render: (text, record, index) => {
                                                return (
                                                    <Form.Item
                                                        className='mb-0'
                                                        validateStatus={(errors?.variants?.[index]?.type?.message) ? 'error' : '-'}
                                                        help={errors?.variants?.[index]?.type?.message}
                                                    >
                                                        <Controller
                                                            name={`variants[${index}].type`}
                                                            control={control}
                                                            render={({ field: { value, onChange, ref } }) => (
                                                                <Select
                                                                    placeholder='Select'
                                                                    options={[
                                                                        { label: 'Solo', value: 'SOLO' },
                                                                        { label: 'Couple', value: 'COUPLE' }
                                                                    ]}
                                                                    onChange={onChange}
                                                                    value={record.type}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        },
                                        {
                                            title: 'Price',
                                            dataIndex: 'price',
                                            render: (text, record, index) => {
                                                return (
                                                    <Form.Item
                                                        className='mb-0'
                                                        validateStatus={(errors?.variants?.[index]?.price?.message) ? 'error' : '-'}
                                                        help={errors?.variants?.[index]?.price?.message}
                                                    >
                                                        <Controller
                                                            name={`variants[${index}].price`}
                                                            control={control}
                                                            render={({ field: { value, onChange, ref } }) => (
                                                                <InputNumber
                                                                    ref={ref}
                                                                    className='w-full'
                                                                    placeholder='Price'
                                                                    formatter={value => `MYR ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    /* eslint-disable no-useless-escape */
                                                                    parser={value => value.replace(/\MYR\s?|(,*)/g, '')}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        },
                                        {
                                            title: 'Purchaseable',
                                            dataIndex: 'purchaseable',
                                            render: (text, record, index) => {
                                                return (
                                                    <Form.Item
                                                        className='mb-0'
                                                        validateStatus={(errors?.variants?.[index]?.purchaseable?.message) ? 'error' : '-'}
                                                        help={errors?.variants?.[index]?.purchaseable?.message}
                                                    >
                                                        <Controller
                                                            name={`variants[${index}].purchaseable`}
                                                            control={control}
                                                            render={({ field: { value, onChange, ref } }) => (
                                                                <Checkbox
                                                                    onChange={onChange}
                                                                    checked={value}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        },
                                        {
                                            title: 'Public',
                                            dataIndex: 'isPublic',
                                            render: (text, record, index) => {
                                                return (
                                                    <Form.Item
                                                        className='mb-0'
                                                        validateStatus={(errors?.variants?.[index]?.isPublic?.message) ? 'error' : '-'}
                                                        help={errors?.variants?.[index]?.isPublic?.message}
                                                    >
                                                        <Controller
                                                            name={`variants[${index}].isPublic`}
                                                            control={control}
                                                            render={({ field: { value, onChange, ref } }) => (
                                                                <Checkbox
                                                                    onChange={onChange}
                                                                    checked={value}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        },
                                        {
                                            title: 'Action',
                                            render: (text, record, index) => {
                                                if (!record.id) {
                                                    return (
                                                        <Popconfirm title='Sure to remove?' onConfirm={() => handleRemoveVariantRow(index)}>
                                                            <a>Delete</a>
                                                        </Popconfirm>
                                                    )
                                                } else {
                                                    return (<div>-</div>)
                                                }
                                            }
                                        }
                                    ]}
                                    dataSource={watch('variants')}
                                    pagination={false}
                                />
                                <Button className='mt-4' onClick={handleAddNewVariantRow}>
                                    Add new variant
                                </Button>
                            </Form.Item>
                        </Card>

                        <Card className='col-span-12'>
                            <Form.Item
                                className='mb-0 col-span-12'
                                label='Features'
                                validateStatus={(errors?.features?.message) ? 'error' : '-'}
                                help={errors?.features?.message}
                            >
                                <Table
                                    rowKey={(record, index) => {
                                        return index
                                    }}
                                    columns={[
                                        {
                                            title: 'Name',
                                            dataIndex: 'name',
                                            render: (text, record, index) => {
                                                return (
                                                    <Form.Item
                                                        className='mb-0'
                                                        validateStatus={(errors?.features?.[index]?.name?.message) ? 'error' : '-'}
                                                        help={errors?.features?.[index]?.name?.message}
                                                    >
                                                        <Controller
                                                            name={`features[${index}].name`}
                                                            control={control}
                                                            render={({ field: { value, onChange, ref } }) => (
                                                                <Input
                                                                    ref={ref}
                                                                    placeholder='e.g. Access To CFP'
                                                                    onChange={onChange}
                                                                    value={value}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        },
                                        {
                                            title: 'Mobile Name',
                                            dataIndex: 'mobileName',
                                            render: (text, record, index) => {
                                                return (
                                                    <Form.Item
                                                        className='mb-0'
                                                        validateStatus={(errors?.features?.[index]?.mobileName?.message) ? 'error' : '-'}
                                                        help={errors?.features?.[index]?.mobileName?.message}
                                                    >
                                                        <Controller
                                                            name={`features[${index}].mobileName`}
                                                            control={control}
                                                            render={({ field: { value, onChange, ref } }) => (
                                                                <Input
                                                                    ref={ref}
                                                                    placeholder='e.g. Access To CFP'
                                                                    onChange={onChange}
                                                                    value={value}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        },
                                        {
                                            title: 'Action',
                                            render: (text, record, index) => {
                                                return (
                                                    <Popconfirm title='Sure to remove?' onConfirm={() => handleRemoveFeatureRow(index)}>
                                                        <a>Delete</a>
                                                    </Popconfirm>
                                                )
                                            }
                                        }
                                    ]}
                                    expandable={{
                                        expandedRowClassName: (record, index) => {
                                            return 'bg-white'
                                        },
                                        expandedRowRender: (record, index) => {
                                            return (
                                                <>
                                                    <Form.Item
                                                        className='mb-0 h-64'
                                                        validateStatus={(errors?.features?.[index]?.description?.message) ? 'error' : '-'}
                                                        help={errors?.features?.[index]?.description?.message}
                                                        label='Description'
                                                    >
                                                        <Controller
                                                            name={`features[${index}].description`}
                                                            control={control}
                                                            render={({ field: { value, onChange, ref } }) => (
                                                                <ReactQuill
                                                                    className='h-44'
                                                                    theme='snow'
                                                                    modules={{
                                                                        toolbar: toolbarOptions
                                                                    }}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        className='mb-0 h-64'
                                                        validateStatus={(errors?.features?.[index]?.mobileDescription?.message) ? 'error' : '-'}
                                                        help={errors?.features?.[index]?.mobileDescription?.message}
                                                        label='Mobile Description'
                                                    >
                                                        <Controller
                                                            name={`features[${index}].mobileDescription`}
                                                            control={control}
                                                            render={({ field: { value, onChange, ref } }) => (
                                                                <ReactQuill
                                                                    className='h-44'
                                                                    theme='snow'
                                                                    modules={{
                                                                        toolbar: toolbarOptions
                                                                    }}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </>
                                            )
                                        }
                                    }}
                                    dataSource={watch('features')}
                                    pagination={false}
                                />
                                <Button className='mt-4' onClick={handleAddNewFeatureRow}>
                                    Add new feature
                                </Button>
                            </Form.Item>
                        </Card>

                        <Card className='col-span-12'>
                            <div className='grid grid-cols-12 gap-4'>
                                <div className='col-span-12'>
                                    <Divider className='!my-0' orientation='center' orientationMargin='0'><span className='text-gray-300'>Expiration</span></Divider>
                                </div>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Expire Duration'
                                    validateStatus={(errors?.expireDuration?.message) ? 'error' : '-'}
                                    help={errors?.expireDuration?.message}
                                >
                                    <Controller
                                        name='expireDuration'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <InputNumber
                                                ref={ref}
                                                className='w-full'
                                                placeholder='e.g. 12'
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\RM\s?|(,*)/g, '')}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Expire Duration Unit'
                                    validateStatus={(errors?.expireDurationUnit?.message) ? 'error' : '-'}
                                    help={errors?.expireDurationUnit?.message}
                                >
                                    <Controller
                                        name='expireDurationUnit'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Select
                                                allowClear
                                                placeholder='Select'
                                                options={[
                                                    { label: 'Months', value: 'MONTH' },
                                                    { label: 'Years', value: 'YEAR' }
                                                ]}
                                                onChange={(value) => {
                                                    if (!value) {
                                                        setValue('expireDuration', null)
                                                        setValue('expireDurationUnit', null)
                                                    } else {
                                                        onChange(value)
                                                    }
                                                }}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <div className='col-span-12'>
                                    <Divider className='!my-0' orientation='center' orientationMargin='0'><span className='text-gray-300'>Other Settings</span></Divider>
                                </div>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Next Purchase Discount Value'
                                    validateStatus={(errors?.nextPurchaseDiscountValue?.message) ? 'error' : '-'}
                                    help={errors?.nextPurchaseDiscountValue?.message}
                                >
                                    <Controller
                                        name='nextPurchaseDiscountValue'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Input
                                                ref={ref}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Next Purchase Discount Type'
                                    validateStatus={(errors?.nextPurchaseDiscountType?.message) ? 'error' : '-'}
                                    help={errors?.nextPurchaseDiscountType?.message}
                                >
                                    <Controller
                                        name='nextPurchaseDiscountType'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => {
                                            return (
                                                <Select
                                                    allowClear
                                                    ref={ref}
                                                    options={[
                                                        { label: DiscountType.PERCENT.label, value: DiscountType.PERCENT.enumKey },
                                                        { label: DiscountType.PRICE.label, value: DiscountType.PRICE.enumKey }
                                                    ]}
                                                    onChange={(value) => {
                                                        if (!value) {
                                                            setValue('nextPurchaseDiscountValue', null)
                                                            setValue('nextPurchaseDiscountType', null)
                                                        } else {
                                                            onChange(value)
                                                        }
                                                    }}
                                                    value={value}
                                                />
                                            )
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Purchase Limit'
                                    validateStatus={(errors?.purchaseLimit?.message) ? 'error' : '-'}
                                    help={errors?.purchaseLimit?.message}
                                >
                                    <Controller
                                        name='purchaseLimit'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <InputNumber
                                                ref={ref}
                                                className='w-full'
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Color Code'
                                    validateStatus={(errors?.colorCode?.message) ? 'error' : '-'}
                                    help={errors?.colorCode?.message}
                                >
                                    <Controller
                                        name='colorCode'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Input
                                                ref={ref}
                                                className='w-full'
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Sort Weight'
                                    validateStatus={(errors?.sortWeight?.message) ? 'error' : '-'}
                                    help={errors?.sortWeight?.message}
                                >
                                    <Controller
                                        name='sortWeight'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <InputNumber
                                                ref={ref}
                                                className='w-full'
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-12'
                                    validateStatus={(errors?.purchaseable?.message) ? 'error' : '-'}
                                    help={errors?.purchaseable?.message}
                                >
                                    <Controller
                                        name='purchaseable'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            >
                                                Purchaseable
                                            </Checkbox>
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-12'
                                    validateStatus={(errors?.isPublic?.message) ? 'error' : '-'}
                                    help={errors?.isPublic?.message}
                                >
                                    <Controller
                                        name='isPublic'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            >
                                                Is Public
                                            </Checkbox>
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-12'
                                    validateStatus={(errors?.isRecommended?.message) ? 'error' : '-'}
                                    help={errors?.isRecommended?.message}
                                >
                                    <Controller
                                        name='isRecommended'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            >
                                                Is Recommended
                                            </Checkbox>
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-12'
                                    validateStatus={(errors?.isInstallmentAvailable?.message) ? 'error' : '-'}
                                    help={errors?.isInstallmentAvailable?.message}
                                >
                                    <Controller
                                        name='isInstallmentAvailable'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            >
                                                Is Installment Available
                                            </Checkbox>
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-12'
                                    validateStatus={(errors?.ableToContactCfp?.message) ? 'error' : '-'}
                                    help={errors?.ableToContactCfp?.message}
                                >
                                    <Controller
                                        name='ableToContactCfp'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            >
                                                Able to Contact Cfp
                                            </Checkbox>
                                        )}
                                    />
                                </Form.Item>
                            </div>
                        </Card>
                    </div>

                    {/* <div className='col-span-12 grid grid-cols-12 gap-4 w-full'>
                        <Card className='col-span-12'>
                            <div className='grid grid-cols-12 gap-4'>
                                <div className='col-span-12'>
                                    <Divider className='!my-0' orientation='center' orientationMargin='0'><span className='text-gray-300'>Expiration</span></Divider>
                                </div>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Expire Duration'
                                    validateStatus={(errors?.expireDuration?.message) ? 'error' : '-'}
                                    help={errors?.expireDuration?.message}
                                >
                                    <Controller
                                        name='expireDuration'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <InputNumber
                                                ref={ref}
                                                className='w-full'
                                                placeholder='e.g. 12'
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\RM\s?|(,*)/g, '')}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Expire Duration Unit'
                                    validateStatus={(errors?.expireDurationUnit?.message) ? 'error' : '-'}
                                    help={errors?.expireDurationUnit?.message}
                                >
                                    <Controller
                                        name='expireDurationUnit'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Select
                                                allowClear
                                                placeholder='Select'
                                                options={[
                                                    { label: 'Months', value: 'MONTH' },
                                                    { label: 'Years', value: 'YEAR' }
                                                ]}
                                                onChange={(value) => {
                                                    if (!value) {
                                                        setValue('expireDuration', null)
                                                        setValue('expireDurationUnit', null)
                                                    } else {
                                                        onChange(value)
                                                    }
                                                }}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <div className='col-span-12'>
                                    <Divider className='!my-0' orientation='center' orientationMargin='0'><span className='text-gray-300'>Other Settings</span></Divider>
                                </div>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Next Purchase Discount Value'
                                    validateStatus={(errors?.nextPurchaseDiscountValue?.message) ? 'error' : '-'}
                                    help={errors?.nextPurchaseDiscountValue?.message}
                                >
                                    <Controller
                                        name='nextPurchaseDiscountValue'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Input
                                                ref={ref}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Next Purchase Discount Type'
                                    validateStatus={(errors?.nextPurchaseDiscountType?.message) ? 'error' : '-'}
                                    help={errors?.nextPurchaseDiscountType?.message}
                                >
                                    <Controller
                                        name='nextPurchaseDiscountType'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => {
                                            return (
                                                <Select
                                                    allowClear
                                                    ref={ref}
                                                    options={[
                                                        { label: DiscountType.PERCENT.label, value: DiscountType.PERCENT.enumKey },
                                                        { label: DiscountType.PRICE.label, value: DiscountType.PRICE.enumKey }
                                                    ]}
                                                    onChange={(value) => {
                                                        if (!value) {
                                                            setValue('nextPurchaseDiscountValue', null)
                                                            setValue('nextPurchaseDiscountType', null)
                                                        } else {
                                                            onChange(value)
                                                        }
                                                    }}
                                                    value={value}
                                                />
                                            )
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Purchase Limit'
                                    validateStatus={(errors?.purchaseLimit?.message) ? 'error' : '-'}
                                    help={errors?.purchaseLimit?.message}
                                >
                                    <Controller
                                        name='purchaseLimit'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <InputNumber
                                                ref={ref}
                                                className='w-full'
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Color Code'
                                    validateStatus={(errors?.colorCode?.message) ? 'error' : '-'}
                                    help={errors?.colorCode?.message}
                                >
                                    <Controller
                                        name='colorCode'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Input
                                                ref={ref}
                                                className='w-full'
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-6'
                                    label='Sort Weight'
                                    validateStatus={(errors?.sortWeight?.message) ? 'error' : '-'}
                                    help={errors?.sortWeight?.message}
                                >
                                    <Controller
                                        name='sortWeight'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <InputNumber
                                                ref={ref}
                                                className='w-full'
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-12'
                                    validateStatus={(errors?.purchaseable?.message) ? 'error' : '-'}
                                    help={errors?.purchaseable?.message}
                                >
                                    <Controller
                                        name='purchaseable'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            >
                                                Purchaseable
                                            </Checkbox>
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-12'
                                    validateStatus={(errors?.isPublic?.message) ? 'error' : '-'}
                                    help={errors?.isPublic?.message}
                                >
                                    <Controller
                                        name='isPublic'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            >
                                                Is Public
                                            </Checkbox>
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-12'
                                    validateStatus={(errors?.isRecommended?.message) ? 'error' : '-'}
                                    help={errors?.isRecommended?.message}
                                >
                                    <Controller
                                        name='isRecommended'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            >
                                                Is Recommended
                                            </Checkbox>
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-12'
                                    validateStatus={(errors?.isInstallmentAvailable?.message) ? 'error' : '-'}
                                    help={errors?.isInstallmentAvailable?.message}
                                >
                                    <Controller
                                        name='isInstallmentAvailable'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            >
                                                Is Installment Available
                                            </Checkbox>
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className='mb-0 col-span-12'
                                    validateStatus={(errors?.ableToContactCfp?.message) ? 'error' : '-'}
                                    help={errors?.ableToContactCfp?.message}
                                >
                                    <Controller
                                        name='ableToContactCfp'
                                        control={control}
                                        render={({ field: { value, onChange, ref } }) => (
                                            <Checkbox
                                                onChange={onChange}
                                                checked={value}
                                            >
                                                Able to Contact Cfp
                                            </Checkbox>
                                        )}
                                    />
                                </Form.Item>
                            </div>
                        </Card>
                    </div> */}
                </div>

                <SubmitButton className='my-4' loading={submitting}>
                    Submit
                </SubmitButton>
            </Form>
            <AdminAddAttachmentSessionsModal
                form={addAttachmentSessionsProps}
                visible={!!addAttachmentSessionsProps}
                onCancel={() => setAddAttachmentSessionsProps(null)}
                updateSessionsFile={updateSessionsFile}
            />
            <AdminViewAttachmentSessionsModal
                record={viewAttachmentSessionsProps}
                visible={!!viewAttachmentSessionsProps}
                onCancel={() => setViewAttachmentSessionsProps(null)}
                updateSessionsFile={updateSessionsFile}
            />
        </>
    )
}

export default inject('services', 'logger')(AdminUpdatePlanTypeForm)
