import { Enumify } from 'enumify'

export default class PromoCodeType extends Enumify {
    value = null

    constructor (value = null, label = null) {
        super()
        this.value = value
        this.label = label
    }

    static of (type) {
        return PromoCodeType.enumValueOf(type)
    }

    static for (type) {
        switch (type) {
            case 0: return PromoCodeType.PRODUCT
            case 1: return PromoCodeType.SHIPPING
        }
        return null
    }
}

PromoCodeType.PRODUCT = new PromoCodeType(0, 'Product')
PromoCodeType.SHIPPING = new PromoCodeType(1, 'Shipping')
PromoCodeType._ = PromoCodeType.closeEnum()