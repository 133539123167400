import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { inject } from 'mobx-react'
import moment from 'moment'
import { Form, Select, Input, TimePicker, InputNumber, Modal, Divider, Card, Button, Checkbox } from 'antd'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import SubmitButton from '@components/common/button/submit/SubmitButton'
import TextArea from 'antd/lib/input/TextArea'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import GoalMapperLinkType from '@models/enum/GoalMapperLinkType'

const UpdateCfpForm = (props) => {
    const logger = props.logger
    const cfpService = props.services.cfpService
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const { handleSubmit, setError, control, reset, watch, setValue, trigger, formState: { errors } } = useForm({
        defaultValues: props.defaultValues,
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    const {
        fields: goalMapperLinksFields,
        append: appendGoalMapperLinks,
        remove: removeGoalMapperLinks
    } = useFieldArray({ control, name: 'goalMapperLinks' })

    const handleAddGoalMapperLink = () => {
        appendGoalMapperLinks({ link: '', type: '' })
    }

    const toolbarOptions = [
        [{ 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ]

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            const params = data

            if (params.settings.appointment.defaultStartTime) {
                params.settings.appointment.defaultStartTime = moment(params.settings.appointment.defaultStartTime).format('HH:mm')
            }

            if (params.settings.appointment.defaultEndTime) {
                params.settings.appointment.defaultEndTime = moment(params.settings.appointment.defaultEndTime).format('HH:mm')
            }

            params.settings = JSON.stringify({ appointment: params.settings.appointment })
            params.goalMapperLinks = JSON.stringify(data.goalMapperLinks)
            const res = await cfpService.updateFromAdmin(params)

            if (res.ok && res.data) {
                props.onSuccessSubmit(res.data)
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (error) {
            logger.error('[Fail Submit Update Cfp Form', error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            <div className='grid grid-cols-12 gap-x-3'>
                <Form.Item
                    className='col-span-12 mb-4'
                    label='Type'
                    validateStatus={(errors?.type?.message) ? 'error' : '-'}
                    help={errors?.type?.message}
                >
                    <Controller
                        name='type'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Select
                                placeholder='Select type'
                                options={[
                                    { label: 'Conventional', value: 'CONVENTIONAL' },
                                    { label: 'Islamic', value: 'ISLAMIC' }
                                ]}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='col-span-6 mb-4'
                    label='Name'
                    validateStatus={(errors?.name?.message) ? 'error' : '-'}
                    help={errors?.name?.message}
                >
                    <Controller
                        name='name'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Input
                                ref={ref}
                                placeholder='e.g. John Smith'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='col-span-6 mb-4'
                    label='Email'
                    validateStatus={(errors?.email?.message) ? 'error' : '-'}
                    help={errors?.email?.message}
                >
                    <Controller
                        name='email'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Input
                                ref={ref}
                                placeholder='e.g. john.smith@email.com'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='mb-4 col-span-6'
                    label='Mobile Prefix'
                    validateStatus={(errors?.mobilePrefix?.message) ? 'error' : '-'}
                    help={errors?.mobilePrefix?.message}
                >
                    <Controller
                        name='mobilePrefix'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Input
                                ref={ref}
                                placeholder='e.g. 60'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='mb-4 col-span-6'
                    label='Mobile Number'
                    validateStatus={(errors?.mobileNumber?.message) ? 'error' : '-'}
                    help={errors?.mobileNumber?.message}
                >
                    <Controller
                        name='mobileNumber'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Input
                                ref={ref}
                                placeholder='e.g. 123456789'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='col-span-12 mb-4'
                    label='License Number'
                    validateStatus={(errors?.licenseNumber?.message) ? 'error' : '-'}
                    help={errors?.licenseNumber?.message}
                >
                    <Controller
                        name='licenseNumber'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <Input
                                ref={ref}
                                placeholder='e.g. 123456789'
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='col-span-12 mb-4'
                    label='Intoduction Summary'
                    validateStatus={errors?.introSummary?.message ? 'error' : '-'}
                    help={errors?.introSummary?.message}
                >
                    <Controller
                        name='introSummary'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <TextArea
                                ref={ref}
                                placeholder='Summary About CFP'
                                onChange={onChange}
                                value={value}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='mb-4 h-52 col-span-12'
                    label='Description'
                    validateStatus={errors?.description?.message ? 'error' : '-'}
                    help={errors?.description?.message}
                >
                    <Controller
                        name='description'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <ReactQuill
                                className='h-32'
                                theme='snow'
                                modules={{
                                    toolbar: toolbarOptions
                                }}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </Form.Item>

                <div className='mb-4 col-span-12'>Goal Mapper Links</div>
                {goalMapperLinksFields.map((item, index) => {
                    return (
                        <div key={item.id} className='flex items-end gap-3 mb-3 col-span-12'>
                            <Form.Item
                                key={`goalMapperLink-${index}-link`}
                                label='Link'
                                validateStatus={errors?.goalMapperLinks && errors.goalMapperLinks[index]?.link ? 'error' : ''}
                                help={errors?.goalMapperLinks && errors.goalMapperLinks[index]?.link ? errors.goalMapperLinks[index].link.message : ''}
                                className='w-full mb-0 col-span-12'
                            >
                                <Controller
                                    name={`goalMapperLinks[${index}].link`}
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => {
                                        return (
                                            <Input
                                                placeholder='Link'
                                                value={value}
                                                onChange={onChange}
                                                ref={ref}
                                            />
                                        )
                                    }}
                                >

                                </Controller>
                            </Form.Item>
                            <Form.Item
                                key={`goalMapperLink-${index}-type`}
                                label='Type'
                                validateStatus={errors?.goalMapperLinks && errors.goalMapperLinks[index]?.type ? 'error' : ''}
                                help={errors?.goalMapperLinks && errors.goalMapperLinks[index]?.type ? errors.goalMapperLinks[index].type.message : ''}
                                className='w-full mb-0'
                            >
                                <Controller
                                    name={`goalMapperLinks[${index}].type`}
                                    control={control}
                                    render={({ field: { value, onChange, ref } }) => (
                                        <Select
                                            ref={ref}
                                            options={[
                                                { label: GoalMapperLinkType.INDIVIDUAL.label, value: GoalMapperLinkType.INDIVIDUAL.enumKey },
                                                { label: GoalMapperLinkType.COUPLE.label, value: GoalMapperLinkType.COUPLE.enumKey }
                                            ]}
                                            placeholder='Plan Type'
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </Form.Item>
                            <Button
                                danger
                                className=''
                                type='primary'
                                onClick={() => {
                                    removeGoalMapperLinks(index)
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    )
                })}

                <Button className='mt-3 col-span-3' type='primary' onClick={handleAddGoalMapperLink}>
                    Add More
                </Button>
            </div>

            <Divider orientation='center' orientationMargin='0'><span className='text-gray-300'>Settings</span></Divider>

            <Card title='Appointment' className='mb-4' size='small'>
                <div className='mb-4 grid grid-cols-12 gap-4'>
                    <Form.Item
                        className='mb-0 col-span-6'
                        label='Default Start Time'
                        validateStatus={(errors?.settings?.appointment?.defaultStartTime?.message) ? 'error' : '-'}
                        help={errors?.settings?.appointment?.defaultStartTime?.message}
                    >
                        <Controller
                            name='settings.appointment.defaultStartTime'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <TimePicker
                                    className='w-full'
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        className='mb-0 col-span-6'
                        label='Default End Time'
                        validateStatus={(errors?.settings?.appointment?.defaultEndTime?.message) ? 'error' : '-'}
                        help={errors?.settings?.appointment?.defaultEndTime?.message}
                    >
                        <Controller
                            name='settings.appointment.defaultEndTime'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <TimePicker
                                    className='w-full'
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        className='mb-0 col-span-6'
                        label='Gap Duration'
                        validateStatus={(errors?.settings?.appointment?.gap?.duration?.message) ? 'error' : '-'}
                        help={errors?.settings?.appointment?.gap?.duration?.message}
                    >
                        <Controller
                            name='settings.appointment.gap.duration'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <InputNumber
                                    ref={ref}
                                    className='w-full'
                                    placeholder='e.g. 30'
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/RM\s?|(,*)/g, '')}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        className='mb-0 col-span-6'
                        label='Gap Duration Unit'
                        validateStatus={(errors?.settings?.appointment?.gap?.durationUnit?.message) ? 'error' : '-'}
                        help={errors?.settings?.appointment?.gap?.durationUnit?.message}
                    >
                        <Controller
                            name='settings.appointment.gap.durationUnit'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Select
                                    placeholder='Select'
                                    options={[
                                        { label: 'Minutes', value: 'MINUTE' }
                                    ]}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        className='mb-0 col-span-6'
                        label='Timezone'
                        validateStatus={(errors?.settings?.appointment?.timezone?.message) ? 'error' : '-'}
                        help={errors?.settings?.appointment?.timezone?.message}
                    >
                        <Controller
                            name='settings.appointment.timezone'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Select
                                    ref={ref}
                                    placeholder='Select timezone'
                                    options={[
                                        { label: 'Asia - Kuala Lumpur', value: 'Asia/Kuala_Lumpur' }
                                    ]}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>
                </div>
            </Card>

            <Form.Item
                className='mb-4'
                validateStatus={(errors?.isPublic?.message) ? 'error' : '-'}
                help={errors?.isPublic?.message}
            >
                <Controller
                    name='isPublic'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Checkbox
                            onChange={onChange}
                            checked={value}
                        >
                            Is Public
                        </Checkbox>
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4 col-span-6'
                label='Sort Weight'
                validateStatus={(errors?.sortWeight?.message) ? 'error' : '-'}
                help={errors?.sortWeight?.message}
            >
                <Controller
                    name='sortWeight'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <InputNumber
                            ref={ref}
                            className='w-full'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <SubmitButton className='mt-1' loading={submitting}>
                Submit
            </SubmitButton>
        </Form>
    )
}

export default inject('services')(UpdateCfpForm)
