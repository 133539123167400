import Layout from '@components/cfp/layout/CfpLayout'
import { PageContainer } from '@ant-design/pro-layout'
import React, { useEffect, useState } from 'react'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import { inject } from 'mobx-react'
import { Statistic, Col, Row, Spin, Card } from 'antd'

const DashboardPage = ({ logger, services: { cfpService } }) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    const getCfpDashboard = async () => {
        try {
            const apiParams = {}
            const res = await cfpService.getCfpAppointment(apiParams)

            if (res.ok) {
                const resData = res.data
                setData(resData)
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
            }
        } catch (e) {
            logger.error('[Failed[', e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        // getCfpDashboard()
    }, [])

    return (
        <div>
            <Layout>
                <PageContainer
                    breadcrumb={{
                        routes: [
                            {
                                breadcrumbName: 'Dashboard'
                            }
                        ]
                    }}
                    header={{
                        ghost: false
                    }}
                    title='Dashboard'
                >
                    {/* {loading &&
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <Spin size="large" />
                        </div>}

                    {!loading && data &&
                        <div>
                            <Row gutter={16} style={{ marginBottom: '30px' }}>
                                <Col span={12}>
                                    <Card bordered={false}>
                                        <Statistic title="Pending Appointment" value={data.cfpTotalPendingAppointment} />
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card bordered={false}>
                                        <Statistic title="Upcoming Appointment" value={data.cfpTotalUpcomingAppointment} />
                                    </Card>
                                </Col>
                            </Row>
                        </div>} */}
                </PageContainer>
            </Layout>
        </div>
    )
}

export default inject('services', 'logger')(DashboardPage)
