import { Link } from 'react-router-dom'
import { Drawer } from 'antd'
import { cfpCustomerDetailPath, cfpCustomerPlanDetailPath } from '@constants/routes'
import AppointmentDetail from '@components/shared/appointment/detail/AppointmentDetail'

const CfpAppointmentDetailDrawer = ({ className, style, loading, visible, onClose, appointment, ...props }) => {
    return (
        <Drawer
            width={700}
            open={visible}
            onClose={onClose}
            closable={false}
            destroyOnClose
        >
            {appointment &&
            <AppointmentDetail
                className={className}
                style={style}
                loading={loading}
                appointment={appointment}
                renderColumns={(defaultColumns) => {
                    defaultColumns.customerFullName = {
                        ...defaultColumns.customerFullName,
                        render: (text, record) => {
                            return (
                                <Link to={cfpCustomerDetailPath(record.customerId)}>
                                    {record.customer.fullName}
                                </Link>
                            )
                        }
                    }

                    defaultColumns.planId = {
                        ...defaultColumns.planId,
                        render: (text, record) => {
                            return (
                                <Link to={cfpCustomerPlanDetailPath(record.planId)}>
                                    {record.planId}
                                </Link>
                            )
                        }
                    }

                    return Object.values(defaultColumns)
                }}
            />}
        </Drawer>
    )
}

export default CfpAppointmentDetailDrawer
