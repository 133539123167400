import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Drawer, Progress, Tag, Typography } from 'antd'
import ProDescriptions from '@ant-design/pro-descriptions'
import planStatuses from '@constants/planStatuses'

const PlanDetail = (props) => {
    const { renderColumns, loading, className, extra } = props
    const [plan, setPlan] = useState(props.plan)

    const defaultColumns = {
        id: {
            title: 'ID',
            dataIndex: 'id',
            render: (text, record) => {
                return text
            }
        },
        customer: {
            title: 'Customer',
            render: (text, record) => {
                if (!record?.customer?.fullName) return '-'
                return (
                    record.customer.fullName
                )
            }
        },
        cfp: {
            title: 'CFP',
            render: (text, record) => {
                if (!record?.cfp?.name) return '-'
                return record?.cfp?.name
            }
        },
        planType: {
            title: 'Type',
            dataIndex: 'planTypeId',
            render: (text, record) => {
                if (!record?.planTypeName) return '-'
                return (
                    record.planTypeName
                )
            }
        },
        planTypeVariant: {
            title: 'Variant',
            render: (text, record) => {
                if (!record?.planTypeVariantType) return '-'
                return (
                    record.planTypeVariantType
                )
            }
        },
        totalSession: {
            title: 'Total Session',
            dataIndex: 'totalSession',
            render: (text, record) => {
                return text
            }
        },
        currentSession: {
            title: 'Current Session',
            dataIndex: 'currentSessionNumber',
            render: (text, record) => {
                return text
            }
        },
        progress: {
            title: 'Progress',
            dataIndex: 'progressScore',
            render: (text, record) => (
                <Progress percent={text} size='small' />
            )
        },
        status: {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                const planStatus = planStatuses[text]
                return (
                    <Tag color={planStatus?.color}>
                        {planStatus?.name}
                    </Tag>
                )
            }
        },
        purchaseId: {
            title: 'Purchase ID',
            dataIndex: 'purchaseId',
            render: (text, record) => {
                return text ?? '-'
            }
        },
        expiredAt: {
            title: 'Expired At',
            search: false,
            valueType: 'datetime',
            render: (text, record) => {
                if (!record?.expiredAt) return '-'
                return moment.utc(text).local().format('D MMM YYYY hh:mm A')
            }
        },
        expireDuration: {
            title: 'Expire Duration',
            search: false,
            render: (text, record) => {
                if (!record?.expiredDuration) return '-'
                return (
                    <div>
                        {record.expireDuration} {record.expireDurationUnit}
                    </div>
                )
            }
        },
        createdAt: {
            title: 'Created At',
            dataIndex: 'createdAt',
            search: false,
            valueType: 'datetime',
            renderText: (text) => {
                return moment.utc(text).local().format('D MMM YYYY hh:mm A')
            }
        },
        paidAt: {
            title: 'Paid At',
            dataIndex: 'paidAt',
            search: false,
            valueType: 'datetime',
            renderText: (text) => {
                return (text) ? moment.utc(text).local().format('D MMM YYYY hh:mm A') : '-'
            }
        },
        completedAt: {
            title: 'Completed At',
            dataIndex: 'completedAt',
            search: false,
            valueType: 'datetime',
            renderText: (text) => {
                return (text) ? moment.utc(text).local().format('D MMM YYYY hh:mm A') : '-'
            }
        }
    }

    let columns = Object.values(defaultColumns)
    if (renderColumns) {
        columns = renderColumns(defaultColumns)
    }

    useEffect(() => {
        if (props.plan) {
            setPlan(props.plan)
        }
    }, [props.plan])

    return (
        <ProDescriptions
            className={className}
            title='Plan Detail'
            bordered
            layout='horizontal'
            size='small'
            column={1}
            loading={loading}
            columns={columns}
            dataSource={plan}
            extra={extra}
        />
    )
}

export default PlanDetail
