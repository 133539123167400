import { useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { PageContainer } from '@ant-design/pro-layout'
import { inject } from 'mobx-react'
import Layout from '@components/cfp/layout/CfpLayout'
import { Link, useParams } from 'react-router-dom'
import { cfpCustomerPlanListingPath } from '@constants/routes'
import CfpPlanList from '@components/cfp/plan/list/CfpPlanList'

const CfpPlanListingPage = ({ logger, services: { customerService } }) => {
    const { id } = useParams()

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: cfpCustomerPlanListingPath,
                            breadcrumbName: 'Plans'
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title={id}
            >
                <CfpPlanList />
            </PageContainer>
        </Layout>
    )
}

export default inject('services', 'logger')(CfpPlanListingPage)
