import { string, object, ref } from 'yup'

const schema = object().shape({
    password: string()
        .min(6)
        .required(),
    passwordConfirmation: string()
        .min(6)
        .oneOf([ref('password'), null])
        .required()
})

export default schema
