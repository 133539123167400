import { useEffect, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import cn from 'classnames'
import Modal from 'antd/lib/modal/Modal'
import CfpAddAppointmentForm from '../form/CfpAddAppointmentForm'

const CfpAddAppointmentModal = (props) => {
    return (
        <Modal
            className={cn(props.className)}
            title='Add Appointment'
            open={props.visible}
            onCancel={props.onCancel}
            footer={null}
            destroyOnClose
        >
            <CfpAddAppointmentForm
                {...props.form}
            />
        </Modal>
    )
}

export default inject('services')(CfpAddAppointmentModal)
