import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Button } from 'antd'

const SubmitButton = (props) => {
    return (
        <Button
            {...props}
            className={cn(props.className)}
            type='primary'
            htmlType='submit'
        >
            {props.children}
        </Button>
    )
}

SubmitButton.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

SubmitButton.defaultProps = {
}

export default SubmitButton
