import React, { useEffect, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import moment from 'moment'
import { Button } from 'antd'
import ProTable from '@ant-design/pro-table'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import AppointmentStatus from '@models/enum/AppointmentStatus'
import showApiErrorMessage from '@helpers/showApiErrorMessage'

const AppointmentList = ({ className, style, actionRef, handleRequest, renderColumns, renderToolbar, search, title, onReset, logger, services, isCfp }) => {
    const { planTypeService } = services
    const [planTypeOptions, setPlanTypeOptions] = useState([])

    useEffect(()=>{
        const getPlanTypeOptions = async () => {
            try {
                const res = await planTypeService.listFromAdmin()

                if (res.ok) {
                    const planTypeList = res.data.data
                    const planTypeOptions = planTypeList.map(item => ({
                        value: item.id, 
                        label: item.name 
                    }))

                    setPlanTypeOptions(planTypeOptions)
                    return 
                } else {
                    const errors = res.data?.errors
                    if (errors) {
                        showApiErrorMessage(errors)
                    }
                    return {
                        data: null,
                        success: res.ok,
                        total: 0
                    }
                }
            } catch (e) {
                logger.error('[Failed List Plan Type Options]', e)
            }
        }

        getPlanTypeOptions()

    },[])

    const defaultColumns = {
        customerFullName: {
            title: 'Customer',
            dataIndex: 'fullName',
            search: true,
            render: (text, record) => (
                isCfp ? 
                    <Link to={`/cfp/customers/${record.customerId}`}>
                        {record.customer.fullName}
                    </Link> : <Link to={`/admin/customers/${record.customerId}`}>
                        {record.customer.fullName}
                    </Link>
            )
        },
        customerEmail: {
            title: 'Customer Email',
            dataIndex: 'email',
            width: 300,
            search: false,
            render: (text, record) => (
                record.customer.email
            )
        },
        cfp: {
            title: 'CFP',
            dataIndex: 'cfpName',
            search: true,
            render: (text, record) => {
                if (!record.cfp?.name) return '-'
                const cfpName = record.cfp.name
                return (
                    cfpName
                )
            }
        },
        planType: {
            title: 'Plan Type',
            dataIndex: 'planTypeName',
            search: false,
            render: (text, record) => {
                if (!record?.plan?.planTypeName) return '-'
                if (!record?.plan?.planTypeVariantType) {
                    return (
                        record.plan.planTypeName
                    )
                }
                return (
                    record.plan.planTypeName + ' ' + record.plan.planTypeVariantType
                )
            }
        },
        planTypeId: {
            title: 'Plan Type',
            dataIndex: 'planTypeId',
            valueType: 'select', 
            hideInTable: true,
            fieldProps: {
                options : planTypeOptions
            }
        },
        session: {
            title: 'Session',
            sorter: true,
            search: false,
            dataIndex: 'sessionNumber',
            render: (text, record) => {
                return text
            }
        },
        status: {
            title: 'Status',
            search: false,
            dataIndex: 'status',
            render: (text, record) => {
                return (
                    AppointmentStatus.of(text)?.label
                )
            }
        },
        startTime: {
            title: 'Start Time',
            dataIndex: 'startTime',
            search: false,
            sorter: true,
            valueType: 'datetime',
            renderText: (text) => {
                if (!text) return '-'
                return moment.utc(text).local().format('D MMM YYYY hh:mm A')
            }
        },
        endTime: {
            title: 'End Time',
            dataIndex: 'endTime',
            search: false,
            sorter: true,
            valueType: 'datetime',
            renderText: (text) => {
                if (!text) return '-'
                return moment.utc(text).local().format('D MMM YYYY hh:mm A')
            }
        },
        appointmentDate: {
            title: 'Appt. Date',
            dataIndex: 'appointmentDate',
            search: true,
            valueType: 'dateTimeRange',
            hideInTable: true
        },
        createdAt: {
            title: 'Created At',
            dataIndex: 'createdAt',
            search: false,
            sorter: true,
            valueType: 'datetime',
            renderText: (text) => {
                if (!text) return '-'
                return moment.utc(text).local().format('D MMM YYYY hh:mm A')
            }
        },
        appointmentCreatedAt: {
            title: 'Created At',
            dataIndex: 'appointmentCreatedAt',
            search: true,
            valueType: 'dateTimeRange',
            hideInTable: true
        },
        action: {
            title: 'Actions',
            valueType: 'option',
            key: 'option',
            render: (text, record, _, action) => {
                const actionComponents = [
                    <Button
                        type='link'
                        key='view'
                    >
                        View
                    </Button>
                ]
                if (record.status === AppointmentStatus.PENDING.enumKey || record.status === AppointmentStatus.UPCOMING.enumKey) {
                    const updateBtn = <a
                        key='edit'
                    >
                        Update
                    </a>
                    actionComponents.push(updateBtn)

                    const cancelBtn = <a
                        key='cancel'
                    >
                        Cancel
                    </a>

                    actionComponents.push(cancelBtn)
                }
                if (record.status === AppointmentStatus.UPCOMING.enumKey) {
                    const updateBtn = <a
                        key='complete'
                    >
                        Complete
                    </a>
                    actionComponents.push(updateBtn)
                }
                return actionComponents
            }
        }
    }

    let columns = Object.values(defaultColumns)
    if (renderColumns) {
        columns = renderColumns(defaultColumns)
    }

    useEffect(() => {
    }, [])

    return (
        <ProTable
            className={cn(className)}
            style={style}
            title={title}
            search={search}
            actionRef={actionRef}
            columns={columns}
            rowKey='id'
            request={handleRequest}
            pagination={{
                showSizeChanger: false
            }}
            onReset={onReset}
            toolBarRender={() => {
                const defaultToolbars = []

                if (renderToolbar) {
                    return renderToolbar(defaultToolbars)
                }

                return defaultToolbars
            }}
        />
    )
}

AppointmentList.defaultProps = {
    title: () => 'Appointments',
    onReset: () => {}
}

export default inject('logger', 'services')(AppointmentList)
