import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import AdminPrivateRoute from './AdminPrivateRoute'
import AdminRole from '@models/enum/AdminRole'
import AdminCustomerListingPage from '@pages/admin/customers'
import AdminCustomerDetailPage from '@pages/admin/customers/[id]'

const AdminCustomerRoutes = (props) => {
    const logger = props.logger
    const { pathname } = useLocation()
    const navigate = useNavigate()

    return (
        <Routes>
            <Route
                path='/'
                exact
                element={
                    <AdminPrivateRoute roles={[AdminRole.SUPER_ADMIN, AdminRole.ADMIN]}>
                        <AdminCustomerListingPage />
                    </AdminPrivateRoute>
                }
            />
            <Route
                path='/:id'
                element={
                    <AdminPrivateRoute roles={[AdminRole.SUPER_ADMIN, AdminRole.ADMIN]}>
                        <AdminCustomerDetailPage />
                    </AdminPrivateRoute>
                }
            />
        </Routes>
    )
}

export default inject('logger')(AdminCustomerRoutes)
