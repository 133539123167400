import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import moment from 'moment'
import { Button, Modal, Tag } from 'antd'
import { PageContainer } from '@ant-design/pro-layout'
import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons'
import ProTable from '@ant-design/pro-table'
import Layout from '@components/admin/layout/AdminLayout'
import prepareApiParams from '@helpers/prepareApiParams'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import AddCfpModal from '@components/admin/cfp/add/modal/AddCfpModal'
import UpdateCfpModal from '@components/admin/cfp/update/modal/UpdateCfpModal'
import { adminCfpListingPath } from '@constants/routes'

const AdminCfpListingPage = ({ config, logger, services: { cfpService } }) => {
    const tableActionRef = useRef(null)
    const [toShowAddCfpModal, setToShowAddCfpModal] = useState(false)
    const [updateCfpFormProps, setUpdateCfpFormProps] = useState(null)
    const [toShowUpdateCfpModal, setToShowUpdateCfpModal] = useState(false)

    const columns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                width: 200,
                fixed: 'left',
                search: true,
                render: (text, record) => {
                    return text
                }
            },
            {
                title: 'Email',
                dataIndex: 'email',
                width: 250,
                search: true,
                render: (text, record) => {
                    return text
                }
            },
            {
                title: 'Next Appointment At',
                dataIndex: 'nextAppointmentAt',
                search: false,
                sorter: true,
                width: 250,
                valueType: 'datetime',
                renderText: (text, record) => {
                    if (!record.nextAppointmentAt) return '-'
                    return moment.utc(record.nextAppointmentAt).local().format('D MMM YYYY hh:mm A')
                }
            },
            {
                title: 'Verified',
                dataIndex: 'verified',
                search: false,
                width: 100,
                render: (text, record) => {
                    if (record.verified) {
                        return (
                            <Tag color='success'>
                                True
                            </Tag>
                        )
                    } else {
                        return (
                            <Tag color='error'>
                                False
                            </Tag>
                        )
                    }
                }
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                search: false,
                sorter: true,
                width: 250,
                valueType: 'datetime',
                renderText: (text) => {
                    return moment.utc(text).local().format('D MMM YYYY hh:mm A')
                }
            },
            {
                title: 'Actions',
                valueType: 'option',
                key: 'option',
                width: 200,
                search: false,
                fixed: 'right',
                render: (text, record, _, action) => {
                    const actionComponents = [
                        <a
                            key='edit'
                            onClick={() => {
                                const defaultValues = record

                                if (defaultValues?.settings?.appointment?.defaultStartTime) {
                                    defaultValues.settings.appointment.defaultStartTime = moment(defaultValues.settings.appointment.defaultStartTime, 'HH:mm')
                                }

                                if (defaultValues?.settings?.appointment?.defaultEndTime) {
                                    defaultValues.settings.appointment.defaultEndTime = moment(defaultValues.settings.appointment.defaultEndTime, 'HH:mm')
                                }
                                setUpdateCfpFormProps({
                                    defaultValues
                                })
                                setToShowUpdateCfpModal(true)
                            }}
                        >
                            Edit
                        </a>
                    ]
                    if (!record.verified) {
                        const btnResendEmailVerification = <a
                            key='btn-resend-email-verification'
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Are you sure to resend email verification?',
                                    icon: <ExclamationCircleFilled />,
                                    okText: 'Confirm',
                                    cancelText: 'Cancel',
                                    onOk: async () => {
                                        await resendEmailVerificationCode(record.email)
                                    }
                                })
                            }}
                        >
                            Resend Email Verification
                        </a>
                        actionComponents.push(btnResendEmailVerification)
                    }
                    return actionComponents
                }
            }
        ]
    }, [])

    useEffect(() => {
    }, [])

    const handleRequest = async (params, sort) => {
        try {
            const apiParams = {}
            prepareApiParams(params, sort, apiParams)
            const res = await cfpService.listFromAdmin(apiParams)

            if (res.ok) {
                const data = res.data
                return {
                    data: data.data,
                    success: res.ok,
                    total: data.lastPage * data.perPage
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                return {
                    data: null,
                    success: res.ok,
                    total: 0
                }
            }
        } catch (e) {
            logger.error('[Failed List CFPs]', e)
        }
    }

    const handleSuccessAddCfp = (data) => {
        setToShowAddCfpModal(false)
        tableActionRef.current?.reload()
    }

    const handleSuccesUpdateCfp = () => {
        setToShowUpdateCfpModal(false)
        tableActionRef.current?.reload()
    }

    const resendEmailVerificationCode = async (email) => {
        try {
            const res = await cfpService.resendEmailVerificationCode(email)

            if (res.ok && res.data) {
                Modal.success({
                    title: 'Email Verification Resent',
                    content: (
                        <p>Email verification has ben resent to {email}</p>
                    )
                })
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (e) {
            logger.error('[Failed Resend Email Verification]', e)
        }
    }

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: adminCfpListingPath,
                            breadcrumbName: 'CFP'
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title='Certified Financial Planners'
            >
                <ProTable
                    actionRef={tableActionRef}
                    search={{
                        defaultCollapsed: false,
                        span: 12,
                        labelWidth: 'auto'
                    }}
                    request={handleRequest}
                    columns={columns}
                    rowKey='id'
                    scroll={{ x: '120%' }}
                    pagination={{
                        showSizeChanger: false
                    }}
                    toolBarRender={() => [
                        <Button
                            type='primary'
                            key='primary'
                            onClick={() => {
                                setToShowAddCfpModal(true)
                            }}
                        >
                            <PlusOutlined /> Add
                        </Button>
                    ]}
                />

                <AddCfpModal
                    visible={toShowAddCfpModal}
                    onCancel={() => setToShowAddCfpModal(false)}
                    onSuccessSubmit={handleSuccessAddCfp}
                />

                {updateCfpFormProps &&
                    <UpdateCfpModal
                        key={updateCfpFormProps.defaultValues.id}
                        form={updateCfpFormProps}
                        visible={toShowUpdateCfpModal}
                        onCancel={() => setToShowUpdateCfpModal(false)}
                        onSuccessSubmit={handleSuccesUpdateCfp}
                    />}
            </PageContainer>
        </Layout>
    )
}

export default inject('config', 'services', 'logger')(AdminCfpListingPage)
