export const listPlanTypeFromAdminEndpointPath = '/admin/plan-type/list'
export const listPlanTypeVariantFromAdminEndpointPath = '/admin/plan-type/variant/list'
export const getPlanTypeDetailFromAdminEndpointPath = '/admin/plan-type/detail'
export const createPlanTypeFromAdminEndpointPath = '/admin/plan-type/create'
export const updatePlanTypeFromAdminEndpointPath = '/admin/plan-type/update'
export const listPlanTypeFromCfpEndpointPath = '/admin/plan-type/list'
export const listPlanTypeVariantFromCfpEndpointPath = '/admin/plan-type/variant/list'

export const uploadFilePlanTypeFromAdminEndpointPath = '/admin/plan-type/attach-file'
export const deleteFilePlanTypeFromAdminEndpointPath = '/admin/plan-type/remove-file'

class PlanTypeService {
    constructor (adminWebClient) {
        this.adminWebClient = adminWebClient
    }

    async listFromAdmin (params) {
        const res = await this.adminWebClient.get(listPlanTypeFromAdminEndpointPath, params)
        return res
    }

    async listVariantFromAdmin (params) {
        const res = await this.adminWebClient.get(listPlanTypeVariantFromAdminEndpointPath, params)
        return res
    }

    async getFromAdmin (params) {
        const res = await this.adminWebClient.get(getPlanTypeDetailFromAdminEndpointPath, params)
        return res
    }

    async createFromAdmin (params) {
        const res = await this.adminWebClient.post(createPlanTypeFromAdminEndpointPath, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return res
    }

    async updateFromAdmin (params) {
        const res = await this.adminWebClient.post(updatePlanTypeFromAdminEndpointPath, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return res
    }

    async listFromCfp (params) {
        const res = await this.adminWebClient.get(listPlanTypeFromCfpEndpointPath, params)
        return res
    }

    async listVariantFromCfp (params) {
        const res = await this.adminWebClient.get(listPlanTypeVariantFromCfpEndpointPath, params)
        return res
    }

    async uploadFilePlanType(formData) {
        const res = await this.adminWebClient.post(uploadFilePlanTypeFromAdminEndpointPath, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return res
    }

    async deleteFilePlanType(formData) {
        const res = await this.adminWebClient.post(deleteFilePlanTypeFromAdminEndpointPath, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return res
    }
}

export default PlanTypeService
