import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import numeral from 'numeral'
import { Link } from 'react-router-dom'
import { Drawer, Typography } from 'antd'
import ProDescriptions from '@ant-design/pro-descriptions'
import PurchaseStatus from '@models/enum/PurchaseStatus'

const PurchaseDetailDrawer = (props) => {
    const [purchase, setPurchase] = useState(props.purchase)

    const columns = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: 'Plan Type',
                render: (text, record) => {
                    if (!record.purchasePlans || record.purchasePlans.length === 0) return '-'
                    const purchasePlanType = record.purchasePlans[0]
                    return purchasePlanType.name
                }
            },
            {
                title: 'Variant',
                render: (text, record) => {
                    if (!record.purchasePlans || record.purchasePlans.length === 0) return '-'
                    const purchasePlanType = record.purchasePlans[0]

                    if (!purchasePlanType?.planTypeVariantType) return '-'
                    return purchasePlanType.planTypeVariantType
                }
            },
            {
                title: 'Plan Type Total Session',
                render: (text, record) => {
                    if (!record.purchasePlans || record.purchasePlans.length === 0) return '-'
                    const purchasePlanType = record.purchasePlans[0]
                    return purchasePlanType?.totalSession
                }
            },
            {
                title: 'Full Name',
                dataIndex: 'customerFullName',
                render: (text, record) => {
                    if (!text) return '-'
                    return text
                }
            },
            {
                title: 'IC Number',
                dataIndex: 'customerIcNumber',
                render: (text, record) => {
                    return text
                }
            },
            {
                title: 'Email',
                dataIndex: 'customerEmail',
                render: (text, record) => {
                    return text
                }
            },
            {
                title: 'Contact',
                dataIndex: 'contact',
                renderText: (text, record) => {
                    if (!record.customerMobilePrefix || !record.customerMobileNumber) return '-'
                    return `+${record.customerMobilePrefix} ${record.customerMobileNumber}`
                }
            },
            {
                title: 'Monthly Income',
                dataIndex: 'customerMonthlyIncome',
                renderText: (text, record) => {
                    return `MYR ${numeral(record.customerMonthlyIncome).format('0,0.00')}`
                }
            },
            {
                title: 'Original Amount',
                dataIndex: 'originalAmount',
                renderText: (text, record) => {
                    return `${record.currency} ${numeral(record.originalAmount).format('0,0.00')}`
                }
            },
            {
                title: 'Discount Amount',
                dataIndex: 'discountAmount',
                renderText: (text, record) => {
                    return `${record.currency} ${numeral(record.discountAmount).format('0,0.00')}`
                }
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                renderText: (text, record) => {
                    return <span className='text-green-600'>{`${record.currency} ${numeral(record.amount).format('0,0.00')}`}</span>
                }
            },
            {
                title: 'Referral Code',
                dataIndex: 'referralCode',
                render: (text, record) => {
                    if (!text) return '-'
                    return text
                }
            },
            {
                title: 'Promo Code',
                dataIndex: 'promoCode',
                render: (text, record) => {
                    if (!text) return '-'
                    return text
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (text, record) => {
                    return (
                        PurchaseStatus.of(text)?.label
                    )
                }
            },
            {
                title: 'Payment Gateway',
                render: (text, record) => {
                    if (!record.lastPayment) return '-'
                    const payment = record.lastPayment
                    return (
                        payment.paymentGateway
                    )
                }
            },
            {
                title: 'EGHL Payment Method',
                render: (text, record) => {
                    if (!record.lastPayment) return '-'
                    const payment = record.lastPayment
                    return (
                        payment.eghlPayment.paymentMethod ? payment.eghlPayment.paymentMethod : '-'
                    )
                }
            },
            {
                title: 'Purchased At',
                dataIndex: 'createdAt',
                width: 250,
                valueType: 'datetime',
                renderText: (text) => {
                    return moment.utc(text).local().format('D MMM YYYY hh:mm A')
                }
            }
        ]
    }, [])

    useEffect(() => {
        if (props.purchase) {
            setPurchase(props.purchase)
        }
    }, [props.purchase])

    return (
        <Drawer
            width={700}
            open={props.visible}
            onClose={props.onClose}
            closable={false}
        >
            {purchase &&
            <ProDescriptions
                title={'Purchase Detail'}
                bordered
                layout='horizontal'
                size='small'
                column={1}
                columns={columns}
                dataSource={purchase}
            />}
        </Drawer>
    )
}

export default PurchaseDetailDrawer
