import { useState, useEffect } from 'react'
import { inject } from 'mobx-react'
import { Routes, Route, Navigate } from 'react-router-dom'
import AdminRoutes from './admin/AdminRoutes'
import CfpRoutes from './cfp/CfpRoutes'

const MainRoutes = (props) => {
    const logger = props.logger

    return (
        <Routes>
            <Route path='/' element={<Navigate to='/admin/login' replace />} />
            <Route path='/admin/*' element={<AdminRoutes />} />
            <Route path='/cfp/*' element={<CfpRoutes />} />
        </Routes>
    )
}

export default inject('logger')(MainRoutes)
