import React, { useState, useEffect, useMemo } from 'react'
import { Provider as MobxProvider } from 'mobx-react'
import { ConfigProvider, Modal } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import AppContext from './AppContext'
import OnlineStatusProvider from '@contexts/online-status/OnlineStatusProvider'

const { Provider } = AppContext

const AppProvider = props => {
    const container = props.container
    const adminStore = container.get('adminStore')
    const cfpStore = container.get('cfpStore')
    const config = container.get('config')
    const services = container.get('services')
    const logger = container.get('logger')

    useEffect(() => {
    }, [])

    return (
        <Provider value={{}}>
            <MobxProvider
                adminStore={adminStore}
                cfpStore={cfpStore}
                config={config}
                services={services}
                logger={logger}
            >
                <ConfigProvider locale={enUS}>
                    <OnlineStatusProvider>
                        {props.children}
                    </OnlineStatusProvider>
                </ConfigProvider>
            </MobxProvider>
        </Provider>
    )
}

export default AppProvider
