import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Drawer, Progress, Tag, Typography } from 'antd'
import ProDescriptions from '@ant-design/pro-descriptions'
import Gender from '@models/enum/Gender'
import CustomerType from '@models/enum/CustomerType'
import Race from '@models/enum/Race'
import numeral from 'numeral'

const CustomerDetail = (props) => {
    const { renderColumns, loading, className, extra } = props
    const [customer, setCustomer] = useState(props.plan)

    const defaultColumns = {
        id: {
            title: 'ID',
            dataIndex: 'id'
        },
        fullName: {
            title: 'Full Name',
            dataIndex: 'fullName',
            render: (text, record) => {
                return text
            }
        },
        email: {
            title: 'Email',
            dataIndex: 'email',
            render: (text, record) => {
                return (
                    <a href={`mailto:${text}`}>
                        {text}
                    </a>
                )
            }
        },
        contact: {
            title: 'Contact',
            dataIndex: 'contact',
            search: false,
            width: 150,
            renderText: (text, record) => {
                if (!record.mobilePrefix || !record.mobileNumber) {
                    return '-'
                }
                return `+${record.mobilePrefix} ${record.mobileNumber}`
            }
        },
        identityNumber: {
            title: 'Identity Number',
            dataIndex: 'icNumber',
            search: false,
            width: 150,
            renderText: (text, record) => {
                if (!text) return '-'
                return text
            }
        },
        birthDate: {
            title: 'Birth Date',
            dataIndex: 'birthDate',
            search: false,
            width: 100,
            renderText: (text, record) => {
                if (!record.birthDate) return '-'
                const dateStr = moment(record.birthDate).format('D MMM YYYY')
                return dateStr
            }
        },
        gender: {
            title: 'Gender',
            dataIndex: 'gender',
            search: false,
            width: 100,
            renderText: (text, record) => {
                if (record.gender === undefined || record.gender === null) return '-'
                return Gender.of(record.gender).label
            }
        },
        type: {
            title: 'Type',
            dataIndex: 'type',
            search: false,
            width: 100,
            renderText: (text, record) => {
                if (!record.type) {
                    return '-'
                }
                return CustomerType.of(record.type).label
            }
        },
        race: {
            title: 'Race',
            dataIndex: 'race',
            search: false,
            width: 100,
            renderText: (text, record) => {
                if (!record.race) {
                    return '-'
                }
                return Race.of(record.race).label
            }
        },
        monthlyIncome: {
            title: 'Monthly Income',
            dataIndex: 'monthlyIncome',
            search: false,
            width: 200,
            renderText: (text, record) => {
                if (!record.monthlyIncome) {
                    return '-'
                }
                return numeral(record.monthlyIncome).format('0,0.00')
            }
        },
        latestPlanType: {
            title: 'Latest Plan',
            dataIndex: 'latestPlanType',
            search: false,
            filters: true,
            onFilter: true,
            width: 150,
            render: (text, record) => {
                if (!record.latestPlan) return <span>-</span>
                return <span>{record.latestPlan.planTypeName}</span>
            }
        },
        latestPlanProgress: {
            title: 'Latest Progress',
            dataIndex: 'latestPlanProgressScore',
            width: 200,
            search: false,
            render: (text, record) => (
                <Progress percent={text} size='small' />
            ),
            sorter: (a, b) => {
                return a.progress - b.progress
            }
        },
        verified: {
            title: 'Verified',
            dataIndex: 'verified',
            search: false,
            width: 100,
            render: (text, record) => {
                if (record.verified) {
                    return (
                        <Tag color='success'>
                            True
                        </Tag>
                    )
                } else {
                    return (
                        <Tag color='error'>
                            False
                        </Tag>
                    )
                }
            }
        }
    }
 
    let columns = Object.values(defaultColumns)
    if (renderColumns) {
        columns = renderColumns(defaultColumns)
    }

    useEffect(() => {
        if (props.customer) {
            setCustomer(props.customer)
        }
    }, [props.customer])

    return (
        <ProDescriptions
            className={className}
            title='Customer Detail'
            bordered
            layout='horizontal'
            size='small'
            column={1}
            loading={loading}
            columns={columns}
            dataSource={customer}
            extra={extra}
        />
    )
}

export default CustomerDetail
