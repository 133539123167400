import { Enumify } from 'enumify'

export default class PlanType extends Enumify {
    value = null

    constructor (value = null) {
        super()
        this.value = value
    }

    static of (type) {
        return PlanType.enumValueOf(type)
    }

    static for (type) {
        switch (type) {
            case 0: return PlanType.STANDARD
            case 1: return PlanType.PLUS
            case 2: return PlanType.THREE60
            case 3: return PlanType.STANDARD_2
            case 4: return PlanType.THREE60_2
            case 5: return PlanType.COUPLE_STANDARD_2
            case 6: return PlanType.COUPLE_THREE60_2
        }
        return null
    }
}

PlanType.STANDARD = new PlanType(0)
PlanType.PLUS = new PlanType(1)
PlanType.THREE60 = new PlanType(2)
PlanType.STANDARD_2 = new PlanType(3)
PlanType.THREE60_2 = new PlanType(4)
PlanType.COUPLE_STANDARD_2 = new PlanType(5)
PlanType.COUPLE_THREE60_2 = new PlanType(6)
PlanType._ = PlanType.closeEnum()
