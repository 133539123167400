import React from 'react'
import { Spin } from 'antd'
import moment from 'moment'

import styles from './AssessmentAnswerCard.module.scss'


const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY')
}

const AssessmentAnswerCard = ({ data, loading }) => {
    const questions = data?.questions
    // console.log('question:', questions)
    return (
        <>
            {loading && <Spin className='mt-3' />}
            {!loading &&
            <div className={styles.wrapper}>
                <div className={styles.titleWrapper}>
                    <p>{data?.title}</p>
                </div>
                <div className={styles.contentWrapper}>
                    {questions?.map((item, index) => {
                        if (item?.question?.answerType === 'flexiMultipleQuestions'){
                            if (item?.answer?.length === 0){
                                return (
                                    <div className={styles.content} key={index}>
                                        <p className={styles.answer}>None</p>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={index}>
                                        <div className={styles.content}>
                                            {item?.answer?.map((itemFlexi, indexFlexi) => (
                                                <div key={indexFlexi} className='pb-[12px]'>
                                                    <p className='text-[12px] font-semibold'>{itemFlexi?.name}</p>
                                                    {itemFlexi?.monthPaymentAmount && 
                                                    <>
                                                        <p className={styles.answer}>Property Value : RM {itemFlexi?.value?.toLocaleString()}</p>
                                                        <p className={styles.answer}>Current Interest Rate : {itemFlexi?.interestRate?.toLocaleString()}%</p>
                                                        <p className={styles.answer}>Remaining Period : {itemFlexi?.period?.toLocaleString()} months</p>
                                                        <p className={styles.answer}>Current Monthly Payment : RM {itemFlexi?.monthPaymentAmount?.toLocaleString()}</p>
                                                    </>
                                                    }
                                                    {itemFlexi?.monthlyPaymentAmount && 
                                                    <>
                                                        <p className={styles.answer}>Remaining Period : {itemFlexi?.period?.toLocaleString()} months</p>
                                                        <p className={styles.answer}>Current Interest Rate : {itemFlexi?.interestRate?.toLocaleString()}%</p>
                                                        <p className={styles.answer}>Current Monthly Payment : RM {itemFlexi?.monthlyPaymentAmount?.toLocaleString()}</p>
                                                        {itemFlexi?.type !== undefined &&
                                                        <p className={styles.answer}>Mortgage Type : {itemFlexi?.type}</p>
                                                        }
                                                        {itemFlexi?.isInsuranceCovered !== undefined &&
                                                        <p className={styles.answer}>Covered With Insurance : {itemFlexi?.isInsuranceCovered?.toLocaleString()}</p>
                                                        }
                                                       
                                                    </>
                                                    }
                                                    {itemFlexi?.outstandingAmount &&
                                                    <>
                                                        <p className={styles.answer}>Interest Rate : {itemFlexi?.interestRate?.toLocaleString()}%</p>
                                                        <p className={styles.answer}>Outstanding Amount : RM {itemFlexi?.outstandingAmount?.toLocaleString()}</p>
                                                    </>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                            }
                        } else if (item?.question?.answerType === 'numberInput') {
                            return (
                                <div className={styles.content} key={index}>
                                    <p>{item?.question?.questionText}</p>
                                    {item?.question?.answerLabelsPosition === 'right' ? 
                                        <p className={styles.answer}>{item?.answer}{' '}{item?.question?.answerLabels}</p> :
                                        <p className={styles.answer}>{item?.question?.answerLabels}{' '}{item?.answer?.toLocaleString()}</p>
                                    }
                                </div>
                            )
                        } else if (item?.question?.answerType === 'multipleChoice') {
                            return (
                                <div className={styles.content} key={index}>
                                    <p>{item?.question?.questionText}</p>
                                    {item?.answer?.map((itemMultiple, indexMultiple)=> (
                                        <div key={indexMultiple} >
                                            <p className={styles.answer}>{indexMultiple +1}. {itemMultiple}</p>
                                        </div>
                                    ))}
                                </div>
                            )
                        } else if (item?.question?.answerType === 'booleanInput') {
                            return (
                                <div className={styles.content} key={index}>
                                    <p>{item?.question?.questionText}</p>
                                    <p className={styles.answer}>{item?.answer}</p>
                                </div>
                            )
                        } else if (item?.question?.answerType === 'multipleChoiceSingle') {
                            return (
                                <div className={styles.content} key={index}>
                                    <p>{item?.question?.questionText}</p>
                                    <p className={styles.answer}>{item?.answer}</p>
                                </div>
                            )
                        } else if (item?.question?.answerType === 'multipleQuestions') {
                            return (
                                <div className={styles.content} key={index}>
                                    <p>{item?.question?.questionText}</p>
                                    {Object.entries(item?.answer).map(([key, value]) => (
                                        <div key={key}>
                                            <p className='text-[12px] pt-[12px]'>{item?.question?.answerKeys[key]}</p>
                                            <p className={styles.answer}>RM {value?.toLocaleString()}</p>
                                        </div>
                                    ))}
                                </div>
                            )
                        } else if (item?.question?.answerType === 'date') {
                            return (
                                <div className={styles.content} key={index}>
                                    <p>{item?.question?.questionText}</p>
                                    <p className={styles.answer}>{formatDate(item?.answer)}</p>
                                </div>
                            )
                        } else {
                            return (
                                <></>
                            )
                        }
                    })}
                </div>
            </div>}
        </>
    )
}

export default AssessmentAnswerCard