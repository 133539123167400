import { useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { PageContainer } from '@ant-design/pro-layout'
import { inject } from 'mobx-react'
import Layout from '@components/cfp/layout/CfpLayout'
import { Progress, Tag, Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import CfpPlanList from '@components/cfp/plan/list/CfpPlanList'
import CfpCustomerDetail from '@components/cfp/customer/detail/CfpCustomerDetail'
import { cfpCustomerListingPath } from '@constants/routes'
import CfpCustomerReport from '@components/cfp/customer/report/CfpCustomerReport'
import CfpCustomerAssessmentAnswer from '@components/cfp/customer/report/assessment-report/CfpCustomerAssessmentAnswer'

const CfpCustomerDetailPage = ({ logger, services: { customerService } }) => {
    const { id } = useParams()
    const [customer, setCustomer] = useState(null)
    const [toReload, setToReload] = useState(false)

    const tabItems = [
        {
            key: 'PLAN',
            label: 'Plans',
            children: <CfpPlanList
                search={false}
                className='mt-3'
                customerId={id}
                defaultFilters={{
                    customerId: id
                }}
                renderColumns={(defaultColumns) => {
                    delete defaultColumns.customer

                    return Object.values(defaultColumns)
                }}
                addForm={{
                    defaultValues: {
                        customerId: id
                    },
                    selectedCustomerOption: {
                        value: id,
                        label: `${customer?.fullName} - ${customer?.email}`
                    },
                    disabledFields: ['customerId'],
                    onSuccessAdd: () => setToReload(!toReload)
                }}
            />
        },
        {
            key: 'ASSESSMENT_REPORT',
            label: 'Assessment Report',
            children: <CfpCustomerReport
                className='mt-3'
                id={id}
            />
        },
        {
            key: 'ASSESSMENT_ANSWER',
            label: 'Assessment Answer',
            children: <CfpCustomerAssessmentAnswer
                className='mt-3'
                customerId={id}
            />
        }
    ]

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: cfpCustomerListingPath,
                            breadcrumbName: 'Customers'
                        },
                        {
                            breadcrumbName: id
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title={id}
            >
                <CfpCustomerDetail
                    key={toReload}
                    className='bg-white p-5'
                    id={id}
                    onGetCustomerSuccess={(customer) => setCustomer(customer)}
                />

                <Tabs
                    className='bg-white p-5'
                    defaultActiveKey='PLAN'
                    items={tabItems}
                />
            </PageContainer>
        </Layout>
    )
}

export default inject('services', 'logger')(CfpCustomerDetailPage)
