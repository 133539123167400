import { Enumify } from 'enumify'

export default class AdminRole extends Enumify {
    value = null

    constructor (value = null) {
        super()
        this.value = value
    }

    static of (type) {
        return AdminRole.enumValueOf(type)
    }

    static for (type) {
        switch (type) {
            case 0: return AdminRole.SUPER_ADMIN
            case 1: return AdminRole.ADMIN
            case 2: return AdminRole.ADMIN_MARKETING
        }
        return null
    }
}

AdminRole.SUPER_ADMIN = new AdminRole(0)
AdminRole.ADMIN = new AdminRole(1)
AdminRole.ADMIN_MARKETING = new AdminRole(2)
AdminRole._ = AdminRole.closeEnum()
