export const listEventEndpointFromAdminPath = '/admin/event/list'
export const listEnrollmentFromAdminEndpointPath = '/admin/event/list-enrollment'
export const addEventFromAdminEndpointPath = '/admin/event/create'
export const updateEventFromAdminEndpointPath = '/admin/event/update'

class EventService {
    constructor (adminWebClient) {
        this.adminWebClient = adminWebClient
    }

    async listFromAdmin (params) {
        const res = await this.adminWebClient.get(listEventEndpointFromAdminPath, params)
        return res
    }

    async listEnrollmentFromAdmin (params) {
        const res = await this.adminWebClient.get(listEnrollmentFromAdminEndpointPath, params)
        return res
    }

    async addFromAdmin (params) {
        const res = await this.adminWebClient.post(addEventFromAdminEndpointPath, params)
        return res
    }

    async updateFromAdmin (params) {
        const res = await this.adminWebClient.post(updateEventFromAdminEndpointPath, params)
        return res
    }
}

export default EventService
