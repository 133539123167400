const listPurchaseFromAdminEndpointPath = '/admin/purchase/list'

class PurchaseService {
    constructor (adminWebClient) {
        this.adminWebClient = adminWebClient
    }

    async listFromAdmin (params) {
        const res = await this.adminWebClient.get(listPurchaseFromAdminEndpointPath, params)
        return res
    }
}

export default PurchaseService
