import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import PlanDetail from '@components/shared/plan/detail/PlanDetail'
import { Link } from 'react-router-dom'
import { cfpCustomerDetailPath } from '@constants/routes'
import { Button, Modal } from 'antd'
import CfpAddAppointmentModal from '@components/cfp/appointment/add/modal/CfpAddAppointmentModal'
import moment from 'moment-timezone'
import { ExclamationCircleTwoTone } from '@ant-design/icons'

const CfpPlanDetail = ({ logger, services, className, style, id, onSuccessAdd }) => {
    const { customerPlanService, appointmentService } = services
    const [loading, setLoading] = useState(true)
    const [plan, setPlan] = useState(null)
    const [newAppointmentFormProps, setNewAppointmentFormProps] = useState(null)

    useEffect(() => {
        getPlanDetail(id)
    }, [id])

    const getPlanDetail = async (id) => {
        try {
            setLoading(true)
            const res = await customerPlanService.getByIdFromCfp(id)

            if (res.ok) {
                const data = res.data
                setPlan(data)
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
            }
        } catch (e) {
            logger.error('[Failed Get Plan Detail]', e)
        } finally {
            setLoading(false)
        }
    }

    const addPending = async (planId) => {
        try {
            const res = await appointmentService.addPendingFromCfp({
                planId
            })

            if (res.ok && res.data) {
                Modal.success({
                    title: 'Success',
                    content: (
                        <p>Successfully add new pending appointment</p>
                    )
                })
                getPlanDetail(id)
                onSuccessAdd()
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
            }
        } catch (error) {
            logger.error('[Fail Add Pending Appointment]', error)
        }
    }

    return (
        <>
            <PlanDetail
                className={className}
                style={style}
                loading={loading}
                plan={plan}
                renderColumns={(defaultColumns) => {
                    defaultColumns.customer = {
                        ...defaultColumns.customer,
                        render: (text, record) => {
                            if (!record?.customer?.fullName) return '-'
                            return (
                                <Link to={cfpCustomerDetailPath(record.customerId)}>
                                    {record.customer.fullName}
                                </Link>
                            )
                        }
                    }

                    return Object.values(defaultColumns)
                }}
                extra={
                    <div className='flex gap-3'>
                        <Button
                            type='primary'
                            className='font-semibold'
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Are you sure to add new appointment for this plan?',
                                    icon: <ExclamationCircleTwoTone />,
                                    okText: 'Confirm',
                                    cancelText: 'Cancel',
                                    onOk: async () => {
                                        await addPending(plan.id)
                                    }
                                })
                            }}
                        >
                            Add Pending Appointment
                        </Button>

                        <Button
                            type='primary'
                            className='font-semibold'
                            onClick={() => {
                                setNewAppointmentFormProps({
                                    defaultValues: {
                                        planId: plan.id,
                                        timezone: moment.tz.guess()
                                    },
                                    onSuccessSubmit: () => {
                                        getPlanDetail(id)
                                        onSuccessAdd()
                                        setNewAppointmentFormProps(null)
                                    }
                                })
                            }}
                        >
                            Add Appointment
                        </Button>
                    </div>
                }
            />

            <CfpAddAppointmentModal
                form={newAppointmentFormProps}
                visible={!!newAppointmentFormProps}
                onCancel={() => setNewAppointmentFormProps(null)}
            />
        </>
    )
}

export default inject('logger', 'services')(CfpPlanDetail)
