import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import { PageContainer } from '@ant-design/pro-layout'
import Layout from '@components/admin/layout/AdminLayout'
import { useParams } from 'react-router-dom'
import { Modal, Skeleton } from 'antd'
import AdminUpdatePlanTypeForm from '@components/admin/plan-type/update/form/AdminUpdatePlanTypeForm'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import { adminPlanTypeListingPath } from '@constants/routes'

const AdminPlanTypeDetailPage = ({ config, logger, services: { planTypeService } }) => {
    const { slug } = useParams()
    const [loading, setLoading] = useState(true)
    const [planType, setPlanType] = useState(null)
    const [defaultValues, setDefaultValues] = useState(null)

    useEffect(() => {
        if (planType) {
            setDefaultValues(planType)
        }
    }, [planType])

    useEffect(() => {
        getPlanType(slug)
    }, [slug])

    const getPlanType = async (slug) => {
        try {
            setLoading(true)
            const res = await planTypeService.getFromAdmin({ slug })

            if (res.ok) {
                const data = res.data
                setPlanType(data)
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
            }
        } catch (e) {
            logger.error('[Failed Get Plan Type]', e)
        } finally {
            setLoading(false)
        }
    }

    const handleSuccessSubmit = () => {
        Modal.success({
            title: 'Success',
            content: (
                <p>Selected plan type updated successfully</p>
            )
        })
    }

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: adminPlanTypeListingPath,
                            breadcrumbName: 'Plan Types'
                        },
                        {
                            path: slug,
                            breadcrumbName: slug
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title='Update Plan Type'
            >
                {!loading && defaultValues &&
                <AdminUpdatePlanTypeForm
                    defaultValues={defaultValues}
                    onSuccessSubmit={handleSuccessSubmit}
                />}

                {(loading || !defaultValues) &&
                <div className='bg-white p-5'>
                    <Skeleton />
                </div>}
            </PageContainer>
        </Layout>
    )
}

export default inject('config', 'services', 'logger')(AdminPlanTypeDetailPage)
