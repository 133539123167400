import { useEffect } from 'react'
import { inject } from 'mobx-react'
import { Link, useNavigate } from 'react-router-dom'
import CfpSetPasswordFormWrapper from '@components/cfp/set-password/form/wrapper/CfpSetPasswordFormWrapper'

const CfpSetPasswordForm = ({ cfpStore, config }) => {
    const navigate = useNavigate()

    useEffect(() => {
        if (cfpStore?.initiated) {
            if (cfpStore.hasPassword) {
                navigate('/cfp/dashboard')
            }
        }
    }, [cfpStore?.initiated, cfpStore.isLogined])

    const handleSuccessSubmit = () => {
        navigate('/cfp/dashboard')
    }

    return (
        <CfpSetPasswordFormWrapper
            onSuccessSubmit={handleSuccessSubmit}
        />
    )
}

export default inject('services', 'cfpStore', 'config')(CfpSetPasswordForm)
