import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import AdminPrivateRoute from './AdminPrivateRoute'
import AdminRole from '@models/enum/AdminRole'
import AdminPlanListingPage from '@pages/admin/plans'
import AdminPlanDetailPage from '@pages/admin/plans/[id]'

const AdminPlanRoutes = (props) => {
    return (
        <Routes>
            <Route
                path='/'
                exact
                element={
                    <AdminPrivateRoute roles={[AdminRole.SUPER_ADMIN, AdminRole.ADMIN]}>
                        <AdminPlanListingPage />
                    </AdminPrivateRoute>
                }
            />
            <Route
                path='/:id'
                element={
                    <AdminPrivateRoute roles={[AdminRole.SUPER_ADMIN, AdminRole.ADMIN]}>
                        <AdminPlanDetailPage />
                    </AdminPrivateRoute>
                }
            />
        </Routes>
    )
}

export default inject('logger')(AdminPlanRoutes)
