const prepareApiParams = (params, sort, apiParams) => {
    for (const [key, value] of Object.entries(params)) {
        if (key === 'current') {
            apiParams.page = params[key]
        } else if (key === 'pageSize') {
            apiParams.perPage = params[key]
        } else {
            apiParams[key] = value
        }
    }

    const sortDesc = []
    const sortAsc = []
    for (const [key, value] of Object.entries(sort)) {
        if (value === 'descend') {
            sortDesc.push(key)
        } else if (value === 'ascend') {
            sortAsc.push(key)
        }
    }

    if (sortDesc.length > 0) apiParams.sortDesc = sortDesc.join(',')
    if (sortAsc.length > 0) apiParams.sortAsc = sortAsc.join(',')
}

export default prepareApiParams
