import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import prepareApiParams from '@helpers/prepareApiParams'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import PlanList from '@components/shared/plan/list/PlanList'
import { Link } from 'react-router-dom'
import { adminCustomerDetailPath, adminCustomerPlanDetailPath } from '@constants/routes'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import AdminAddPlanModal from '../add/modal/AdminAddPlanModal'
import SelectCustomerInput from '@components/shared/customer/input/select/SelectCustomerInput'

const AdminPlanList = ({ logger, services, className, style, defaultFilters, addForm, renderColumns }) => {
    const tableActionRef = useRef(null)
    const { customerPlanService } = services
    const [filters, setFilters] = useState(defaultFilters)
    const [toShowAddPlan, setToShowAddPlan] = useState(false)
    const [selectedCustomerId, setSelectedCustomerId] = useState(null)

    useEffect(() => {
    }, [])

    useEffect(() => {
        setFilters(defaultFilters)
        tableActionRef.current?.reset()
        tableActionRef.current?.reload()
    }, [defaultFilters])

    const handleRequest = async (params, sort) => {
        try {
            const apiParams = {
                ...filters
            }
            prepareApiParams(params, sort, apiParams)
            const res = await customerPlanService.listFromAdmin(apiParams)

            if (res.ok) {
                const data = res.data
                return {
                    data: data.data,
                    success: res.ok,
                    total: data.lastPage * data.perPage
                }
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                return {
                    data: null,
                    success: res.ok,
                    total: 0
                }
            }
        } catch (e) {
            logger.error('[Failed List Plan Type]', e)
        }
    }

    return (
        <>
            <PlanList
                actionRef={tableActionRef}
                className={className}
                style={style}
                handleRequest={handleRequest}
                renderColumns={(defaultColumns) => {
                    defaultColumns.customer = {
                        ...defaultColumns.customer,
                        search: true,
                        renderFormItem: (item, config, form) => {
                            return (
                                <SelectCustomerInput
                                    by='ADMIN'
                                    className='w-full'
                                    onChange={(option) => {
                                        form.setFieldValue(item.dataIndex, option.value)
                                        setFilters({
                                            ...filters,
                                            [item.dataIndex]: option.value
                                        })
                                        setSelectedCustomerId(option)
                                    }}
                                    value={selectedCustomerId}
                                />
                            )
                        },
                        render: (text, record, _, action) => {
                            return (
                                <Link to={adminCustomerDetailPath(record.customerId)} key='view'>
                                    {record.customer?.email}
                                </Link>
                            )
                        }
                    }

                    defaultColumns.action = {
                        ...defaultColumns.action,
                        render: (text, record, _, action) => {
                            const actionComponents = [
                                <Link to={adminCustomerPlanDetailPath(record.id)} key='view'>
                                    View
                                </Link>
                            ]
                            return actionComponents
                        }
                    }

                    if (renderColumns) {
                        return renderColumns(defaultColumns)
                    }

                    return Object.values(defaultColumns)
                }}
                renderToolbar={(defaultToolbars) => {
                    return [
                        ...defaultToolbars,
                        <Button
                            type='primary'
                            key='primary'
                            onClick={() => {
                                setToShowAddPlan(true)
                            }}
                        >
                            <PlusOutlined /> Add
                        </Button>
                    ]
                }}
                onReset={() => {
                    setFilters(defaultFilters)
                    setSelectedCustomerId(null)
                }}
            />

            <AdminAddPlanModal
                visible={toShowAddPlan}
                onCancel={() => setToShowAddPlan(false)}
                form={{
                    ...addForm,
                    onSuccessSubmit: () => {
                        setToShowAddPlan(false)
                        tableActionRef.current.reload()
                        addForm?.onSuccessAdd()
                    }
                }}
            />
        </>
    )
}

export default inject('logger', 'services')(AdminPlanList)
