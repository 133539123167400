const listCustomerFromAdminEndpointPath = '/admin/customer/list'
const createCustomerFromAdminEndpointPath = '/admin/customer/create'
const updateCustomerFromAdminEndpointPath = '/admin/customer/update'
const getCustomerFromAdminEndpointPath = '/admin/customer/detail'
const getCustomerFromCfpEndpointPath = '/cfp/customer/detail'
const listCustomerFromCfpEndpointPath = '/cfp/customer/list'
const createCustomerFromCfpEndpointPath = '/cfp/customer/create'
const updateCustomerFromCfpEndpointPath = '/cfp/customer/update'
const getCustomerReportFromAdminEndpointPath = '/admin/customer/full-assessment-result'
const getCustomerReportFromCfpEndpointPath = '/cfp/customer/full-assessment-result'
const exportCsvByCfpEndpointPath = '/cfp/customer/export-csv'
const exportCsvByAdminEndpointPath = '/admin/customer/export-csv'
const getCustomerAssessmentIdEndpointPath = '/admin/customer-assessment/calculated-value'
const getCustomerAssessmentReportEndpointPath ='/admin/customer-assessment/answers'


class CustomerService {
    constructor (adminWebClient, cfpWebClient) {
        this.adminWebClient = adminWebClient
        this.cfpWebClient = cfpWebClient
    }

    async listFromAdmin (params) {
        const res = await this.adminWebClient.get(listCustomerFromAdminEndpointPath, params)
        return res
    }

    async addFromAdmin (params) {
        const res = await this.adminWebClient.post(createCustomerFromAdminEndpointPath, params)
        return res
    }

    async updateFromAdmin (params) {
        const res = await this.adminWebClient.post(updateCustomerFromAdminEndpointPath, params)
        return res
    }

    async getFromAdmin (params) {
        const res = await this.adminWebClient.get(getCustomerFromAdminEndpointPath, params)
        return res
    }
    
    async getReportFromAdmin (params) {
        const res = await this.adminWebClient.get(getCustomerReportFromAdminEndpointPath, params)
        return res
    }

    async exportCsvFromAdmin (params) {
        const res = await this.adminWebClient.get(exportCsvByAdminEndpointPath, params)
        return res
    }

    async getReportFromCfp (params) {
        const res = await this.cfpWebClient.get(getCustomerReportFromCfpEndpointPath, params)
        return res
    }
    
    async getFromCfp (params) {
        const res = await this.cfpWebClient.get(getCustomerFromCfpEndpointPath, params)
        return res
    }

    async listFromCfp (params) {
        const res = await this.cfpWebClient.get(listCustomerFromCfpEndpointPath, params)
        return res
    }

    async addFromCfp (params) {
        const res = await this.cfpWebClient.post(createCustomerFromCfpEndpointPath, params)
        return res
    }

    async updateFromCfp (params) {
        const res = await this.cfpWebClient.post(updateCustomerFromCfpEndpointPath, params)
        return res
    }

    async exportCsvFromCfp (params) {
        const res = await this.cfpWebClient.get(exportCsvByCfpEndpointPath, params)
        return res
    }

    async getCustomerAssessmentId (params) {
        const res = await this.cfpWebClient.post(getCustomerAssessmentIdEndpointPath, params)
        return res
    }

    async getCustomerAssessmentReport (params) {
        const res = await this.cfpWebClient.post(getCustomerAssessmentReportEndpointPath, params)
        return res
    }
}

export default CustomerService
