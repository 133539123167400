import { useEffect, useState } from 'react'
import _ from 'lodash'
import { PageContainer } from '@ant-design/pro-layout'
import { inject } from 'mobx-react'
import CfpLayout from '@components/cfp/layout/CfpLayout'
import { useParams } from 'react-router-dom'
import { cfpAppointmentListingPath } from '@constants/routes'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import { Tabs } from 'antd'
import CfpAppointmentDetail from '@components/cfp/appointment/detail/CfpAppointmentDetail'
import CfpAppointmentAttachmentList from '@components/cfp/appointment/attachment/list/CfpAppointmentAttachmentList'

const CfpAppointmentDetailPage = ({ logger, services: { appointmentService } }) => {
    const { id } = useParams()
    const [toRerenderAppointmentList, setToRerenderAppointmentList] = useState(true)
    const [loading, setLoading] = useState(true)
    const [appointment, setAppointment] = useState(null)

    const tabItems = [
        {
            key: 'ATTACHMENT',
            label: 'Attachments',
            children: <CfpAppointmentAttachmentList
                appointment={appointment}
            />
        }
    ]

    useEffect(() => {
        if (toRerenderAppointmentList === true) {
            getAppointmentDetail(id)
        }
    }, [id, toRerenderAppointmentList])

    const getAppointmentDetail = async (id) => {
        try {
            setLoading(true)
            const res = await appointmentService.listFromCfpById(id)

            if (res.ok) {
                const data = res.data
                setAppointment(data)
                setToRerenderAppointmentList(false)
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
            }
        } catch (e) {
            logger.error('[Failed Get Plan Detail]', e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <CfpLayout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: cfpAppointmentListingPath,
                            breadcrumbName: 'Appointment'
                        },
                        {
                            breadcrumbName: id
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title={id}
            >
                <CfpAppointmentDetail
                    className='bg-white p-5' s
                    appointment={appointment}
                    loading={loading}
                    onSuccessCancel={() => { setToRerenderAppointmentList(true) }}
                    onSuccessComplete={() => { setToRerenderAppointmentList(true) }}
                />

                <Tabs
                    className='bg-white p-5'
                    defaultActiveKey='APPOINTMENT'
                    items={tabItems}
                />
            </PageContainer>
        </CfpLayout>
    )
}

export default inject('services', 'logger')(CfpAppointmentDetailPage)
