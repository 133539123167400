import { useEffect, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import cn from 'classnames'
import Modal from 'antd/lib/modal/Modal'
import UpdateCfpForm from '../form/UpdateCfpForm'
import { Card } from 'antd'
import CfpProfileBannerUpload from '../form/profile-banner-upload/CfpProfileBannerUpload'
import CfpProfilePictureUpload from '../form/profile-picture-upload/CfpProfilePictureUpload'

const UpdateCfpModal = (props) => {
    return (
        <Modal
            className={cn(props.className)}
            title='Update CFP'
            open={props.visible}
            onCancel={props.onCancel}
            footer={null}
        >
            {/* <div className="grid grid-cols-2 gap-3 mb-3">
                <Card
                    title='Profile Picture'
                    className='col-span-1 text-center'
                >
                    <CfpProfilePictureUpload />
                </Card>
                <Card
                    className='col-span-1 text-center'
                    title='Profile Banner'
                >
                    <CfpProfileBannerUpload />
                </Card>
            </div> */}
            <Card title='Profile'>
                <UpdateCfpForm
                    defaultValues={props.form.defaultValues}
                    onSuccessSubmit={props.onSuccessSubmit}
                />
            </Card>
        </Modal>
    )
}

export default inject('services')(UpdateCfpModal)
