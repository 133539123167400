import moment from 'moment'
import { string, object, number, array, boolean } from 'yup'

const schema = object().shape({
    type: string()
        .oneOf(['CONVENTIONAL', 'ISLAMIC'])
        .required(),
    id: string().required(),
    name: string().required(),
    introSummary: string().required('Summary is required'),
    description: string()
        .required('Description is required'),
    goalMapperLinks: array()
        .of(
            object().shape({
                link: string()
                    .url('Must be a url')
                    .required('Link is required')
                    .max(255, 'Must be less than 255 characters'),
                type: string()
                    .required('Type is required')
                    .max(255, 'Must be less than 255 characters')
            })
        )
        .required('Input is required'),
    mobilePrefix: string()
        .min(1)
        .max(3)
        .nullable()
        .matches(/^\d+$/, 'Invalid mobile prefix'),
    mobileNumber: string()
        .nullable()
        .matches(/^\d+$/, 'Invalid mobile number'),
    licenseNumber: string()
        .nullable(),
    settings: object().shape({
        appointment: object().shape({
            defaultStartTime: string()
                .required()
                .test(
                    'is-before-end-time',
                    'Start time must be before end time',
                    (value, context) => {
                        return moment(value).isBefore(
                            context.parent.defaultEndTime
                        )
                    }
                ),
            defaultEndTime: string()
                .required()
                .test(
                    'is-after-start-time',
                    'End time must be after start time',
                    (value, context) => {
                        return moment(value).isAfter(
                            context.parent.defaultStartTime
                        )
                    }
                ),
            gap: object().shape({
                duration: number()
                    .required(),
                durationUnit: string()
                    .nullable()
                    .oneOf(['MINUTE'])
                    .required()
            }),
            timezone: string().oneOf(['Asia/Kuala_Lumpur']).required()
        })
    }),
    isPublic: boolean(),
    sortWeight: number()
        .nullable()
})

export default schema
