import { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Spin, Modal } from 'antd'
import qs from 'qs'
import { inject } from 'mobx-react'

const CompleteGoogleAuthPage = (props) => {
    const logger = props.logger
    const cfpStore = props.cfpStore
    const location = useLocation()
    const navigate = useNavigate()
    const [completing, setCompleting] = useState(true)
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    useEffect(() => {
        if (cfpStore?.initiated) {
            if (cfpStore?.accessToken && query?.code && query?.state) {
                const state = JSON.parse(query.state)
                completeGoogleAuth(query.code, state?.cfpId)
            }
        }
    }, [cfpStore?.initiated, cfpStore.isLogined])

    const completeGoogleAuth = async (code, cfpId) => {
        try {
            setCompleting(true)
            const res = await props.cfpStore.completeGoogleAuth(code, cfpId)
            if (res.ok) {
                if (res.data.success) {
                    Modal.success({
                        title: 'Completed Google Auth',
                        content: (
                            <p>
                                You have successfully completed the Google Auth for us to access your calendar(s).
                                So now your Google Calendar(s) are sync with us.
                            </p>
                        ),
                        onOk: () => {
                            window.location.href = '/#/cfp/dashboard'
                        }
                    })
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <ul>
                                {Object.values(errors).map((message, index) =>
                                    <li key={index}>
                                        {message}
                                    </li>
                                )}
                            </ul>
                        ),
                        onOk: () => {
                            window.location.href = '/#/cfp/login'
                        }
                    })
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <ul>
                                <li>Something went wrong</li>
                            </ul>
                        ),
                        onOk: () => {
                            window.location.href = '/#/cfp/login'
                        }
                    })
                }
            }
        } catch (error) {
            logger.error('[Failed Complete Google Auth]', error)
        } finally {
            setTimeout(() => {
                setCompleting(false)
            }, 2000)
        }
    }

    if (!query?.code || !query?.state) {
        window.location.href='/#/cfp/dashboard'
        return (<div />)
    }

    return (
        <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
            {completing &&
            <>
                <Spin size='large' />
                <p className='text-lg mt-2 text-center text-blue-400'>Completing Google Auth ...</p>
            </>}
        </div>
    )
}

export default inject('cfpStore', 'logger')(CompleteGoogleAuthPage)
