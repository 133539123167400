import React, { useMemo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import CfpSetPasswordForm from '../CfpSetPasswordForm'
import styles from './CfpSetPasswordFormWrapper.module.scss'

const CfpSetPasswordFormWrapper = props => {
    const config = props.config

    return (
        <div className={cn(styles.wrapper, props.className)} style={props.style}>
            <h1>Set Password</h1>

            <CfpSetPasswordForm
                className='my-5 w-full'
                successMessage={props.successMessage}
                onSuccessSubmit={props.onSuccessSubmit}
            />
        </div>
    )
}

CfpSetPasswordFormWrapper.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onSuccessSubmit: PropTypes.func
}

CfpSetPasswordFormWrapper.defaultProps = {
    onSuccessSubmit: () => {}
}

export default inject('config')(CfpSetPasswordFormWrapper)
