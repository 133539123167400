import showApiErrorMessage from '@helpers/showApiErrorMessage'

const usePlanTypeVariantOptionsLoader = (props) => {
    const { logger, planTypeService, planTypeId, by } = props

    const methodNames = {
        'CFP': 'listVariantFromCfp',
        'ADMIN': 'listVariantFromAdmin'
    }

    const loadOptions = async (inputValue, loadedOptions) => {
        try {
            const nextPage = (loadedOptions) ? (loadedOptions.length / 20) + 1 : 1

            const methodName = methodNames[by]
            const res = await planTypeService.listVariantFromAdmin({
                id: planTypeId,
                page: nextPage
            })

            if (res.ok && res.data) {
                const data = res.data
                const variants = data.data
                const options = []
                
                variants.forEach((variant, index) => {
                    const option = {
                        label: `${variant.type}`,
                        value: `${variant.id}`
                    }
                    options.push(option)
                })

                const hasMore = (data.lastPage > data.page)

                return {
                    options,
                    hasMore
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                throw new Error(errors)
            }
        } catch (error) {
            logger.error('[Failed Load Plan Type Variant Options]', error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    return { loadOptions }
}

export default (usePlanTypeVariantOptionsLoader)