import { Enumify } from 'enumify'

export default class Gender extends Enumify {
    value = null

    constructor (value = null, label = null) {
        super()
        this.value = value
        this.label = label
    }

    static of (type) {
        return Gender.enumValueOf(type)
    }

    static for (type) {
        switch (type) {
            case 0: return Gender.MALE
            case 1: return Gender.FEMALE
        }
        return null
    }
}

Gender.MALE = new Gender(0, 'Male')
Gender.FEMALE = new Gender(1, 'Female')
Gender._ = Gender.closeEnum()
