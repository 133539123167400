import { useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { PageContainer } from '@ant-design/pro-layout'
import { inject } from 'mobx-react'
import Layout from '@components/cfp/layout/CfpLayout'
import { Link, useParams } from 'react-router-dom'
import { cfpCustomerPlanListingPath } from '@constants/routes'
import CfpPlanDetail from '@components/cfp/plan/detail/CfpPlanDetail'
import { Button, Tabs } from 'antd'
import CfpAppointmentList from '@components/cfp/appointment/list/CfpAppointmentList'

const CfpPlanDetailPage = ({ logger, services: { customerService } }) => {
    const { id } = useParams()
    const [toRerenderAppointmentList, setToRerenderAppointmentList] = useState(false)

    const tabItems = [
        {
            key: 'APPOINTMENT',
            label: 'Appointments',
            children: <CfpAppointmentList
                search={false}
                key={toRerenderAppointmentList}
                defaultFilters={{
                    planId: id
                }}
                renderColumns={(defaultColumns) => {
                    delete defaultColumns.customerEmail
                    delete defaultColumns.customerFullName

                    return Object.values(defaultColumns)
                }}
                onSuccessComplete={() => setToRerenderAppointmentList(!toRerenderAppointmentList)}
                onSuccessCancel={() => setToRerenderAppointmentList(!toRerenderAppointmentList)}
            />
        }
    ]

    const reload = () => {
        setToRerenderAppointmentList(!toRerenderAppointmentList)
    }

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: cfpCustomerPlanListingPath,
                            breadcrumbName: 'Plans'
                        },
                        {
                            breadcrumbName: id
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title={id}
            >
                <CfpPlanDetail
                    key={!toRerenderAppointmentList}
                    className='bg-white p-5'
                    id={id}
                    onSuccessAdd={reload}
                    onSuccessSelectCfp={reload}
                />

                <Tabs
                    className='bg-white p-5'
                    defaultActiveKey='APPOINTMENT'
                    items={tabItems}
                />
            </PageContainer>
        </Layout>
    )
}

export default inject('services', 'logger')(CfpPlanDetailPage)
