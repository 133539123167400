const listCustomerPlanFromAdminEndpointPath = '/admin/customer-plan/list'
const listCustomerPlanFromCfpEndpointPath = '/cfp/customer-plan/list'
const createCustomerPlanFromAdminEndpointPath = '/admin/customer-plan/create'
const cancelCustomerPlanFromAdminEndpointPath = '/admin/customer-plan/cancel'
const getCustomerPlanFromAdminEndpointPath = '/admin/customer-plan/detail'
const selectCfpFromAdminEndpointPath = '/admin/customer-plan/select-cfp'
const getCustomerPlanFromCfpEndpointPath = '/cfp/customer-plan/detail'

class CustomerPlanService {
    constructor (adminWebClient, cfpWebClient) {
        this.adminWebClient = adminWebClient
        this.cfpWebClient = cfpWebClient
    }

    async listFromAdmin (params) {
        const res = await this.adminWebClient.get(listCustomerPlanFromAdminEndpointPath, params)
        return res
    }

    async getByIdFromAdmin (id) {
        const res = await this.adminWebClient.get(getCustomerPlanFromAdminEndpointPath, { id })
        return res
    }

    async selectCfpFromAdmin (data) {
        const res = await this.adminWebClient.post(selectCfpFromAdminEndpointPath, data)
        return res
    }

    async addFromAdmin (params) {
        const res = await this.adminWebClient.post(createCustomerPlanFromAdminEndpointPath, params)
        return res
    }

    async cancelFromAdmin (params) {
        const res = await this.adminWebClient.post(cancelCustomerPlanFromAdminEndpointPath, params)
        return res
    }

    async listFromCfp (params) {
        const res = await this.cfpWebClient.get(listCustomerPlanFromCfpEndpointPath, params)
        return res
    }

    async getByIdFromCfp (id) {
        const res = await this.cfpWebClient.get(getCustomerPlanFromCfpEndpointPath, { id })
        return res
    }

}

export default CustomerPlanService
