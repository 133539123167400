import { useState, useEffect } from 'react'
import { inject } from 'mobx-react'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import CfpPrivateRoute from './CfpPrivateRoute'
import CfpPublicRoute from './CfpPublicRoute'
import LoginPage from '@pages/cfp/login'
import CfpEmailVerificationPage from '@pages/cfp/email-verification'
import CfpSetPasswordPage from '@pages/cfp/set-password'
import CfpProfilePage from '@pages/cfp/profile'
import CompleteGoogleAuthPage from '@pages/cfp/complete-google-auth'
import DashboardPage from '@pages/cfp/dashboard'
import AppointmentListingPage from '@pages/cfp/appointments'
import CfpCustomerRoutes from './CfpCustomerRoutes'
import CfpPlanRoutes from './CfpPlanRoutes'
import CfpAppointmentRoutes from './CfpAppointmentRoutes'

const CfpRoutes = (props) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    return (
        <Routes>
            <Route
                path='/'
                element={
                    <Navigate to='login' replace />
                }
            />

            <Route
                exact
                path='login'
                element={
                    <CfpPublicRoute restricted>
                        <LoginPage />
                    </CfpPublicRoute>
                }
            />

            <Route
                path='email-verification'
                element={
                    <CfpPublicRoute>
                        <CfpEmailVerificationPage />
                    </CfpPublicRoute>
                }
            />

            <Route
                path='set-password'
                element={
                    <CfpPrivateRoute>
                        <CfpSetPasswordPage />
                    </CfpPrivateRoute>
                }
            />

            <Route
                path='profile'
                element={
                    <CfpPrivateRoute>
                        <CfpProfilePage />
                    </CfpPrivateRoute>
                }
            />

            <Route
                path='complete-google-auth'
                element={
                    <CfpPublicRoute>
                        <CompleteGoogleAuthPage />
                    </CfpPublicRoute>
                }
            />

            <Route
                path='dashboard'
                element={
                    <CfpPrivateRoute>
                        <DashboardPage />
                    </CfpPrivateRoute>
                }
            />

            <Route
                path='customers/*'
                element={
                    <CfpCustomerRoutes />
                }
            />

            <Route
                path='plans/*'
                element={
                    <CfpPlanRoutes />
                }
            />

            <Route
                path='appointments/*'
                element={
                    <CfpAppointmentRoutes />
                }
            />
        </Routes>
    )
}

export default (CfpRoutes)
