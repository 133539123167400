import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { inject } from 'mobx-react'
import moment from 'moment'
import { Form, Select, Input, TimePicker, InputNumber, DatePicker, Modal } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import SubmitButton from '@components/common/button/submit/SubmitButton'
import DiscountType from '@models/enum/DiscountType'
import PromoCodeType from '@models/enum/PromoCodeType'
import TextArea from 'antd/lib/input/TextArea'
import schema from './schema'

const AdminAddPromoForm = (props) => {
    const config = props.config
    const logger = props.logger
    const promoCodeService = props.services.promoCodeService
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const { handleSubmit, setError, control, reset, watch, setValue, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    const disabledExpiredDate = (current) => {
        return current && current.isBefore(moment(), 'day')
    }

    const disabledExpiredTime = (now) => {
        return {
            disabledHours: () => {
                const hours = []
                if (moment(now).isSame(moment(), 'day')) {
                    for (let i = 0; i < moment().hour(); i += 1) hours.push(i)
                }
                return hours
            },
            disabledMinutes: (selectedHour) => {
                const minutes = []
                if (moment(now).isSame(moment(), 'day') && selectedHour === moment().hour()) {
                    for (let i = moment().minute(); i >= 0; i -= 1) minutes.push(i)
                }
                return minutes
            }
        }
    }

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            const params = {
                ...data,
                type: PromoCodeType.PRODUCT.enumKey
            }

            if (data.expiredAt) {
                params.expiredAt = moment(data.expiredAt).toDate().toISOString().slice(0, 19)
            }

            const res = await promoCodeService.addFromAdmin(params)

            if (res.ok && res.data) {
                reset()
                props.onSuccessSubmit(res.data)
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (error) {
            logger.error('[Fail Submit Add Event Form', error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            <Form.Item
                className='mb-4'
                label='Code'
                validateStatus={(errors?.code?.message) ? 'error' : '-'}
                help={errors?.code?.message}
            >
                <Controller
                    name='code'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            placeholder='Promo Code'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Description'
                validateStatus={(errors?.description?.message) ? 'error' : '-'}
                help={errors?.description?.message}
            >
                <Controller
                    name='description'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <TextArea
                            ref={ref}
                            placeholder='Description'
                            onChange={onChange}
                            value={value}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Discount Type'
                validateStatus={(errors?.discountType?.message) ? 'error' : '-'}
                help={errors?.discountType?.message}
            >
                <Controller
                    name='discountType'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Select
                            ref={ref}
                            placeholder='Discount Type'
                            options={[
                                { label: DiscountType.PERCENT.label, value: DiscountType.PERCENT.enumKey },
                                { label: DiscountType.PRICE.label, value: DiscountType.PRICE.enumKey }
                            ]}
                            onChange={(e) => {
                                onChange(e)
                            }}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Discount Value'
                validateStatus={(errors?.discountValue?.message) ? 'error' : '-'}
                help={errors?.discountValue?.message}
            >
                <Controller
                    name='discountValue'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            placeholder='Promo Code Discount Value'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <div className='mb-4 grid grid-cols-12 gap-4'>

                <Form.Item
                    className='mb-0 col-span-6'
                    label='Expired Time'
                    validateStatus={(errors?.expiredAt?.message) ? 'error' : '-'}
                    help={errors?.expiredAt?.message}
                >
                    <Controller
                        name='expiredAt'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <DatePicker
                                className='w-full'
                                placeholder='Select Expired Time'
                                format='YYYY-MM-DD hh:mm A'
                                showTime
                                disabledDate={disabledExpiredDate}
                                disabledTime={disabledExpiredTime}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>
            </div>

            <SubmitButton className='mt-1' loading={submitting}>
                Submit
            </SubmitButton>
        </Form>
    )
}

export default inject('services', 'config')(AdminAddPromoForm)