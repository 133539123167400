import { Enumify } from 'enumify'

export default class Race extends Enumify {
    value = null

    constructor (value = null, label = null) {
        super()
        this.value = value
        this.label = label
    }

    static of (type) {
        return Race.enumValueOf(type)
    }

    static for (type) {
        switch (type) {
            case 0: return Race.MALAY
            case 1: return Race.CHINESE
            case 2: return Race.INDIAN
            case 3: return Race.OTHERS
            case 4: return Race.FOREIGNER
        }
        return null
    }
}

Race.MALAY = new Race(0, 'Malay')
Race.CHINESE = new Race(1, 'Chinese')
Race.INDIAN = new Race(2, 'Indian')
Race.OTHERS = new Race(3, 'Others')
Race.FOREIGNER = new Race(4, 'Foreigner')
Race._ = Race.closeEnum()
