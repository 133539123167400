import Layout from '@components/cfp/layout/CfpLayout'
import { PageContainer } from '@ant-design/pro-layout'
import { Card, Modal, Typography } from 'antd'
import { inject, observer } from 'mobx-react'
import CfpProfileUpdateForm from '@components/cfp/profile/update/form/CfpProfileUpdateForm'
import { useMemo } from 'react'
import moment from 'moment/moment'
import CfpChangePasswordForm from '@components/cfp/change-password/form/CfpChangePasswordForm'
import CfpProfilePictureUpload from '@components/cfp/profile/update/profile-picture-upload/CfpProfilePictureUpload'
import CfpProfileBannerUpload from '@components/cfp/profile/update/profile-banner-upload/CfpProfileBannerUpload'

const { Paragraph } = Typography

const CfpProfilePage = (props) => {
    const cfpStore = props.cfpStore
    const config = props.config

    const updateFormDefaultValues = useMemo(() => {
        const temp = {
            type: cfpStore.type,
            name: cfpStore.fullName,
            email: cfpStore.email,
            settings: cfpStore.settings.toJSON(),
            goalMapperLinks: cfpStore.goalMapperLinks,
            introSummary: cfpStore.introSummary,
            description: cfpStore.description,
            mobilePrefix: cfpStore.mobilePrefix,
            mobileNumber: cfpStore.mobileNumber,
            licenseNumber: cfpStore.licenseNumber
        }
        const defaultValues = JSON.parse(JSON.stringify(temp))

        if (defaultValues?.settings?.appointment?.defaultStartTime) {
            defaultValues.settings.appointment.defaultStartTime = moment(defaultValues.settings.appointment.defaultStartTime, 'HH:mm')
        }

        if (defaultValues?.settings?.appointment?.defaultEndTime) {
            defaultValues.settings.appointment.defaultEndTime = moment(defaultValues.settings.appointment.defaultEndTime, 'HH:mm')
        }

        return defaultValues
    }, [cfpStore])

    const uploadProfilePictureDefaultValues = useMemo(() => {
        const temp = {
            id: cfpStore.id,
            profilePictureUrl: cfpStore.profilePictureUrl

        }
        const defaultValues = JSON.parse(JSON.stringify(temp))

        return defaultValues
    }, [cfpStore])

    const uploadProfileBannerDefaultValues = useMemo(() => {
        const temp = {
            id: cfpStore.id,
            profileBannerUrl: cfpStore.profileBannerUrl

        }
        const defaultValues = JSON.parse(JSON.stringify(temp))

        return defaultValues
    }, [cfpStore])

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            breadcrumbName: 'Profile'
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title='Profile'
            >
                <div className='mb-4 grid grid-cols-12 gap-4'>
                    <div className='col-span-6'>
                        <Card
                            title='Profile Picture'
                        >
                            <CfpProfilePictureUpload defaultValues={uploadProfilePictureDefaultValues} />
                        </Card>
                        <Card
                            className='mt-4'
                            title='Profile Banner'
                        >
                            <CfpProfileBannerUpload defaultValues={uploadProfileBannerDefaultValues} />
                        </Card>
                        <Card
                            className='mt-4'
                            title='My Profile'
                        >
                            <CfpProfileUpdateForm
                                defaultValues={updateFormDefaultValues}
                                onSuccessSubmit={() => {
                                    Modal.success({
                                        title: 'Profile Update Success',
                                        content: (
                                            <p>
                                                You have successfully update your profile
                                            </p>
                                        )
                                    })
                                }}
                            />
                        </Card>
                    </div>
                    <div className='col-span-6'>
                        {/* <Card
                            title='Appointment Invitation Link'
                        >
                            <Paragraph
                                copyable={{
                                    icon: [
                                        <div key='copy'>Copy</div>,
                                        <div key='copied'>Copied</div>
                                    ]
                                }}
                            >
                                {`${config.app.productUrl}/customer/appointment/create?cfpId=${cfpStore.id}`}
                            </Paragraph>
                        </Card> */}

                        <Card
                            title='Change Password'
                        >
                            <CfpChangePasswordForm
                                onSuccessSubmit={() => {
                                    Modal.success({
                                        title: 'Password Changed',
                                        content: (
                                            <p>
                                                You have successfully change your password
                                            </p>
                                        )
                                    })
                                }}
                            />
                        </Card>
                    </div>
                </div>
            </PageContainer>
        </Layout>
    )
}

export default inject('cfpStore', 'config')(observer(CfpProfilePage))
