import CustomerType from '@models/enum/CustomerType'
import Gender from '@models/enum/Gender'
import PlanType from '@models/enum/PlanType'
import Race from '@models/enum/Race'
import moment from 'moment'
import { string, object, ref } from 'yup'

const schema = object().shape({
    type: string()
        .oneOf([CustomerType.B2B.enumKey, CustomerType.B2C.enumKey])
        .required(),
    fullName: string()
        .required(),
    icNumber: string()
        .matches(/^\d{12}$/, 'IC number must be 12 digit number')
        .transform((value, original) => (original === '' ? null : value))
        .nullable(),
    birthDate: string()
        .nullable()
        .test(
            'is-valid',
            'Invalid datetime format',
            (value, context) => {
                if (value === null) {
                    return true
                }
                return moment(value).isValid()
            }
        ),
    gender: string()
        .required()
        .oneOf([Gender.MALE.enumKey, Gender.FEMALE.enumKey]),
    mobilePrefix: string()
        .min(1)
        .max(3)
        .nullable()
        .matches(/^\d+$/, 'Invalid mobile prefix'),
    mobileNumber: string()
        .nullable()
        .matches(/^\d+$/, 'Invalid mobile number'),
    race: string()
        .oneOf([
            Race.MALAY.enumKey,
            Race.CHINESE.enumKey,
            Race.INDIAN.enumKey,
            Race.OTHERS.enumKey,
            Race.FOREIGNER.enumKey
        ])
        .nullable(),
    monthlyIncome: string()
        .nullable()
        .matches(/^\d+$/, 'Invalid monthly income'),
    otherRemarks: string()
        .nullable()
})

export default schema
