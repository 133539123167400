import { create } from 'apisauce'
import qs from 'qs'

class WebClient {
    httpClient = null
    authToken = null
    refreshToken = null
    checkToRetryHandler = null
    refreshTokenHandler = null
    refreshTokenExpiredHandler = null
    updateTokenHandler = null
    serverErrorHandler = null
    timeoutErrorHandler = null
    connectionErrorHandler = null
    networkErrorHandler = null
    cancelErrorHandler = null
    logger = null
    toRetry = true

    constructor (options, logger, toRetry = true) {
        this.logger = logger
        this.toRetry = toRetry
        this.httpClient = create(options)
        this.httpClient.addRequestTransform(this.requestTransformFunc)
        this.httpClient.addAsyncResponseTransform(this.asyncResponseTransform)
    }

    requestTransformFunc = async (request) => {
        if (this.authToken) {
            request.headers.Authorization = 'Bearer ' + this.authToken
        }

        const isRequestDataObject = typeof request.data === 'object'

        if (request.method?.toLowerCase() === 'post' && request.headers['Content-Type'] === 'application/x-www-form-urlencoded' && isRequestDataObject) {
            request.data = qs.stringify(request.data)
        } else if (request.method?.toLowerCase() === 'put' && request.headers['Content-Type'] === 'application/x-www-form-urlencoded' && isRequestDataObject) {
            request.data = qs.stringify(request.data)
        }

        if (request.method?.toLowerCase() === 'get') {
            request.headers['Content-Type'] = null
        }

        this.logger.debug('[Final Request]', request)

        return request
    }

    asyncResponseTransform = async (response) => {
        this.logger.debug('[Transform Response]', response)

        let toRetry = this.toRetry
        if (this.toRetry && this.checkToRetryHandler) {
            toRetry = this.checkToRetryHandler(response)
        }

        const isRefreshTokenExpired = !response.ok && response.data?.error?.tokenStatus.includes('Refresh token is expired')
        const isRefreshTokenInvalid = !response.ok && response.data?.error?.tokenStatus.includes('Invalid refresh token')

        if (isRefreshTokenExpired && this.refreshTokenExpiredHandler) {
            this.refreshTokenExpiredHandler()
            return
        }

        if (!response.problem || response.problem === 'CLIENT_ERROR') {
            if (response.status === 401 &&
                this.refreshTokenHandler &&
                toRetry &&
                !isRefreshTokenInvalid &&
                !isRefreshTokenExpired
            ) {
                const refreshTokenRes = await this.refreshTokenHandler(this.refreshToken)
    
                if (refreshTokenRes.data.accessToken && refreshTokenRes.data.refreshToken) {
                    const { accessToken, refreshToken } = refreshTokenRes.data
                    if (this.updateTokenHandler) this.updateTokenHandler(accessToken, refreshToken)
                    this.authToken = accessToken
                    this.refreshToken = refreshToken
    
                    this.logger.debug('[Request Retry]', {
                        url: response.config.url,
                        data: response.config.data
                    })
    
                    const retryRes = await this.httpClient.any({ ...response.config })
                    response.config = retryRes.config
                    response.data = retryRes.data
                    response.duration = retryRes.duration
                    response.headers = retryRes.headers
                    response.ok = retryRes.ok
                    response.originalError = retryRes.originalError
                    response.problem = retryRes.problem
                    response.status = retryRes.status
                }
            }
        } else if (response.problem === 'SERVER_ERROR') {
            if (this.serverErrorHandler) this.serverErrorHandler()
        } else if (response.problem === 'TIMEOUT_ERROR') {
            if (this.timeoutErrorHandler) this.timeoutErrorHandler()
        } else if (response.problem === 'CONNECTION_ERROR') {
            if (this.connectionErrorHandler) this.connectionErrorHandler()
        } else if (response.problem === 'NETWORK_ERROR') {
            if (this.networkErrorHandler) this.networkErrorHandler()  
        } else if (response.problem === 'CANCEL_ERROR') {
            if (this.cancelErrorHandler) this.cancelErrorHandler()
        }

        this.logger.debug('[Final Response]', response)
    }

    any = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.any(...args)
                resolve(response)
            })()
        })
    }

    post = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.post(...args)
                resolve(response)
            })()
        })
    }

    put = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.put(...args)
                resolve(response)
            })()
        })
    }

    delete = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.delete(...args)
                resolve(response)
            })()
        })
    }

    get = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.get(...args)
                resolve(response)
            })()
        })
    }

    setAuthToken = (authToken) => {
        this.authToken = authToken
    }

    setRefreshToken = (refreshToken) => {
        this.refreshToken = refreshToken
    }

    setCheckToRetryHandler = (callback) => {
        this.checkToRetryHandler = callback
    }

    setRefreshTokenHanlder = (callback) => {
        this.refreshTokenHandler = callback
    }

    setRefreshTokenExpiredHandler = (callback) => {
        this.refreshTokenExpiredHandler = callback
    }

    setUpdateTokenHandler = (callback) => {
        this.updateTokenHandler = callback
    }

    setServerErrorHandler = (callback) => {
        this.serverErrorHandler = callback
    }

    setTimeoutErrorHandler = (callback) => {
        this.timeoutErrorHandler = callback
    }

    setConnectionErrorHandler = (callback) => {
        this.connectionErrorHandler = callback
    }

    setNetworkErrorHandler = (callback) => {
        this.networkErrorHandler = callback
    }

    setCancelErrorHandler = (callback) => {
        this.cancelErrorHandler = callback
    }
}

export default WebClient
