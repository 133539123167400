const loginEndpointPath = '/cfp/login'
const resendEmailVerificationCodeEndpointPath =
    '/admin/cfp/email-verification/resend'
const verifyEmailEndpointPath = '/cfp/email-verification/verify'
const setPasswordEndpointPath = '/cfp/set-password'
const checkTokenEndpointPath = '/cfp/check-token'
const refreshTokenEndpointPath = '/cfp/refresh-token'
const getGoogleCalendarAuthUrlEndpointPath = '/cfp/google-calendar/auth-url'
const completeGoogleCalendarAuthUrlEndpointPath =
    '/cfp/google-calendar/complete-auth'
const listCfpFromAdminEndpointPath = '/admin/cfp/list'
const addCfpFromAdminEndpointPath = '/admin/cfp/create'
const updateCfpFromAdminEndpointPath = '/admin/cfp/update'
const getProfileEndpointPath = '/cfp/profile'
const updateProfileEndpointPath = '/cfp/update-profile'
const changePasswordEndpointPath = '/cfp/change-password'
const uploadProfilePictureEndpointPath = '/cfp/upload-profile-picture'
const uploadProfileBannerEndpointPath = '/cfp/upload-profile-banner'
const getDashboardDataEndpointPath = '/cfp/total-appointment'

class CfpService {
    constructor(adminWebClient, cfpWebClient, credentialEncrypter) {
        this.adminWebClient = adminWebClient
        this.cfpWebClient = cfpWebClient
        this.credentialEncrypter = credentialEncrypter
    }

    async login(username, password, isEncrypted = false) {
        if (isEncrypted) {
            const encrypytedPassword = this.credentialEncrypter.encrypt(password)
            password = encrypytedPassword
        }

        const res = await this.cfpWebClient.post(loginEndpointPath, {
            email: username,
            password,
            isEncrypted
        })
        return res
    }

    async resendEmailVerificationCode(email) {
        const res = await this.adminWebClient.post(
            resendEmailVerificationCodeEndpointPath,
            {
                email
            }
        )
        return res
    }

    async verifyEmailVerificationCode(code) {
        const res = await this.cfpWebClient.post(verifyEmailEndpointPath, {
            code
        })
        return res
    }

    async setPassword(password, passwordConfirmation, isEncrypted = false) {
        if (isEncrypted) {
            const encrypytedPassword = this.credentialEncrypter.encrypt(password)
            password = encrypytedPassword

            const encrypytedPasswordConfirmation = this.credentialEncrypter.encrypt(
                passwordConfirmation
            )
            passwordConfirmation = encrypytedPasswordConfirmation
        }

        const res = await this.cfpWebClient.post(setPasswordEndpointPath, {
            password,
            passwordConfirmation,
            isEncrypted
        })
        return res
    }

    async checkToken() {
        const res = await this.cfpWebClient.get(checkTokenEndpointPath)
        return res
    }

    async refreshToken(refreshToken) {
        const res = await this.cfpWebClient.post(refreshTokenEndpointPath, {
            refreshToken
        })
        return res
    }

    async getGoogleCalendarAuthUrl() {
        const res = await this.cfpWebClient.get(
            getGoogleCalendarAuthUrlEndpointPath
        )
        return res
    }

    async completeGoogleAuth(code, cfpId) {
        const res = await this.cfpWebClient.post(
            completeGoogleCalendarAuthUrlEndpointPath,
            {
                code,
                cfpId
            }
        )
        return res
    }

    async listFromAdmin(params) {
        const res = await this.adminWebClient.get(
            listCfpFromAdminEndpointPath,
            params
        )
        return res
    }

    async addFromAdmin(params) {
        const res = await this.adminWebClient.post(
            addCfpFromAdminEndpointPath,
            params
        )
        return res
    }

    async updateFromAdmin(params) {
        const res = await this.adminWebClient.post(
            updateCfpFromAdminEndpointPath,
            params
        )
        return res
    }

    async getProfile(params) {
        const res = await this.cfpWebClient.get(getProfileEndpointPath, params)
        return res
    }

    async updateProfile(params) {
        const res = await this.cfpWebClient.post(
            updateProfileEndpointPath,
            params
        )
        return res
    }

    async uploadProfilePicture(params) {
        const res = await this.cfpWebClient.post(
            uploadProfilePictureEndpointPath,
            params,
            {
                headers: {
                    'Content-Type':
                        'multipart/form-data; boundary=<calculated when request is sent>'
                }
            }
        )
        return res
    }

    async uploadProfileBanner(params) {
        const res = await this.cfpWebClient.post(
            uploadProfileBannerEndpointPath,
            params,
            {
                headers: {
                    'Content-Type':
                        'multipart/form-data; boundary=<calculated when request is sent>'
                }
            }
        )
        return res
    }

    async changePassword(currentPassword, password, isEncrypted = false) {
        if (isEncrypted) {
            const encrypytedPassword = this.credentialEncrypter.encrypt(password)
            password = encrypytedPassword

            const encrypytedCurrentPassword = this.credentialEncrypter.encrypt(
                currentPassword
            )
            currentPassword = encrypytedCurrentPassword
        }

        const res = await this.cfpWebClient.post(changePasswordEndpointPath, {
            currentPassword,
            password,
            isEncrypted
        })
        return res
    }

    async getCfpAppointment (params) {
        const res = await this.cfpWebClient.get(getDashboardDataEndpointPath, params)
        return res
    }
}

export default CfpService
