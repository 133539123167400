import React, { useEffect, useState } from 'react'
import { inject } from 'mobx-react'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import AssessmentAnswerCard from './assessment-report-card/AssessmentAnswerCard'
import { Select } from 'antd'

const CfpCustomerAssessmentAnswer = ({ customerId, logger, services: { customerService } }) => {

    const assessmentOptions = [
        { value: 1, label: 'Cash Flow' },
        { value: 2, label: 'Investment' },
        { value: 3, label: 'Retirement' },
        { value: 4, label: 'Insurance' },
        { value: 5, label: 'Tax Planning' },
        { value: 6, label: 'Estate Planning' }
    ]

    const [currentSelection, setCurrentSelection] = useState(assessmentOptions[0].value)
    const [assessmentId, setAssessmentId] = useState(null)
    const [assessmentReport, setAssessmentReport] = useState(null)
    const [errorReport, setErrorReport] = useState(null)
    const [loading, setLoading] = useState(true)

    const handleSelection = (value) => {
        setCurrentSelection(value)
    }

    const getCustomerAssessmentId = async (customerId, categoryId) => {
        try {
            setLoading(true)
            const apiParams = { customerId, categoryId }
            const res = await customerService.getCustomerAssessmentId(apiParams)

            if (res.ok) {
                const data = res.data
                setAssessmentId(data[0].customerAssessmentId)
                setErrorReport(null)
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res?.data?.errors
                if (errors) {
                    setErrorReport('Assessment is not done for'+ ' ' + assessmentOptions[currentSelection - 1]?.label)
                }
            }
        } catch (e) {
            logger.error('[Failed Get Customer Assessment Id]', e)
        } finally {
            setLoading(false)
        }
    }

    const getCustomerAssessmentReport = async (assessmentId) => {
        try {
            setLoading(true)
            const apiParams = { assessmentId }
            const res = await customerService.getCustomerAssessmentReport(apiParams)

            if (res.ok) {
                const data = res.data
                setAssessmentReport(data)
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res?.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
            }
        } catch (e) {
            logger.error('[Failed Get Customer Assessment Report]', e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getCustomerAssessmentId(customerId, currentSelection)
    }, [currentSelection])

    useEffect(() => {
        if (assessmentId !== null) {
            getCustomerAssessmentReport(assessmentId)
        }
    }, [assessmentId])

    return (
        <div>
            <div className='flex flex-col gap-x-[50px] mb-[20px]'>
                <h1>Customer Assessment Report</h1>
                <Select
                    className='w-1/5'
                    options={assessmentOptions}
                    defaultValue={assessmentOptions[0]}
                    onChange={handleSelection}
                ></Select>
            </div>

            {!errorReport ? (
                assessmentReport?.map((item, index) => (
                    <AssessmentAnswerCard key={index} data={item} loading={loading}/>
                ))
            ) : (
                <p>{errorReport}</p>
            )}
        </div>
    )
}

export default inject('services', 'logger')(CfpCustomerAssessmentAnswer)