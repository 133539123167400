import showApiErrorMessage from '@helpers/showApiErrorMessage'

const useCustomerOptionLoader = (props) => {
    const { logger, customerService, by } = props

    const methodNames = {
        'CFP': 'listFromCfp',
        'ADMIN': 'listFromAdmin'
    }

    const loadOptions = async (inputValue, loadedOptions) => {
        try {
            const nextPage = (loadedOptions.length / 20) + 1

            const methodName = methodNames[by]
            const res = await customerService[methodName]({
                name: inputValue,
                page: nextPage
            })

            if (res.ok && res.data) {
                const data = res.data
                const customers = data.data
                const options = []

                customers.forEach((customer, index) => {
                    options.push({
                        label: `${customer.fullName} - ${customer.email}`,
                        value: customer.id,
                        data: customer
                    })
                })

                const hasMore = (data.lastPage > data.page)

                return {
                    options,
                    hasMore
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                throw new Error(errors)
            }
        } catch (error) {
            logger.error('[Failed Load Customer Options]', error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    return { loadOptions }
}

export default (useCustomerOptionLoader)