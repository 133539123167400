import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import { PageContainer } from '@ant-design/pro-layout'
import Layout from '@components/admin/layout/AdminLayout'
import { Modal } from 'antd'
import AdminCreatePlanTypeForm from '@components/admin/plan-type/create/form/AdminCreatePlanTypeForm'
import { useNavigate } from 'react-router-dom'
import { adminPlanTypeListingPath } from '@constants/routes'

const AdminCreatePlanTypePage = ({ config, logger, services: { planTypeService } }) => {
    const [defaultValues, setDefaultValues] = useState(null)
    const navigate = useNavigate()

    const handleSuccessSubmit = (data) => {
        Modal.success({
            title: 'Success',
            content: (
                <p>New plan type created successfully</p>
            ),
            onOk: () => {
                navigate(`/admin/plan-types/${data.slug}`)
            }
        })
    }

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: adminPlanTypeListingPath,
                            breadcrumbName: 'Plan Types'
                        },
                        {
                            breadcrumbName: 'Create'
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title='Create Plan Type'
            >
                <AdminCreatePlanTypeForm
                    defaultValues={defaultValues}
                    onSuccessSubmit={handleSuccessSubmit}
                />
            </PageContainer>
        </Layout>
    )
}

export default inject('config', 'services', 'logger')(AdminCreatePlanTypePage)
