import { useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { PageContainer } from '@ant-design/pro-layout'
import { inject } from 'mobx-react'
import Layout from '@components/admin/layout/AdminLayout'
import { Link, useParams } from 'react-router-dom'
import { adminCustomerPlanListingPath } from '@constants/routes'
import AdminPlanList from '@components/admin/plan/list/AdminPlanList'

const AdminPlanListingPage = ({ logger, services: { customerService } }) => {
    const { id } = useParams()

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: adminCustomerPlanListingPath,
                            breadcrumbName: 'Plans'
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title={id}
            >
                <AdminPlanList />
            </PageContainer>
        </Layout>
    )
}

export default inject('services', 'logger')(AdminPlanListingPage)
