import { useEffect, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import cn from 'classnames'
import Modal from 'antd/lib/modal/Modal'
import AdminAddEventForm from '../form/AdminAddEventForm'

const AdminAddEventModal = (props) => {
    return (
        <Modal
            className={cn(props.className)}
            title='Add Event'
            open={props.visible}
            onCancel={props.onCancel}
            footer={null}
        >
            <AdminAddEventForm onSuccessSubmit={props.onSuccessSubmit} />
        </Modal>
    )
}

export default inject('services')(AdminAddEventModal)
