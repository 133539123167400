import AsyncSelectPaginateInput from '@components/common/input/async-select-paginate/AsyncSelectPaginateInput'
import { inject } from 'mobx-react'
import { useEffect, useState } from 'react'
import cn from 'classnames'
import useCfpOptionsLoader from '@hooks/useCfpOptionsLoader'

const SelectCfpInput = (props) => {
    const { className, hasError, placeholder, onChange, logger, services, defaultValue, value, disabled } = props
    const { cfpService } = services
    const { loadOptions } = useCfpOptionsLoader({ logger, cfpService })
    const [selectedOption, setSelectedOption] = useState(defaultValue)

    useEffect(() => {
        if (value) {
            setSelectedOption(value)
        } else {
            setSelectedOption(null)
        }
    }, [value])

    return (
        <AsyncSelectPaginateInput
            className={cn(className)}
            isDisabled={disabled}
            hasError={hasError}
            placeholder={placeholder}
            loadOptions={loadOptions}
            onChange={(option) => {
                onChange(option)
                setSelectedOption(option)
            }}
            value={selectedOption}
            defaultOptions
            debounceTimeout={700}
        />
    )
}

SelectCfpInput.defaultProps = {
    placeholder: 'Search by cfp name',
    onChange: () => {}
}

export default inject('logger', 'services')(SelectCfpInput)
