import { useRef, useState, useMemo, useEffect } from 'react'
import { inject } from 'mobx-react'
import moment from 'moment'
import { Modal, Button, Tag } from 'antd'
import ProTable from '@ant-design/pro-table'
import ProDescriptions from '@ant-design/pro-descriptions'
import prepareApiParams from '@helpers/prepareApiParams'
import showApiErrorMessage from '@helpers/showApiErrorMessage'

const AdminEventEnrollmentListModal = (props) => {
    const enrollmentListRef = useRef(null)

    useEffect(() => {
        if (props.visible && props.eventId) {
            enrollmentListRef.current?.reload()
        }
    }, [props.visible, props.eventId])

    const columns = useMemo(() => {
        return [
            {
                title: 'Receipt',
                dataIndex: 'receiptUrl',
                render: (text, record) => (
                    <Button type='primary' onClick={() => window.open(text)}>
                        View
                    </Button>
                )
            },
            {
                title: 'Company',
                dataIndex: 'company',
                render: (text, record) => {
                    if (!record.hrData) return '-'
                    return (
                        <ProDescriptions
                            bordered
                            layout='horizontal'
                            size='small'
                            column={1}
                            columns={companyColumns}
                            dataSource={record.hrData}
                        />
                    )
                }
            },
            {
                title: 'Participants',
                dataIndex: 'participants',
                width: 500,
                render: (text, record) => {
                    if (!record.participantList) return '-'
                    const descriptions = []
                    record.participantList.map((participant, index) =>
                        descriptions.push(
                            <ProDescriptions
                                style={{ marginTop: (index === 0) ? 0 : 10 }}
                                key={index}
                                bordered
                                layout='horizontal'
                                size='small'
                                column={1}
                                columns={participantColumns}
                                dataSource={participant}
                            />
                        )
                    )
                    return descriptions
                }
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                renderText: (text) => {
                    if (text == null) return '-'
                    const dateStr = moment.utc(text).local().format('D MMM YYYY hh:mm A')
                    return dateStr
                }
            }
        ]
    }, [])

    const companyColumns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'companyName'
            },
            {
                title: 'Person',
                dataIndex: 'name',
                render: (text, record) => {
                    if (!record.name) return '-'
                    return `${record.title} ${record.name}`
                }
            },
            {
                title: 'Email',
                dataIndex: 'email'
            },
            {
                title: 'Contact',
                dataIndex: 'contact',
                render: (text, record) => {
                    if (!record.mobileNumber || !record.mobilePrefix) return '-'
                    return `+${record.mobilePrefix} ${record.mobileNumber}`
                }
            },
            {
                title: 'HR Claimable',
                dataIndex: 'hrdClaimable',
                render: (text, record) => {
                    if (record.hrdClaimable) {
                        return (
                            <Tag color='success'>
                                True
                            </Tag>
                        )
                    } else {
                        return (
                            <Tag color='error'>
                                False
                            </Tag>
                        )
                    }
                }
            }
        ]
    }, [])

    const participantColumns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                width: 100,
                render: (text, record) => {
                    if (!record.name) return '-'
                    return `${record.title} ${record.name}`
                }
            },
            {
                title: 'Identity Number',
                dataIndex: 'icNumber'
            },
            {
                title: 'Email',
                dataIndex: 'email'
            },
            {
                title: 'Contact',
                dataIndex: 'contact',
                render: (text, record) => {
                    if (!record.mobileNumber || !record.mobilePrefix) return '-'
                    return `+${record.mobilePrefix} ${record.mobileNumber}`
                }
            },
            {
                title: 'Job Title',
                dataIndex: 'jobTitle',
                render: (text, record) => {
                    if (!record.jobTitle) return '-'
                    return record.jobTitle
                }
            }
        ]
    }, [])

    const listEnrollment = async (params, sort, filter) => {
        try {
            const apiParams = { id: props.eventId }
            prepareApiParams(params, sort, apiParams)
            const res = await props.services.eventService.listEnrollmentFromAdmin(apiParams)

            if (res.ok) {
                return {
                    data: res.data,
                    success: res.ok
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                return {
                    data: null,
                    success: res.ok,
                    total: 0
                }
            }
        } catch (e) {
            console.error('[Failed List Enrollment]', e)
        }
    }

    return (
        <Modal
            title='Enrollments'
            width={1200}
            open={props.visible}
            onOk={props.onOk}
            onCancel={props.onCancel}
        >
            <ProTable
                actionRef={enrollmentListRef}
                search={false}
                pagination={false}
                headerTitle=''
                request={listEnrollment}
                columns={columns}
                rowKey='id'
                toolBarRender={false}
                footer={() => ''}
            />
        </Modal>
    )
}

export default inject('services')(AdminEventEnrollmentListModal)
