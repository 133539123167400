import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

const CfpPublicRoute = ({ children, cfpStore, restricted, ...restProps }) => {
    const [isAuthed, setIsAuthed] = useState(cfpStore.accessToken && cfpStore.isLogined)

    useEffect(() => {
        setIsAuthed(cfpStore.accessToken && cfpStore.isLogined)
    }, [cfpStore.accessToken, cfpStore.isLogined])

    if (isAuthed && restricted) {
        return <Navigate to={restProps.redirectTo} replace />
    }

    return children
}

CfpPublicRoute.propTypes = {
    restricted: PropTypes.bool,
    redirectTo: PropTypes.object
}

CfpPublicRoute.defaultProps = {
    restricted: false,
    redirectTo: {
        pathname: '/cfp/dashboard'
    }
}

export default inject('cfpStore')(observer(CfpPublicRoute))
