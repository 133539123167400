import { useEffect, useMemo } from 'react'
import { HashRouter, Routes, Route, Navigate, useNavigate, createSearchParams } from 'react-router-dom'
import { Modal } from 'antd'
import { observer, Provider} from 'mobx-react'
import { connectReduxDevtools } from 'mst-middlewares'
import AsyncStorage from '@callstack/async-storage'
import { applySnapshot, onSnapshot } from 'mobx-state-tree'
import AppProvider from '@contexts/app/AppProvider'
import showNetworkErrorMessage from '@helpers/showNetworkErrorMessage'
import showServerErrorMessage from '@helpers/showServerErrorMessage'
import showTimeoutErrorMessage from '@helpers/showTimeoutErrorMessage'
import showConnectionErrorMessage from '@helpers/showConnectionErrorMessage'
import showCancelErrorMessage from '@helpers/showCancelErrorMessage'
import MainRoutes from 'routes/MainRoutes'

const App = ({ container }) => {
    const navigate = useNavigate()
    const adminStore = container.get('adminStore')
    const cfpStore = container.get('cfpStore')
    const config = container.get('config')
    const services = container.get('services')
    const logger = container.get('logger')
    const cfpWebClient = container.get('cfpWebClient')
    const adminWebClient = container.get('adminWebClient')

    useEffect(() => {
        const load = async () => {
            adminWebClient.setServerErrorHandler(showServerErrorMessage)
            adminWebClient.setTimeoutErrorHandler(showTimeoutErrorMessage)
            adminWebClient.setConnectionErrorHandler(showConnectionErrorMessage)
            adminWebClient.setNetworkErrorHandler(showNetworkErrorMessage)
            adminWebClient.setCancelErrorHandler(showCancelErrorMessage)

            cfpWebClient.setServerErrorHandler(showServerErrorMessage)
            cfpWebClient.setTimeoutErrorHandler(showTimeoutErrorMessage)
            cfpWebClient.setConnectionErrorHandler(showConnectionErrorMessage)
            cfpWebClient.setNetworkErrorHandler(showNetworkErrorMessage)
            cfpWebClient.setCancelErrorHandler(showCancelErrorMessage)

            const adminJson = await AsyncStorage.getItem('adminStore')
            if (adminJson) {
                const obj = JSON.parse(adminJson)
                adminWebClient.setAuthToken(obj.accessToken)
                adminWebClient.setRefreshToken(obj.refreshToken)

                adminWebClient.setCheckToRetryHandler((response) => {
                    return response.config.url !== '/admin/refresh-token'
                })
    
                adminWebClient.setRefreshTokenHanlder(async (refreshToken) => {
                    const adminService = services.adminService
                    const res = await adminService.refreshToken(refreshToken)
                    return res
                })
    
                adminWebClient.setRefreshTokenExpiredHandler(async () => {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>
                                Please re-login to continue your action
                            </p>
                        ),
                        onOk () {
                            adminStore.clear()
                            navigate('/admin/login')
                            
                        }
                    })
                })
    
                adminWebClient.setUpdateTokenHandler((accessToken, refreshToken) => {
                    logger.debug('[Update Tokens]')
                    adminStore.setData({
                        accessToken,
                        refreshToken
                    })
                })

                if (obj) {
                    applySnapshot(adminStore, obj)
                }
            }
            adminStore.init()

            const cfpJson = await AsyncStorage.getItem('cfpStore')
            if (cfpJson) {
                const obj = JSON.parse(cfpJson)
                cfpWebClient.setAuthToken(obj.accessToken)
                cfpWebClient.setRefreshToken(obj.refreshToken)

                cfpWebClient.setCheckToRetryHandler((response) => {
                    return response.config.url !== '/cfp/refresh-token'
                })
    
                cfpWebClient.setRefreshTokenHanlder(async (refreshToken) => {
                    const cfpService = services.cfpService
                    const res = await cfpService.refreshToken(refreshToken)
                    return res
                })
    
                cfpWebClient.setRefreshTokenExpiredHandler(async () => {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>
                                Please re-login to continue your action
                            </p>
                        ),
                        onOk () {
                            cfpStore.clear()
                            navigate('/cfp/login')
                            
                        }
                    })
                })
    
                cfpWebClient.setUpdateTokenHandler((accessToken, refreshToken) => {
                    logger.debug('[Update Tokens]')
                    cfpStore.setData({
                        accessToken,
                        refreshToken
                    })
                })

                if (obj) {
                    applySnapshot(cfpStore, obj)
                }
            }
            cfpStore.init()
        }

        load()

        connectReduxDevtools(require('remotedev'), cfpStore)


        onSnapshot(adminStore, snapshot => {
            const obj = snapshot
            AsyncStorage.setItem('adminStore', JSON.stringify(obj))
        })

        onSnapshot(cfpStore, snapshot => {
            const obj = snapshot
            AsyncStorage.setItem('cfpStore', JSON.stringify(obj))
        })
    }, [])

    useEffect(() => {
        logger.debug('[Logined as CFP]', cfpStore.isLogined)
    }, [cfpStore.isLogined])

    useEffect(() => {
        logger.debug('[Logined as admin]', adminStore.isLogined)
    }, [adminStore.isLogined])

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    if (params?.completeGoogleAuth) {
        window.location.href = '/#/cfp/complete-google-auth' + window.location.search
        return (<div />)
    }

    return (
        <AppProvider container={container}>
            <MainRoutes />
        </AppProvider>
    )
}

export default observer(App)
