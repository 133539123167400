import  { useState, useRef, useMemo } from 'react'
import numeral from 'numeral'
import { CSVLink } from 'react-csv'
import { inject } from 'mobx-react'
import moment from 'moment'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable from '@ant-design/pro-table'
import { Button, Image, Space, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Layout from '@components/admin/layout/AdminLayout'
import prepareApiParams from '@helpers/prepareApiParams'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import AdminAddEventModal from '@components/admin/event/add/modal/AdminAddEventModal'
import AdminUpdateEventModal from '@components/admin/event/update/modal/AdminUpdateEventModal'
import AdminEventEnrollmentListModal from '@components/admin/event/enrollment/list/modal/AdminEventEnrollmentListModal'
import { adminEventListingPath } from '@constants/routes'

const AdminEventListingPage = ({ config, logger, services: { eventService } }) => {
    const actionRef = useRef()
    const [toPreviewBanner, setToPreviewBanner] = useState(false)
    const [toShowEnrollmentListModal, setToShowEnrollmentListModal] = useState(false)
    const [toShowAddEvent, setToShowAddEvent] = useState(false)
    const [toShowUpdateEvent, setToShowUpdateEvent] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [updateEventFormProps, setUpdateEventFormProps] = useState(null)
    const [eventIdToDownload, setEventIdToDownload] = useState(false)
    const [csvData, setCsvData] = useState('')

    const columns = useMemo(() => {
        return [
            {
                title: 'Title',
                dataIndex: 'name',
                width: 300,
                render: (text, record) => (
                    <Space direction='vertical'>
                        <Typography.Link strong onClick={() => handleEditEvent(record)}>{record.name}</Typography.Link>
                        <Typography.Text type='secondary'>{record.description}</Typography.Text>
                    </Space>
                )
            },
            {
                title: 'Banner',
                dataIndex: 'bannerUrl',
                render: (text, record) => (
                    <>
                        <Button type='primary' onClick={() => setToPreviewBanner(true)}>
                            View
                        </Button>

                        <Image
                            style={{ display: 'none' }}
                            src={record.bannerUrl}
                            preview={{
                                visible: toPreviewBanner,
                                onVisibleChange: value => {
                                    setToPreviewBanner(value)
                                }
                            }}
                        />
                    </>
                )
            },
            {
                title: 'From',
                dataIndex: 'startAt',
                renderText: (text) => {
                    if (text == null) return '-'
                    const dateStr = moment.utc(text).local().format('D MMM YYYY hh:mm A')
                    return dateStr
                }
            },
            {
                title: 'To',
                dataIndex: 'endAt',
                renderText: (text) => {
                    if (text == null) return '-'
                    const dateStr = moment.utc(text).local().format('D MMM YYYY hh:mm A')
                    return dateStr
                }
            },
            {
                title: 'Venue',
                dataIndex: 'venue'
            },
            {
                title: 'Price',
                dataIndex: 'price',
                renderText: (text) => `RM ${numeral(text).format('0,0.00')}`
            },
            {
                title: 'Enrollments',
                dataIndex: 'enrollments',
                render: (text, record) => {
                    return (
                        <Space>
                            <Button type='primary' onClick={() => handleViewEnrollment(record)}>
                                View
                            </Button>

                            {eventIdToDownload === record.id
                                ? <CSVLink data={csvData} filename={'participants.csv'}>Download</CSVLink>
                                : <Button type='default' onClick={() => handleExportEnrollments(record.id)}>Export</Button>
                            }
                        </Space>
                    )
                }
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                sorter: true,
                renderText: (text) => {
                    if (text == null) return '-'
                    const dateStr = moment.utc(text).local().format('D MMM YYYY hh:mm A')
                    return dateStr
                }
            }
        ]
    }, [toPreviewBanner, csvData, eventIdToDownload])

    const listEvent = async (params, sort, filter) => {
        try {
            const apiParams = {}
            prepareApiParams(params, sort, apiParams)
            const res = await eventService.listFromAdmin(apiParams)

            if (res.ok) {
                const data = res.data
                return {
                    data: data.data,
                    success: res.ok,
                    total: data.lastPage * data.perPage
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                return {
                    data: null,
                    success: res.ok,
                    total: 0
                }
            }
        } catch (e) {
            logger.error('[Failed List Event]', e)
        }
    }

    const handleViewEnrollment = (event) => {
        setSelectedEvent(event)
        setToShowEnrollmentListModal(true)
    }

    const handleExportEnrollments = async (id) => {
        try {
            const res = await eventService.listEnrollmentFromAdmin({ id })
            if (res.ok && res.data) {
                if (Array.isArray(res.data)) {
                    const participants = res.data.map((obj) => {
                        const receipt = { receipt: obj.receiptUrl }
                        if (Object.prototype.hasOwnProperty.call(obj, 'hrData')) {
                            const hrData = {
                                hrName: obj.hrData.name,
                                hrEmail: obj.hrData.email,
                                hrTitle: obj.hrData.title,
                                hrAddress: obj.hrData.address,
                                hrCompanyName: obj.hrData.companyName,
                                hrClaimable: obj.hrData.hrdClaimable,
                                hrMobileNumber: obj.hrData.mobileNumber,
                                hrMobilePrefix: obj.hrData.mobilePrefix
                            }
                            return obj.participantList.map((participant) => ({...participant, ...hrData, ...receipt}))
                        }
                        else {
                            return obj.participantList.map((participant) => ({...participant, ...receipt}))
                        }
                    })
                    const flatParticipants = participants.flatMap((obj) => obj)
                    setCsvData(flatParticipants)
                    setEventIdToDownload(id)
                }
            }
        } catch (e) {
            console.error('Failed to get participants', e)    
        }
    }

    const handleAddEventSuccess = (data) => {
        setToShowAddEvent(false)
        actionRef.current?.reload()
    }

    const handleEditEvent = (event) => {
        setUpdateEventFormProps({
            defaultValues: {
                ...event,
                startAt: moment(event.startAt),
                endAt: moment(event.endAt)
            }
        })
        setToShowUpdateEvent(true)
    }

    const handleUpdateEventSuccess = (data) => {
        setToShowUpdateEvent(false)
        actionRef.current?.reload()
    }

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: adminEventListingPath,
                            breadcrumbName: 'Events'
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title='Events'
            >
                <ProTable
                    actionRef={actionRef}
                    headerTitle=''
                    columns={columns}
                    request={listEvent}
                    search={false}
                    rowKey='id'
                    toolBarRender={() => [
                        <Button
                            type='primary'
                            key='primary'
                            onClick={() => {
                                setToShowAddEvent(true)
                            }}
                        >
                            <PlusOutlined /> Add
                        </Button>
                    ]}
                />

                <AdminAddEventModal
                    visible={toShowAddEvent}
                    onCancel={() => setToShowAddEvent(false)}
                    onSuccessSubmit={handleAddEventSuccess}
                />

                {updateEventFormProps &&
                <AdminUpdateEventModal
                    key={updateEventFormProps.defaultValues.id}
                    form={updateEventFormProps}
                    visible={toShowUpdateEvent}
                    onCancel={() => setToShowUpdateEvent(false)}
                    onSuccessSubmit={handleUpdateEventSuccess}
                />}

                <AdminEventEnrollmentListModal
                    eventId={selectedEvent?.id}
                    visible={toShowEnrollmentListModal}
                    onOk={() => setToShowEnrollmentListModal(false)}
                    onCancel={() => setToShowEnrollmentListModal(false)}
                />
            </PageContainer>
        </Layout>
    )
}

export default inject('services')(AdminEventListingPage)