const planStatuses = {
    PENDING: {
        name: 'Pending',
        color: ''
    },
    PAID: {
        name: 'Paid',
        color: ''
    },
    IN_PROGRESS: {
        name: 'In Progress',
        color: ''
    },
    COMPLETED: {
        name: 'Completed',
        color: ''
    },
    EXPIRED: {
        name: 'Expired',
        color: ''
    },
    PENDING_CFP_SELECTION: {
        name: 'Pending CFP Selection',
        color: ''
    }
}

export default planStatuses
