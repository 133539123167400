import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import prepareApiParams from '@helpers/prepareApiParams'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import { Button, Modal } from 'antd'
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import downloadCsv from '@helpers/downloadCsv'
import CfpAddCustomerModal from '../add/modal/CfpAddCustomerModal'
import CfpUpdateCustomerModal from '../update/modal/CfpUpdateCustomerModal'
import CustomerList from '@components/shared/customer/list/CustomerList'
import { Link } from 'react-router-dom'
import { cfpCustomerDetailPath } from '@constants/routes'

const CfpCustomerList = ({ logger, services, className, style, defaultFilters, renderColumns, search }) => {
    const tableActionRef = useRef(null)
    const { customerService } = services
    const [filters, setFilters] = useState(defaultFilters)
    const [updateFormProps, setUpdateFormProps] = useState(null)
    const [addFormProps, setAddFormProps] = useState(null)

    useEffect(() => {
    }, [])

    useEffect(() => {
        setFilters(defaultFilters)
        tableActionRef.current?.reset()
        tableActionRef.current?.reload()
    }, [defaultFilters])

    const handleRequest = async (params, sort) => {
        try {
            const apiParams = {
                ...filters
            }
            prepareApiParams(params, sort, apiParams)
            const res = await customerService.listFromCfp(apiParams)

            if (res.ok) {
                const data = res.data
                return {
                    data: data.data,
                    success: res.ok,
                    total: data.lastPage * data.perPage
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                return {
                    data: null,
                    success: res.ok,
                    total: 0
                }
            }
        } catch (e) {
            logger.error('[Failed List Customer]', e)
        }
    }

    const exportCsv = async () => {
        try {
            const res = await customerService.exportCsvFromCfp()
            if (res.ok) {
                downloadCsv(res.data, 'customers.csv')
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (e) {
            logger.error('[Failed Export Customer CSV]', e)
            Modal.error({
                title: 'Error',
                content: (
                    <p>Something went wrong</p>
                )
            })
        }
    }

    return (
        <>
            <CustomerList
                actionRef={tableActionRef}
                className={className}
                style={style}
                title={null}
                search={search}
                handleRequest={handleRequest}
                renderColumns={(defaultColumns) => {
                    defaultColumns.fullName.render = (text, record) => (
                        <Link to={cfpCustomerDetailPath(record.id)}>
                            {text}
                        </Link>
                    )

                    defaultColumns.action = {
                        ...defaultColumns.action,
                        render: (text, record, _, action) => {
                            return (
                                <Button
                                    key='edit'
                                    type='link'
                                    onClick={() => {
                                        setUpdateFormProps({
                                            defaultValues: {
                                                ...record,
                                                birthDate: (record.birthDate) ? moment(record.birthDate) : null
                                            },
                                            onSuccessSubmit: () => {
                                                tableActionRef.current?.reload()
                                                setUpdateFormProps(null)
                                            }
                                        })
                                    }}
                                >
                                    Update
                                </Button>
                            )
                        }
                    }

                    if (renderColumns) {
                        return renderColumns(defaultColumns)
                    }

                    return Object.values(defaultColumns)
                }}
                renderToolbar={() => [
                    <>
                        <Button
                            type='primary'
                            key='export-csv'
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Are you sure to export to csv?',
                                    icon: <ExclamationCircleOutlined />,
                                    okText: 'Confirm',
                                    cancelText: 'Cancel',
                                    onOk: async () => {
                                        await exportCsv()
                                    }
                                })
                            }}
                        >
                            <PlusOutlined /> Export CSV
                        </Button>
                        <Button
                            type='primary'
                            key='add'
                            onClick={() => {
                                setAddFormProps({
                                    onSuccessSubmit: () => {
                                        tableActionRef.current?.reload()
                                        setAddFormProps(null)
                                    }
                                })
                            } }
                        >
                            <PlusOutlined /> Add
                        </Button>
                    </>
                ]}
            />

            <CfpAddCustomerModal
                form={addFormProps}
                visible={!!addFormProps}
                onCancel={() => setAddFormProps(null)}
            />

            <CfpUpdateCustomerModal
                form={updateFormProps}
                visible={!!updateFormProps}
                onCancel={() => setUpdateFormProps(null)}
            />
        </>
    )
}

export default inject('logger', 'services')(CfpCustomerList)
