import React from 'react'
import { createRoot } from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import './styles/global.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import generateAppContainer from '@container'

generateAppContainer.then((appContainer) => {
    const container = document.getElementById('root')
    const root = createRoot(container)
    root.render(
        <HashRouter>
            <App container={appContainer} />
        </HashRouter>
    )

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals()
})
