import { useEffect } from 'react'
import { inject } from 'mobx-react'
import { Link, useNavigate } from 'react-router-dom'
import CfpLoginFormWrapper from '@components/cfp/login/form/wrapper/CfpLoginFormWrapper'

const CfpLoginPage = ({ cfpStore, config }) => {
    const navigate = useNavigate()

    const handleSuccessSubmit = () => {
        navigate('/cfp/dashboard')
    }

    return (
        <CfpLoginFormWrapper
            onSuccessSubmit={handleSuccessSubmit}
        />
    )
}

export default inject('services', 'cfpStore', 'config')(CfpLoginPage)
