import cn from 'classnames'
import { Modal, Button } from 'antd'

const CfpDeleteAppointmentAttachmentModal = (props) => {
    const record = props?.record

    const handleConfirmDelete = (attachmentId) => {
        props.handleDeleteFile(attachmentId)
        props.onCancel()
    }

    return (
        <Modal
            className={cn(props.className)}
            title='Delete file confirmation'
            open={props.visible}
            onCancel={props.onCancel}
            footer={null}
            destroyOnClose
        >
            <p className='text-center'>Are you sure you want to delete
                <span className='font-bold'>{' '}{record?.title}{' '}</span>
                ?
            </p>
            <div className='flex flex-row items-center justify-center gap-[50px] mt-[50px]'>
                <Button onClick={() => handleConfirmDelete(record.attachmentId)}>Yes</Button>
                <Button onClick={props.onCancel}>No</Button>
            </div>

        </Modal>
    )
}
export default CfpDeleteAppointmentAttachmentModal
