import { useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { PageContainer } from '@ant-design/pro-layout'
import { inject } from 'mobx-react'
import Layout from '@components/admin/layout/AdminLayout'
import { Progress, Tag, Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import AdminPlanList from '@components/admin/plan/list/AdminPlanList'
import AdminCustomerDetail from '@components/admin/customer/detail/AdminCustomerDetail'
import { adminCustomerListingPath } from '@constants/routes'

const AdminCustomerDetailPage = ({ logger, services: { customerService } }) => {
    const { id } = useParams()
    const [customer, setCustomer] = useState(null)
    const [toReload, setToReload] = useState(false)

    const items = [
        {
            key: 'PLAN',
            label: 'Plans',
            children: <AdminPlanList
                search={false}
                className='mt-3'
                customerId={id}
                defaultFilters={{
                    customerId: id
                }}
                renderColumns={(defaultColumns) => {
                    delete defaultColumns.customer

                    return Object.values(defaultColumns)
                }}
                addForm={{
                    defaultValues: {
                        customerId: id
                    },
                    selectedCustomerOption: {
                        value: id,
                        label: `${customer?.fullName} - ${customer?.email}`
                    },
                    disabledFields: ['customerId'],
                    onSuccessAdd: () => setToReload(!toReload)
                }}
            />
        }
    ]

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: adminCustomerListingPath,
                            breadcrumbName: 'Customers'
                        },
                        {
                            breadcrumbName: id
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title={id}
            >
                <AdminCustomerDetail
                    key={toReload}
                    className='bg-white p-5'
                    id={id}
                    onGetCustomerSuccess={(customer) => setCustomer(customer)}
                />

                <Tabs
                    className='bg-white p-5'
                    defaultActiveKey='PLAN'
                    items={items}
                />
            </PageContainer>
        </Layout>
    )
}

export default inject('services', 'logger')(AdminCustomerDetailPage)
