import cn from 'classnames'
import Modal from 'antd/lib/modal/Modal'
import AdminAddPlanForm from '../form/AdminAddPlanForm'

const AdminAddPlanModal = (props) => {
    return (
        <Modal
            className={cn(props.className)}
            title='Add Customer Plan'
            open={props.visible}
            onCancel={props.onCancel}
            footer={null}
            destroyOnClose
        >
            <AdminAddPlanForm
                {...props.form}
            />
        </Modal>
    )
}

export default AdminAddPlanModal
