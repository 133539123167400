import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { inject } from 'mobx-react'
import moment from 'moment'
import { Form, Select, Input, TimePicker, InputNumber, DatePicker, Modal } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import SubmitButton from '@components/common/button/submit/SubmitButton'

const AdminAddEventForm = (props) => {
    const config = props.config
    const logger = props.logger
    const eventService = props.services.eventService
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const { handleSubmit, setError, control, reset, watch, setValue, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    const disabledStartDate = (current) => {
        return current && current.isBefore(moment(), 'day')
    }

    const disabledStartTime = (now) => {
        return {
            disabledHours: () => {
                const hours = []
                if (moment(now).isSame(moment(), 'day')) {
                    for (let i = 0; i < moment().hour(); i += 1) hours.push(i)
                }
                return hours
            },
            disabledMinutes: (selectedHour) => {
                const minutes = []
                if (moment(now).isSame(moment(), 'day') && selectedHour === moment().hour()) {
                    for (let i = moment().minute(); i >= 0; i -= 1) minutes.push(i)
                }
                return minutes
            }
        }
    }

    const disabledEndDate = (current) => {
        return current && current.isBefore(moment(), 'day')
    }

    const disabledEndTime = (now) => {
        return {
            disabledHours: () => {
                const hours = []
                if (moment(now).isSame(moment(), 'day')) {
                    for (let i = 0; i < moment().hour(); i += 1) hours.push(i)
                }
                return hours
            },
            disabledMinutes: (selectedHour) => {
                const minutes = []
                if (moment(now).isSame(moment(), 'day') && selectedHour === moment().hour()) {
                    for (let i = moment().minute(); i >= 0; i -= 1) minutes.push(i)
                }
                return minutes
            }
        }
    }

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            const bannerFilename = '3648d5c8-806a-4b72-9673-1a4120cc2471.png'
            const params = {
                ...data,
                startAt: moment(data.startAt).toDate().toISOString().slice(0, 19),
                endAt: moment(data.endAt).toDate().toISOString().slice(0, 19),
                bannerUrl: `${config.app.s3Url}/${bannerFilename}`
            }

            const res = await eventService.addFromAdmin(params)

            if (res.ok && res.data) {
                props.onSuccessSubmit(res.data)
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (error) {
            logger.error('[Fail Submit Add Event Form', error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            <Form.Item
                className='mb-4'
                label='Name'
                validateStatus={(errors?.name?.message) ? 'error' : '-'}
                help={errors?.name?.message}
            >
                <Controller
                    name='name'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            placeholder='Event name'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Description'
                validateStatus={(errors?.description?.message) ? 'error' : '-'}
                help={errors?.description?.message}
            >
                <Controller
                    name='description'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            placeholder='Description'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Venue'
                validateStatus={(errors?.venue?.message) ? 'error' : '-'}
                help={errors?.venue?.message}
            >
                <Controller
                    name='venue'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            placeholder='Event nenue'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <div className='mb-4 grid grid-cols-12 gap-4'>
                <Form.Item
                    className='mb-0 col-span-6'
                    label='Start Time'
                    validateStatus={(errors?.startAt?.message) ? 'error' : '-'}
                    help={errors?.startAt?.message}
                >
                    <Controller
                        name='startAt'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <DatePicker
                                className='w-full'
                                placeholder='Select Start Time'
                                format='YYYY-MM-DD hh:mm A'
                                showTime
                                disabledDate={disabledStartDate}
                                disabledTime={disabledStartTime}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='mb-0 col-span-6'
                    label='End Time'
                    validateStatus={(errors?.endAt?.message) ? 'error' : '-'}
                    help={errors?.endAt?.message}
                >
                    <Controller
                        name='endAt'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <DatePicker
                                className='w-full'
                                placeholder='Select End Time'
                                format='YYYY-MM-DD hh:mm A'
                                showTime
                                disabledDate={disabledEndDate}
                                disabledTime={disabledEndTime}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>
            </div>

            <Form.Item
                className='mb-4'
                label='Price'
                validateStatus={(errors?.price?.message) ? 'error' : '-'}
                help={errors?.price?.message}
            >
                <Controller
                    name='price'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <InputNumber
                            ref={ref}
                            className='w-full'
                            placeholder='Price per pax'
                            formatter={value => `RM ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            /* eslint-disable no-useless-escape */
                            parser={value => value.replace(/\RM\s?|(,*)/g, '')}
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <SubmitButton className='mt-1' loading={submitting}>
                Submit
            </SubmitButton>
        </Form>
    )
}

export default inject('services', 'config')(AdminAddEventForm)
