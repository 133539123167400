import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'
import CfpPrivateRoute from './CfpPrivateRoute'
import CfpPlanListingPage from '@pages/cfp/plans'
import CfpPlanDetailPage from '@pages/cfp/plans/[id]'

const CfpPlanRoutes = (props) => {
    return (
        <Routes>
            <Route
                path='/'
                exact
                element={
                    <CfpPrivateRoute>
                        <CfpPlanListingPage />
                    </CfpPrivateRoute>
                }
            />
            <Route
                path='/:id'
                element={
                    <CfpPrivateRoute>
                        <CfpPlanDetailPage />
                    </CfpPrivateRoute>
                }
            />
        </Routes>
    )
}

export default inject('logger')(CfpPlanRoutes)
