import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import { PageContainer } from '@ant-design/pro-layout'
import Layout from '@components/admin/layout/AdminLayout'
import AppointmentStatus from '@models/enum/AppointmentStatus'
import AdminAppointmentList from '@components/admin/appointment/list/AdminAppointmentList'
import { adminAppointmentListingPath } from '@constants/routes'

const AdminAppointmentListingPage = ({ config, logger, services: { appointmentService, planTypeService } }) => {
    const tableActionRef = useRef(null)
    const [activeTabKey, setActiveTabKey] = useState(AppointmentStatus.PENDING.enumKey)

    const tabList = useMemo(() => {
        const enumValues = Object.values(AppointmentStatus.enumValues)
        const tabList = []
        enumValues.map((enumValue) =>
            tabList.push({
                label: enumValue.label, key: enumValue.enumKey
            })
        )
        return tabList
    }, [])
    useEffect(() => {
    }, [])

    useEffect(() => {
        tableActionRef.current?.reset()
        tableActionRef.current?.reload()
    }, [activeTabKey])

    const handleTabChange = (key) => {
        setActiveTabKey(key)
    }

    return (
        <Layout>
            <PageContainer
                breadcrumb={{
                    routes: [
                        {
                            path: adminAppointmentListingPath,
                            breadcrumbName: 'Appointments'
                        }
                    ]
                }}
                header={{
                    ghost: false
                }}
                title='Appointments'
                tabList={tabList}
                onTabChange={handleTabChange}
                tabActiveKey={activeTabKey}
            >
                <AdminAppointmentList
                    search
                    defaultFilters={{
                        status: activeTabKey
                    }}
                />
            </PageContainer>
        </Layout>
    )
}

export default inject('config', 'services', 'logger')(AdminAppointmentListingPage)
