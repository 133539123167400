import { Enumify } from 'enumify'

export default class CustomerType extends Enumify {
    value = null

    constructor (value = null, label = null) {
        super()
        this.value = value
        this.label = label
    }

    static of (type) {
        return CustomerType.enumValueOf(type)
    }

    static for (type) {
        switch (type) {
            case 0: return CustomerType.B2C
            case 1: return CustomerType.B2B
        }
        return null
    }
}

CustomerType.B2C = new CustomerType(0, 'B2C')
CustomerType.B2B = new CustomerType(1, 'B2B')
CustomerType._ = CustomerType.closeEnum()
