import planTypes from '@constants/planTypes'
import moment from 'moment'
import { string, object, number } from 'yup'

const schema = object().shape({
    type: string()
        .oneOf(['CONVENTIONAL', 'ISLAMIC'])
        .required(),
    name: string()
        .required(),
    email: string()
        .email()
        .required(),
    mobilePrefix: string()
        .min(1)
        .max(3)
        .nullable()
        .matches(/^\d+$/, 'Invalid mobile prefix'),
    mobileNumber: string()
        .nullable()
        .matches(/^\d+$/, 'Invalid mobile number'),
    settings: object().shape({
        appointment: object().shape({
            defaultStartTime: string()
                .required()
                .test(
                    'is-before-end-time',
                    'Start time must be before end time',
                    (value, context) => {
                        return moment(value).isBefore(context.parent.defaultEndTime)
                    }
                ),
            defaultEndTime: string()
                .required()
                .test(
                    'is-after-start-time',
                    'End time must be after start time',
                    (value, context) => {
                        return moment(value).isAfter(context.parent.defaultStartTime)
                    }
                ),
            timezone: string()
                .oneOf(['Asia/Kuala_Lumpur'])
                .required()
        })
    })
})

export default schema
