import AsyncSelectPaginateInput from '@components/common/input/async-select-paginate/AsyncSelectPaginateInput'
import { inject } from 'mobx-react'
import { useEffect, useState } from 'react'
import usePlanTypeVariantOptionsLoader from '@hooks/usePlanTypeVariantOptionsLoader'
import cn from 'classnames'

const SelectPlanTypeVariantInput = (props) => {
    const { className, hasError, placeholder, onChange, logger, services, defaultValue, value, disabled, planTypeId, by } = props
    const { planTypeService } = services
    const { loadOptions } = usePlanTypeVariantOptionsLoader({ logger, planTypeService, planTypeId, by })
    const [selectedOption, setSelectedOption] = useState(defaultValue)

    useEffect(() => {
        if (value) {
            setSelectedOption(value)
        } else {
            setSelectedOption(null)
        }
    }, [value])

    return (
        <AsyncSelectPaginateInput
            className={cn(className)}
            isDisabled={disabled}
            isSearchable={false}
            hasError={hasError}
            placeholder={placeholder}
            loadOptions={loadOptions}
            onChange={(option) => {
                onChange(option)
                setSelectedOption(option)
            }}
            value={selectedOption}
            defaultOptions
            debounceTimeout={700}
        />
    )
}

SelectPlanTypeVariantInput.defaultProps = {
    placeholder: 'Search variant',
    onChange: () => {}
}

export default inject('logger', 'services')(SelectPlanTypeVariantInput)
