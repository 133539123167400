import { useEffect, useRef, useState, useMemo } from 'react'
import cn from 'classnames'
import { inject } from 'mobx-react'
import { Form, Checkbox, Modal } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import SubmitButton from '@components/common/button/submit/SubmitButton'
import SelectCfpInput from '@components/admin/cfp/input/select/SelectCfpInput'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import SelectPlanTypeInput from '@components/shared/plan-type/input/select/SelectPlanTypeInput'
import SelectPlanTypeVariantInput from '@components/shared/plan-type/input/select/SelectPlanTypeVariantInput'
import SelectCustomerInput from '@components/shared/customer/input/select/SelectCustomerInput'

const AdminAddPlanForm = (props) => {
    const { logger, services, defaultValues, disabledFields } = props
    const { customerPlanService } = services
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const [selectedPlanTypeOption, setSelectedPlanTypeOption] = useState(null)
    const [selectedPlanTypeVariantOption, setSelectedPlanTypeVariantOption] = useState(null)
    const [selectedCfpOption, setSelectedCfpOption] = useState(null)
    const [toShowVariant, setToShowVariant] = useState(false)
    const [selectedCustomerOption, setSelectedCustomerOption] = useState(props.selectedCustomerOption)
    const { handleSubmit, setError, control, reset, watch, setValue, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues
    })

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            const params = {
                ...data,
                toAddAppointment: !!data.toAddAppointment
            }

            logger.debug('[Submit Add Customer Plan Form]', params)

            const res = await customerPlanService.addFromAdmin(params)

            if (res.ok && res.data) {
                props.onSuccessSubmit(res.data)
            } else if (res.problem === 'CLIENT_ERROR') {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (error) {
            logger.error('[Fail Submit Add Customer Plan Form', error)
        } finally {
            setSubmitting(false)
        }
    }

    useEffect(() => {
        if (props.selectedPlanTypeOption) {
            setSelectedPlanTypeOption(props.selectedPlanTypeOption)
        }
    }, [props.selectedPlanTypeOption])

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            <Form.Item
                className='mb-4'
                label='Customer'
                validateStatus={(errors?.customerId?.message) ? 'error' : '-'}
                help={errors?.customerId?.message}
            >
                <Controller
                    name='customerId'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <SelectCustomerInput
                            disabled={disabledFields?.includes('customerId')}
                            by='ADMIN'
                            className='w-full'
                            defaultValue={value}
                            value={selectedCustomerOption}
                            hasError={errors?.customerId?.message}
                            onChange={(option) => {
                                onChange(option.value)
                                setSelectedCustomerOption(option)
                            }}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Plan Type'
                validateStatus={(errors?.planTypeId?.message) ? 'error' : '-'}
                help={errors?.planTypeId?.message}
            >
                <Controller
                    name='planTypeId'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <SelectPlanTypeInput
                            by='ADMIN'
                            className='w-full'
                            defaultValue={value}
                            value={selectedPlanTypeOption}
                            hasError={errors?.planTypeId?.message}
                            onChange={(option) => {
                                setValue('planTypeVariantId', null)
                                onChange(option.value)
                                if (option.planType.hasVariant) {
                                    setToShowVariant(true)
                                } else {
                                    setToShowVariant(false)
                                }
                                setSelectedPlanTypeOption(option)
                            }}
                        />
                    )}
                />
            </Form.Item>

            {toShowVariant && watch('planTypeId') &&
            <Form.Item
                className='mb-4'
                label='Variant'
                validateStatus={(errors?.planTypeVariantId?.message) ? 'error' : '-'}
                help={errors?.planTypeVariantId?.message}
            >
                <Controller
                    name='planTypeVariantId'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <SelectPlanTypeVariantInput
                            by='ADMIN'
                            className='w-full'
                            planTypeId={watch('planTypeId')}
                            defaultValue={value}
                            value={selectedPlanTypeVariantOption}
                            hasError={errors?.planTypeVariantId?.message}
                            onChange={(option) => {
                                onChange(option.value)
                                setSelectedPlanTypeVariantOption(option)
                            }}
                        />
                    )}
                />
            </Form.Item>}

            <Form.Item
                className='mb-4'
                label='CFP'
                validateStatus={(errors?.cfpId?.message) ? 'error' : '-'}
                help={errors?.cfpId?.message}
            >
                <Controller
                    name='cfpId'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <SelectCfpInput
                            className='w-full'
                            defaultValue={value}
                            value={selectedCfpOption}
                            hasError={errors?.cfpId?.message}
                            onChange={(option) => {
                                onChange(option.value)
                                setSelectedCfpOption(option)
                            }}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                validateStatus={(errors?.toAddAppointment?.message) ? 'error' : '-'}
                help={errors?.toAddAppointment?.message}
            >
                <Controller
                    name='toAddAppointment'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Checkbox
                            onChange={onChange}
                            checked={value}
                        >
                            To Add Appointment
                        </Checkbox>
                    )}
                />
            </Form.Item>

            <SubmitButton className='mt-1' loading={submitting}>
                Submit
            </SubmitButton>
        </Form>
    )
}

export default inject('services', 'logger')(AdminAddPlanForm)
