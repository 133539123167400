import { flow, getEnv, types } from 'mobx-state-tree'
import jwtDecode from 'jwt-decode'
import WebClientResult from '@utils/WebClientResult'
import AdminRole from './enum/AdminRole'

export const AdminStore = types
    .model('AdminStore')
    .props({
        initiated: types.optional(types.boolean, false),
        id: types.maybeNull(types.string),
        fullName: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        accessToken: types.maybeNull(types.string),
        refreshToken: types.maybeNull(types.string),
        role: types.maybeNull(
            types.enumeration('AdminRole', [
                AdminRole.SUPER_ADMIN.enumKey,
                AdminRole.ADMIN.enumKey,
                AdminRole.ADMIN_MARKETING.enumKey
            ])
        ),
        isLogined: types.optional(types.boolean, false)
    })
    .views((self) => ({
    }))
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                self[key] = data[key]
            }
        },

        init () {
            self.initiated = true
        },

        login: flow(function * login (username, password) {
            const logger = getEnv(self).logger
            try {
                const service = getEnv(self).adminService
                const webClient = getEnv(self).webClient
                const res = yield service.login(username, password, true)

                if (res.ok) {
                    const data = res.data
                    const decoded = jwtDecode(data.accessToken)
                    self.setData({
                        ...decoded,
                        isLogined: true,
                        accessToken: data.accessToken,
                        refreshToken: data.refreshToken
                    })

                    webClient.setAuthToken(data.accessToken)
                    webClient.setRefreshToken(data.refreshToken)
                    return res
                }
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[Failed Login]', error)
                return new WebClientResult(false, null)
            }
        }),
        
        checkToken: flow(function * checkToken () {
            const logger = getEnv(self).logger
            try {
                const service = getEnv(self).adminService
                const res = yield service.checkToken()
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[Failed Check Token]', error)
                return new WebClientResult(false, null)
            }
        }),

        clear () {
            self.id = null
            self.fullName = null
            self.email = null
            self.accessToken = null
            self.refreshToken = null
            self.isLogined = false
            self.type = null
        }
    }))

let _store

export const createAdminStore = (data, env = null) => {
    if (!_store) {
        _store = AdminStore.create(data, env)
    }
    return _store
}
    
