import { string, object, boolean } from 'yup'

const schema = object().shape({
    planTypeId: string()
        .required(),
    toAddAppointment: boolean(),
    cfpId: string()
        .when('toAddAppointment', {
            is: true,
            then: string()
                .required(),
            otherwise: string()
                .nullable()
        })
})

export default schema
