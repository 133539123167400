import { flow, getEnv, types } from 'mobx-state-tree'
import jwtDecode from 'jwt-decode'
import WebClientResult from '@utils/WebClientResult'

const DecimalPrimitive = types.custom({
    name: 'Decimal',
    fromSnapshot(value) {
        return parseFloat(value)
    },
    toSnapshot(value) {
        return parseFloat(value)
    },
    isTargetType(value) {
        return parseFloat(value)
    },
    getValidationMessage(value) {
        if (/^-?\d+\.\d+$/.test(value)) return '' // OK
        if (/^\d+$/.test(value)) return '' // OK
        return `'${value}' doesn't look like a valid decimal number`
    }
})

const AppointmentSettings = types.model('AppointmentSettings', {
    timezone: types.enumeration('TimeZone', ['Asia/Kuala_Lumpur']),
    defaultStartTime: types.string,
    defaultEndTime: types.string,
    gap: types.maybeNull(types.model('Gap', {
        duration: DecimalPrimitive,
        durationUnit: types.string
    }))
})

const Settings = types.model('Settings', {
    appointment: types.maybeNull(AppointmentSettings)
})

export const CfpStore = types
    .model('CfpStore')
    .props({
        initiated: types.optional(types.boolean, false),
        id: types.maybeNull(types.string),
        type: types.maybeNull(types.string),
        fullName: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        accessToken: types.maybeNull(types.string),
        refreshToken: types.maybeNull(types.string),
        isLogined: types.optional(types.boolean, false),
        verified: types.optional(types.boolean, false),
        hasPassword: types.optional(types.boolean, false),
        settings: types.maybeNull(Settings),
        mobilePrefix: types.maybeNull(types.string),
        mobileNumber: types.maybeNull(types.string),
        licenseNumber: types.maybeNull(types.string)
    })
    .actions(self => ({
        setData(data) {
            for (const key in data) {
                self[key] = data[key]
            }
        },

        init() {
            self.initiated = true
        },

        login: flow(function* login(username, password) {
            const logger = getEnv(self).logger
            try {
                const service = getEnv(self).cfpService
                const webClient = getEnv(self).webClient
                const res = yield service.login(username, password, true)

                if (res.ok) {
                    const data = res.data
                    const decoded = jwtDecode(data.accessToken)
                    self.setData({
                        ...decoded,
                        isLogined: true,
                        accessToken: data.accessToken,
                        refreshToken: data.refreshToken
                    })

                    webClient.setAuthToken(data.accessToken)
                    webClient.setRefreshToken(data.refreshToken)
                    return res
                }
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[Failed Login]', error)
                return new WebClientResult(false, null)
            }
        }),

        verifyEmail: flow(function* verifyEmail(code) {
            const logger = getEnv(self).logger
            try {
                const service = getEnv(self).cfpService
                const webClient = getEnv(self).webClient
                const res = yield service.verifyEmailVerificationCode(code)

                if (res.ok) {
                    const data = res.data
                    const decoded = jwtDecode(data.accessToken)
                    self.setData({
                        ...decoded,
                        verified: true,
                        isLogined: true,
                        accessToken: data.accessToken,
                        refreshToken: data.refreshToken
                    })
                    webClient.setAuthToken(data.accessToken)
                    webClient.setRefreshToken(data.refreshToken)
                    return res
                }
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[Failed Verify]', error)
                return new WebClientResult(false, null)
            }
        }),

        setPassword: flow(function* setPassword(
            password,
            passwordConfirmation
        ) {
            const logger = getEnv(self).logger
            try {
                const service = getEnv(self).cfpService
                const res = yield service.setPassword(
                    password,
                    passwordConfirmation,
                    true
                )
                if (res.ok) {
                    self.setData({
                        hasPassword: true
                    })
                }
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[Failed Set Password]', error)
                return new WebClientResult(false, null)
            }
        }),

        checkToken: flow(function* checkToken() {
            const logger = getEnv(self).logger
            try {
                const service = getEnv(self).cfpService
                const res = yield service.checkToken()
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[Failed Check Token]', error)
                return new WebClientResult(false, null)
            }
        }),

        getGoogleCalendarUrl: flow(function* getGoogleCalendarUrl() {
            const logger = getEnv(self).logger
            try {
                const service = getEnv(self).cfpService
                const res = yield service.getGoogleCalendarAuthUrl()
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.log('[Failed Sync Google Calendar]', error)
                return new WebClientResult(false, null)
            }
        }),

        completeGoogleAuth: flow(function* completeGoogleAuth(code, cfpId) {
            const logger = getEnv(self).logger
            try {
                const service = getEnv(self).cfpService
                const res = yield service.completeGoogleAuth(code, cfpId)
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.log('[Failed Complete Google Auth]', error)
                return new WebClientResult(false, null)
            }
        }),

        getProfile: flow(function* getProfile() {
            const logger = getEnv(self).logger
            try {
                const service = getEnv(self).cfpService
                const res = yield service.getProfile()
                if (res.ok) {
                    const data = res.data
                    self.setData(data)
                    return res
                }
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[Failed Get Profile]', error)
                return new WebClientResult(false, null)
            }
        }),

        updateProfile: flow(function* updateProfile(code, cfpId) {
            const logger = getEnv(self).logger
            try {
                const service = getEnv(self).cfpService
                const res = yield service.updateProfile(code, cfpId)
                if (res.ok) {
                    const data = res.data
                    self.setData({
                        fullName: res.data.name,
                        ...data
                    })
                }
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.log('[Failed Update CFP Profile]', error)
                return new WebClientResult(false, null)
            }
        }),

        uploadProfilePicture: flow(function* uploadProfilePicture(formData) {
            const logger = getEnv(self).logger

            try {
                const service = getEnv(self).cfpService
                const res = yield service.uploadProfilePicture(formData)
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.log('[Failed Update CFP Profile Picture]', error)
                return new WebClientResult(false, null)
            }
        }),

        uploadProfileBanner: flow(function* uploadProfileBanner(formData) {
            const logger = getEnv(self).logger

            try {
                const service = getEnv(self).cfpService
                const res = yield service.uploadProfileBanner(formData)
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.log('[Failed Update CFP Profile Banner]', error)
                return new WebClientResult(false, null)
            }
        }),

        changePassword: flow(function* changePassword(
            currentPassword,
            password
        ) {
            const logger = getEnv(self).logger
            try {
                const service = getEnv(self).cfpService
                const res = yield service.changePassword(
                    currentPassword,
                    password,
                    true
                )
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.log('[Failed Change Password]', error)
                return new WebClientResult(false, null)
            }
        }),

        clear() {
            self.id = null
            self.fullName = null
            self.email = null
            self.accessToken = null
            self.refreshToken = null
            self.isLogined = false
            self.type = null
        }
    }))
    .views(self => ({
        get isLogin() {
            return (
                self.user.accessToken !== null &&
                self.user.accessToken.length > 0
            )
        }
    }))

let _store

export const createCfpStore = (data, env = null) => {
    if (!_store) {
        _store = CfpStore.create(data, env)
    }
    return _store
}
