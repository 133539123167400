import { useState } from 'react'
import cn from 'classnames'
import { inject } from 'mobx-react'
import { Form, Input, Modal } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import SubmitButton from '@components/common/button/submit/SubmitButton'
import UploadFile from '@components/common/upload-file/UploadFile'

const AdminAddFileInSessionsForm = (props) => {
    const logger = props.logger
    const planTypeService = props.services.planTypeService
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const sessionIndex = props.defaultValues.sessionIndex
    const { handleSubmit, setError, control, reset, watch, setValue, trigger, formState: { errors } } = useForm({
        defaultValues: props?.defaultValues,
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)

            const formData = new FormData()
            Object.keys(data).forEach((key) => {
                if (key !== 'file') {
                    formData.append(key, data[key])
                } else {
                    formData.append('file', data[key].file)
                }
            })

            const res = await planTypeService.uploadFilePlanType(formData)

            if (res.ok && res.data) {
                reset()
                props.onCancel()
                props.onSuccessSubmit()
                props.updateSessionsFile(res.data, sessionIndex)
            } else {
                const errors = res?.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: <p>Something went wrong</p>
                    })
                }
            }

        } catch (error) {
            logger.error('Fail Add File in Sessions Form', error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            <Form.Item
                className='mb-4'
                label='Title'
                validateStatus={(errors?.title?.message) ? 'error' : '-'}
                help={errors?.title?.message}
            >
                <Controller
                    name='title'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            placeholder=''
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>
            <p>File</p>
            <Form.Item
                className='mb-6'
                validateStatus={errors?.file?.message ? 'error' : '-'}
                help={errors?.file?.message}
            >
                <Controller
                    name='file'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <UploadFile
                            onChange={(e) => {
                                const file = e.file
                                if (file?.status === 'removed') {
                                    setValue('file', undefined)
                                } else {
                                    setValue('file', e)
                                }
                            }}
                            value={value}
                        />
                    )}
                />
            </Form.Item>
            <SubmitButton className='mt-1' loading={submitting}>
                Submit
            </SubmitButton>
        </Form>
    )
}

export default inject('services')(AdminAddFileInSessionsForm)
