import React, { useEffect, useMemo, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import moment from 'moment'
import { Button, Tag } from 'antd'
import ProTable from '@ant-design/pro-table'
import cn from 'classnames'
import planStatuses from '@constants/planStatuses'
import { Link } from 'react-router-dom'

const PlanList = ({ className, style, actionRef, handleRequest, renderColumns, renderToolbar, search, onReset }) => {
    const defaultColumns = {
        customer: {
            title: 'Customer',
            dataIndex: 'customerId',
            search: false,
            render: (text, record) => {
                return record?.customer?.fullName
            }
        },
        cfp: {
            title: 'CFP',
            search: false,
            render: (text, record) => {
                if (!record?.cfp?.name) return '-'
                return record?.cfp?.name
            }
        },
        planTypeId: {
            title: 'Type',
            dataIndex: 'planTypeId',
            width: 300,
            search: false,
            render: (text, record) => {
                if (!record?.planTypeName) return '-'
                if (!record?.planTypeVariantType) return (
                    record.planTypeName
                )

                return (
                    record.planTypeName + ' - ' + record.planTypeVariantType
                )
            }
        },
        totalSession: {
            title: 'Total Session',
            dataIndex: 'totalSession',
            search: false,
            render: (text, record) => {
                return text
            }
        },
        currentSessionNumber: {
            title: 'Current Session',
            dataIndex: 'currentSessionNumber',
            search: false,
            render: (text, record) => {
                return text
            }
        },
        status: {
            title: 'Status',
            search: false,
            dataIndex: 'status',
            render: (text, record) => {
                const planStatus = planStatuses[text]
                return (
                    <Tag color={planStatus.color}>
                        {planStatus.name}
                    </Tag>
                )
            }
        },
        createdAt: {
            title: 'Created At',
            dataIndex: 'createdAt',
            search: false,
            width: 230,
            valueType: 'datetime',
            renderText: (text) => {
                return moment.utc(text).local().format('D MMM YYYY hh:mm A')
            }
        },
        action: {
            title: 'Actions',
            valueType: 'option',
            key: 'option',
            width: 80,
            search: false,
            fixed: 'right',
            render: (text, record, _, action) => {
                const actionComponents = [
                    <Link to={''} key='view'>
                        View
                    </Link>
                ]
                return actionComponents
            }
        }
    }

    let columns = Object.values(defaultColumns)
    if (renderColumns) {
        columns = renderColumns(defaultColumns)
    }

    useEffect(() => {
    }, [])

    return (
        <ProTable
            className={cn(className)}
            style={style}
            title={() => 'Plans'}
            search={search}
            actionRef={actionRef}
            columns={columns}
            rowKey='id'
            request={handleRequest}
            pagination={{
                showSizeChanger: false
            }}
            toolBarRender={() => {
                const defaultToolbars = []

                if (renderToolbar) {
                    return renderToolbar(defaultToolbars)
                }

                return defaultToolbars
            }}
            onReset={onReset}
        />
    )
}

PlanList.defaultProps = {
    search: {
        defaultCollapsed: false
    },
    onReset: () => { }
}

export default inject('logger', 'services')(PlanList)
