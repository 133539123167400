import { useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import { inject } from 'mobx-react'
import moment from 'moment'
import { Form, DatePicker, Modal } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import SubmitButton from '@components/common/button/submit/SubmitButton'

const AdminAddAppointmentForm = (props) => {
    const { logger, services, defaultValues, disabledFields } = props
    const { appointmentService } = services
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const { handleSubmit, setError, control, reset, watch, setValue, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues
    })

    const disabledStartDate = (current) => {
        return current && current.isBefore(moment(), 'day')
    }

    const disabledStartTime = (now) => {
        return {
            disabledHours: () => {
                const hours = []
                if (moment(now).isSame(moment(), 'day')) {
                    for (let i = 0; i < moment().hour(); i += 1) hours.push(i)
                }
                return hours
            },
            disabledMinutes: (selectedHour) => {
                const minutes = []
                if (moment(now).isSame(moment(), 'day') && selectedHour === moment().hour()) {
                    for (let i = moment().minute(); i >= 0; i -= 1) minutes.push(i)
                }
                return minutes
            }
        }
    }

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            const params = {
                ...data,
                startTime: moment(data.startTime).format('YYYY-MM-DDTHH:mm:ss')
            }

            logger.info('[Submit Add Appointment Form]', params)

            const res = await appointmentService.addFromAdmin(params)

            if (res.ok && res.data) {
                props.onSuccessSubmit(res.data)
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (error) {
            logger.error('[Fail Submit Add Appointment Form]', error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            <div className='mb-4 grid grid-cols-12 gap-4'>
                <Form.Item
                    className='mb-0 col-span-6'
                    label='Appointment Start Time'
                    validateStatus={(errors?.startTime?.message) ? 'error' : '-'}
                    help={errors?.startTime?.message}
                >
                    <Controller
                        name='startTime'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <DatePicker
                                className='w-full'
                                placeholder='Select End Time'
                                format='YYYY-MM-DD hh:mm A'
                                showTime
                                disabledDate={disabledStartDate}
                                disabledTime={disabledStartTime}
                                onChange={(e) => {
                                    onChange(e)
                                    trigger('endTime')
                                }}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>
            </div>

            <SubmitButton className='mt-1' loading={submitting}>
                Submit
            </SubmitButton>
        </Form>
    )
}

export default inject('services', 'logger')(AdminAddAppointmentForm)
