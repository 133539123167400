import React, { useEffect, useRef, useState } from 'react'
import { inject } from 'mobx-react'
import _ from 'lodash'
import prepareApiParams from '@helpers/prepareApiParams'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import AppointmentList from '@components/shared/appointment/list/AppointmentList'
import { Button, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import AdminAppointmentDetailDrawer from '../detail/drawer/AdminAppointmentDetailDrawer'
import AppointmentStatus from '@models/enum/AppointmentStatus'
import AdminUpdateAppointmentModal from '../update/modal/AdminUpdateAppointmentModal.jsx'
import moment from 'moment-timezone'

const AdminAppointmentList = ({ logger, services, className, style, defaultFilters, renderColumns, search, onSuccessCancel, onSuccessComplete, onSuccessUpdate }) => {
    const tableActionRef = useRef(null)
    const { appointmentService } = services
    const [filters, setFilters] = useState(defaultFilters)
    const [selectedAppointment, setSelectedAppointment] = useState(null)
    const [updateAppointmentFormProps, setUpdateAppointmentFormProps] = useState(null)

    useEffect(() => {
    }, [])

    useEffect(() => {
        setFilters(defaultFilters)
        tableActionRef.current?.reset()
        tableActionRef.current?.reload()
    }, [defaultFilters])

    const handleRequest = async (params, sort) => {
        try {
            const apiParams = {
                ...filters,
                ...(params.appointmentDate && {
                    startTime: moment(params.appointmentDate[0]).format('YYYY-MM-DDTHH:mm:ss'),
                    endTime: moment(params.appointmentDate[1]).format('YYYY-MM-DDTHH:mm:ss')
                }),
                ...(params.appointmentCreatedAt && {
                    createdStartAt: moment(params.appointmentCreatedAt[0]).format('YYYY-MM-DDTHH:mm:ss'),
                    createdEndAt: moment(params.appointmentCreatedAt[1]).format('YYYY-MM-DDTHH:mm:ss')
                })
            }

            delete params.appointmentDate
            delete params.appointmentCreatedAt

            prepareApiParams(params, sort, apiParams)
            const res = await appointmentService.listFromAdmin(apiParams)

            if (res.ok) {
                const data = res.data
                return {
                    data: data.data,
                    success: res.ok,
                    total: data.lastPage * data.perPage
                }
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
                return {
                    data: null,
                    success: res.ok,
                    total: 0
                }
            }
        } catch (e) {
            logger.error('[Failed List Appointment]', e)
        }
    }

    const cancelAppointment = async (id) => {
        try {
            const apiParams = { id }
            const res = await appointmentService.cancelFromAdmin(apiParams)

            if (res.ok && res.data) {
                tableActionRef.current?.reload()
                onSuccessCancel()
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (e) {
            logger.error('[Failed Cancel Appointment]', e)
        }
    }

    const completeAppointment = async (id) => {
        try {
            const apiParams = { id }
            const res = await appointmentService.completeFromAdmin(apiParams)

            if (res.ok && res.data) {
                tableActionRef.current?.reload()
                onSuccessComplete()
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                } else {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <p>Something went wrong</p>
                        )
                    })
                }
            }
        } catch (e) {
            logger.error('[Failed Complete Appointment]', e)
        }
    }

    return (
        <>
            <AppointmentList
                actionRef={tableActionRef}
                className={className}
                style={style}
                title={null}
                search={search}
                handleRequest={handleRequest}
                renderColumns={(defaultColumns) => {
                    defaultColumns.action = {
                        ...defaultColumns.action,
                        render: (text, record, _, action) => {
                            const actionComponents = [
                                <Button
                                    type='link'
                                    key='view'
                                    onClick={() => {
                                        setSelectedAppointment(record)
                                    }}
                                >
                                    View
                                </Button>
                            ]
                            if (record.status === AppointmentStatus.PENDING.enumKey || record.status === AppointmentStatus.UPCOMING.enumKey) {
                                const updateBtn = <a
                                    key='edit'
                                    onClick={() => {
                                        setUpdateAppointmentFormProps({
                                            defaultValues: {
                                                id: record.id,
                                                startTime: (record.startTime) ? moment(record.startTime) : null,
                                                timezone: moment.tz.guess()
                                            },
                                            onSuccessSubmit: () => {
                                                tableActionRef.current?.reload()
                                                if (onSuccessUpdate) onSuccessUpdate()
                                                setUpdateAppointmentFormProps(null)
                                            }
                                        })
                                    }}
                                >
                                    Update
                                </a>
                                actionComponents.push(updateBtn)
            
                                const cancelBtn = <a
                                    key='cancel'
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Are you sure to cancel this appointment?',
                                            icon: <ExclamationCircleOutlined />,
                                            okText: 'Confirm',
                                            cancelText: 'Cancel',
                                            onOk: async () => {
                                                await cancelAppointment(record.id)
                                            }
                                        })
                                    }}
                                >
                                    Cancel
                                </a>
            
                                actionComponents.push(cancelBtn)
                            }
                            if (record.status === AppointmentStatus.UPCOMING.enumKey) {
                                const updateBtn = <a
                                    key='complete'
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Are you sure to complete this appointment?',
                                            icon: <ExclamationCircleOutlined />,
                                            okText: 'Confirm',
                                            cancelText: 'Cancel',
                                            onOk: async () => {
                                                await completeAppointment(record.id)
                                            }
                                        })
                                    }}
                                >
                                    Complete
                                </a>
                                actionComponents.push(updateBtn)
                            }
                            return actionComponents
                        }
                    }

                    if (renderColumns) {
                        return renderColumns(defaultColumns)
                    }

                    return Object.values(defaultColumns)
                }}
                isCfp={false}
            />

            <AdminUpdateAppointmentModal
                form={updateAppointmentFormProps}
                visible={!!updateAppointmentFormProps}
                onCancel={() => setUpdateAppointmentFormProps(null)}
            />

            <AdminAppointmentDetailDrawer
                appointment={selectedAppointment}
                visible={!!selectedAppointment}
                onClose={() => setSelectedAppointment(null)}
            />
        </>
    )
}

export default inject('logger', 'services')(AdminAppointmentList)
