import React, { useEffect, useState } from 'react'
import showApiErrorMessage from '@helpers/showApiErrorMessage'
import { inject } from 'mobx-react'
import AdminLayout from '@components/admin/layout/AdminLayout'
import { PageContainer } from '@ant-design/pro-layout'
import { Statistic, Col, Row, Spin, Card } from 'antd'

const AdminDashboardPage = ({ logger, services: { adminService } }) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    const getAdminDashboard = async () => {
        try {
            const apiParams = {}
            const res = await adminService.getDashboard(apiParams)

            if (res.ok) {
                const resData = res.data
                setData(resData)
            } else {
                const errors = res.data?.errors
                if (errors) {
                    showApiErrorMessage(errors)
                }
            }
        } catch (e) {
            logger.error('[Failed[', e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        // getAdminDashboard()
    }, [])

    return (
        <div>
            <AdminLayout>
                <PageContainer
                    breadcrumb={{
                        routes: [
                            {
                                breadcrumbName: 'Dashboard'
                            }
                        ]
                    }}
                    header={{
                        ghost: false
                    }}
                    title="Dashboard"
                >
                    {/* {loading &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <Spin size="large" />
                    </div>}

                    {!loading && data &&
                    <div>
                        <Row gutter={16} style={{ marginBottom: '30px' }}>
                            <Col span={8}>
                                <Card bordered={false}>
                                    <Statistic title="Customers" value={data.totalCustomer} />
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card bordered={false}>
                                    <Statistic title="Verified Customers" value={data.totalVerifiedCustomer} />
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card bordered={false}>
                                    <Statistic title="Purchased" value={data.totalCompletedPurchase} />
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Card bordered={false}>
                                    <Statistic title="Pending Appointments" value={data.totalPendingAppointment} />
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card bordered={false}>
                                    <Statistic title="Upcoming Appointments" value={data.totalUpcomingAppointment} />
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card bordered={false}>
                                    <Statistic title="Completed Appointments" value={data.totalCompletedAppointment} />
                                </Card>
                            </Col>
                        </Row>
                    </div>} */}
                </PageContainer>
            </AdminLayout>
        </div>
    )
}

export default inject('services', 'logger')(AdminDashboardPage)






